import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { CardSummaryBigContainer } from './cards.styles'
import { ReactComponent as ArrowCardLink } from 'src/assets/icons/ayuda/ArrowCardLink.svg'
import { useHistory } from 'react-router-dom'

interface CardAyudaProps {
  Icon:any
  title: string
  text?: string
  link?: string
  externalLink?: string
  underlineText?: string
  manuals?: boolean
}

export default function CardAyuda({ title, Icon, text, link, externalLink, underlineText, manuals }: CardAyudaProps): JSX.Element {
  const history = useHistory()

  const navigationCard = () => {
    if (link) history.push(link)
  }

  return (
    <a target="_blank" href={externalLink} rel="noreferrer" >
      <CardSummaryBigContainer
        sx={{ p: 3, alignItems: 'flex-start', justifyContent: 'flex-start', cursor: 'pointer', height: '100%' }}
        onClick={navigationCard}
      >
        <Box className="flex-between" sx={{ width: '100%', alignItems: 'flex-start' }}>
            
           <Icon style={{ color: '#1870c7' }} />
         {!manuals && (
           <ArrowCardLink />
           )}
        </Box>
        

        <Typography sx={{ mt: 3, mb: 1, textAlign: 'left' }} variant="subtitle2" color="grey.900" component="strong">
          {title}
        </Typography>
        {text && (
          <Typography sx={{ color: '#6B7280' }} variant="body1" component="p">
            {text}
            {underlineText && <span style={{ textDecoration: 'underline', color: '#6B7280', fontWeight: 500 }}>{underlineText}</span>}
          </Typography>
        )}
        {manuals && (
          <>
            <Typography sx={{ color: '#6B7280' }} variant="body1" component="p">
              <a
                style={{ textDecoration: 'underline', color: '#6B7280', fontWeight: 500 }}
                href="https://firebasestorage.googleapis.com/v0/b/fisioizq.appspot.com/o/manuals%2FManual%20Colaborador.pdf?alt=media&token=6ceeb17f-0dbc-45e8-8d95-f28d2494e85b"
                target={'_blank'}
                rel="noreferrer"
              >
                Manual Colaborador
              </a>
            </Typography>

            <Typography sx={{ color: '#6B7280' }} variant="body1" component="p">
              <a
                style={{ textDecoration: 'underline', color: '#6B7280', fontWeight: 500 }}
                href="https://firebasestorage.googleapis.com/v0/b/fisioizq.appspot.com/o/manuals%2FManual%20Fisioterapeuta.pdf?alt=media&token=42b917cd-0371-479e-a002-84bc413b618c"
                target={'_blank'}
                rel="noreferrer"
              >
                Manual Fisioterapeuta
              </a>
            </Typography>

            <Typography sx={{ color: '#6B7280' }} variant="body1" component="p">
              <a
                style={{ textDecoration: 'underline', color: '#6B7280', fontWeight: 500 }}
                href="https://firebasestorage.googleapis.com/v0/b/fisioizq.appspot.com/o/manuals%2FManual%20Jefe%20Sector%20y%20Responsable.%20.pdf?alt=media&token=20eae71c-1f24-459f-b872-de3cb45bca3b"
                target={'_blank'}
                rel="noreferrer"
              >
                Manual Jefe Sector/Responsable
              </a>
            </Typography>

            <Typography sx={{ color: '#6B7280' }} variant="body1" component="p">
              <a
                style={{ textDecoration: 'underline', color: '#6B7280', fontWeight: 500 }}
                href="https://firebasestorage.googleapis.com/v0/b/fisioizq.appspot.com/o/manuals%2FManual%20Recursos%20Humanos.pdf?alt=media&token=b29f1062-355b-43a3-afbe-db6627b1a73a"
                target={'_blank'}
                rel="noreferrer"
              >
                Manual RRHH
              </a>
            </Typography>
          </>
        )}
      </CardSummaryBigContainer>
    </a>
  )
}
