import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Stack, Typography } from '@mui/material'

import { Tooltip, ResponsiveContainer, Pie, PieChart, Legend, Cell } from 'recharts'

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
}

export interface ChartProps {
  title: string
  dataChart: any
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#49C427', '#a83240', '#33FF93']

export function PieChartCategory({ title, dataChart }: ChartProps): JSX.Element {
  const theme = useTheme()
  const [posData, setposData] = useState<Record<string, number>>({ x: 0, y: 0 })
  const [chartInfo, setChartInfo] = useState<any>(null)
  const [isToolTipActive, setIsToolTipActive] = useState(false)

  useEffect(() => {
    setChartInfo(dataChart)
  }, [dataChart])

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc' }}>
          <label>{`${payload[0].name} : ${payload[0].payload.percentage}%(${payload[0].value} Citas)`}</label>
        </div>
      )
    }

    return null
  }

  return (
    <Box sx={{ my: 2, border: '1px solid #E5F2FA', borderRadius: 2, boxSizing: 'border-box', height: '400px', py: 2 }}>
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 2, px: 4, height: '38px' }}>
        <Typography variant="subtitle1" sx={{ fontSize: '18px' }}>
          {title}
        </Typography>
      </Stack>
      <ResponsiveContainer height="90%">
        <PieChart>
          <Pie
            width={500}
            height={400}
            data={dataChart}
            color="#000000"
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
          >
            {dataChart && dataChart.map((entry: any, index: number) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  )
}
