import React, { useState, useEffect } from 'react'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useDebouncedValue } from 'src/hooks/useDebounced'
import { SearchBox } from './SearchInput.styles'

interface Props {
  clean?: boolean
  handleSubmit: (word: string) => void
  name: string
  value?: string
  placeholder: string
  children?: React.ReactNode
  fullwidth?: boolean
}

export default function SearchInput({ placeholder, clean, name, handleSubmit, fullwidth }: Props): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>('')
  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    handleSubmit(searchValue)
  }

  const debouncedValue = useDebouncedValue(searchValue)

  useEffect(() => {
    const search = debouncedValue.toLowerCase()
    if (search.length > 2 || search === '') handleSubmit(search)
  }, [debouncedValue])

  const closeButton = () => {
    handleSubmit('')
    setSearchValue('')
  }

  return (
    <SearchBox sx={{ width: { xs: fullwidth ? 'inherit' : '180px', sm: 'inherit' }, mr: 1 }} component={'form'} onSubmit={submit}>
      <IconButton onClick={submit} sx={{ p: '1px', color: '#9CA3AF' }} aria-label="search">
        <SearchIcon sx={{ color: '#9CA3AF' }} />
      </IconButton>
      <InputBase
        onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => setSearchValue(ev.target.value)}
        name={name}
        value={searchValue}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': placeholder }}
      />
      {clean && searchValue.length > 0 && (
        <IconButton onClick={closeButton} sx={{ p: '1px', color: '#9CA3AF', position: 'absolute', right: 5 }} aria-label="close">
          <CloseOutlinedIcon />
        </IconButton>
      )}
    </SearchBox>
  )
}
