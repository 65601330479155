export const stores = [
  { id: 'RFmfWRUGtLQzCZooCBCG', name: 'Obramat Alcalá de Guadaíra' },
  { id: 'YFpRnyrT61hKK4jcng6z', name: 'Obramat Alcobendas' },
  { id: 'PElwMA5nxCovViVeR0k3', name: 'Obramat Alcorcón' },
  { id: 'f7no0RsgjyyzkQifOzfU', name: 'Obramat Alicante' },
  { id: 'fxSOu4w4bmZ0Xe5AWer4', name: 'Obramat Badalona' },
  { id: 'cIyiw6XX6kZc7hMWdasw', name: 'Obramat Bormujos' },
  { id: 'sdt1f1yjU2RYfKOpYTVD', name: 'Obramat Burgos' },
  { id: 'NKQmJpUqe4UQR9drpK3u', name: 'Obramat Castellón' },
  { id: '31bV3HgkVPAdbwyJHyC5', name: 'Obramat Cordoba' },
  { id: 'DbL83opQ67QDf4jaqncp', name: 'Obramat Usera' },
  { id: 'nrXT2uKNrOEdWZOaRxjc', name: 'Obramat Finestrat' },
  { id: 'mq5lBK20WQxAEIT34iO9', name: 'Obramat Galdakao' },
  { id: '52VzLnqCxSXwhev0t1Gv', name: 'Obramat Hospitalet' },
  { id: '3rCmusLZdNvlWjpuoMBn', name: 'Obramat Jinámar' },
  { id: 'w6gUwaOLAoqIeZjO24ev', name: 'Obramat Leganés' },
  { id: 'zlnh150MVxxj3dNHmtZE', name: 'Obramat Lleida' },
  { id: 't0Sh9LDgsPL9mZhfY2jm', name: 'Obramat Los Barrios' },
  { id: 'vQKXvRLoVwHHrw384r3w', name: 'Obramat Lugo' },
  { id: '1SbIEXokTudIvhdX6yWC', name: 'Obramat Majadahonda' },
  { id: 'NMjZLxGXIIH3nPfvj0W5', name: 'Obramat Málaga' },
  { id: 'QS7IzxIOIZkeOaZ2AWzO', name: 'Obramat Massanassa' },
  { id: 'SU437fOgrd4KauKcIrnK', name: 'Obramat Murcia' },
  { id: 'PoSBlDu4vOSr5cjyHSS5', name: 'Obramat Pamplona' },
  { id: 'aY62jvAfSpmRNyLGO9VP', name: 'Obramat Palma' },
  { id: 'Oq7eBV3kG5Uv4C10kR5b', name: 'Obramat Rivas' },
  { id: 'x0p4Fzb9D4AohVdCuy9E', name: 'Obramat Salamanca' },
  { id: 'rQJJfVuvhbw2TZPnnTXf', name: 'Obramat Santander' },
  { id: 'N1Rh6Y5Eq0LUwX8dfhGn', name: 'Obramat Sabadell' },
  { id: 'bpFyVanglt3xOb11dk8P', name: 'Obramat Sestao' },
  { id: 'UZpXnvU6IzKRmTkU3EFY', name: 'Obramat Tarragona' },
  { id: 'KTofRHax9la7t9kQFbIX', name: 'Obramat Terrassa' },
  { id: 'TQZcMFyRdlcCB4LjxNHp', name: 'Obramat Siero' },
  { id: 'cOI75AWnYl50BITa7Twq', name: 'Obramat Santiago' },
  { id: 'yUNfafwNwix5ixFHCYgn', name: 'Obramat Vícar' },
  { id: '1myosgKfcuIKtlPlxZf6', name: 'Obramat Valladolid' },
  { id: 'epfvwL6ELak7cFD1Csjt', name: 'Obramat Vilanova' },
  { id: '5a78S3NUf8fMqppaGPwH', name: 'Obramat Zaragoza' },
  { id: 'k6mzslWr9L19VjEov4Ks', name: 'Servicios Internos' }
]

export const sectors = [
  { id: '9wD0qSafs0pBMdSBhyyJ', name: 'Almacenes-Organizacion' },
  { id: 'XKCkhiwAXhaNnCQStirJ', name: 'Bricolaje' },
  { id: 'kH1owkBpvRb8BSSlml0E', name: 'Cajas' },
  { id: 'QGjl3qWqsGE4WeCVPVef', name: 'Canal Pro' },
  { id: 'VVM2fSrTJ5is5kpFKwlZ', name: 'Cerámica' },
  { id: 'sUnnsIaHvjRALbmR3rPN', name: 'Codir' },
  { id: 'H2MzQZfWAspjfSXbnmyg', name: 'Control Gestión' },
  { id: 'EWoogPS3hjzvoGSOR49e', name: 'Digital Data Tech' },
  { id: 'oBZ80mRuwv1rJSkU7hne', name: 'Dpto. Cadena de Suministro' },
  { id: 'PSGFCUePN67kyUwSQxIB', name: 'Dpto. Compras' },
  { id: 'au6QUGjUd7L9ZJi2zpEU', name: 'Dpto. Financiero' },
  { id: 'P3H84DJhBeWKUutleLpS', name: 'Dpto. Seguridad y PRL' },
  { id: '1pvcxyNGGoNDxD1ZWvLZ', name: 'Dpto. Recursos Humanos' },
  { id: 'cfBA9LKNh8junzKD20yE', name: 'Dpto. Jurídico' },
  { id: 'Kqbf6zvb4yjxWeeKPNJc', name: 'Dpto. Marketing' },
  { id: 'Y1zA9JdXY6c3OcAf8r2f', name: 'Dpto. Innovación' },
  { id: 'GmjBlB08a90pizfg5IsA', name: 'Dpto. Expansión' },
  { id: 'MQeLFadhUVGlptCRfL9e', name: 'Electricidad' },
  { id: 'H2Rqqr8k6mPFqUaQttiG', name: 'Ferretería' },
  { id: '7xWJZbtbVwTePpTt44ia', name: 'Fontanería' },
  { id: 'S0cH2x7DCrlLWyHkbMuM', name: 'Gran Obra' },
  { id: 'WG866trq2pXZqTt7uT6m', name: 'Herramientas' },
  { id: 'smA5rPnhv0csOsn7qwF2', name: 'Iluminación' },
  { id: 'f2HCGFmUzZlbm8udiKS2', name: 'Informatica' },
  { id: 'vS9rwhyJ9IPLO1U199Hc', name: 'Jardinería' },
  { id: 'pddMbo5vlLJWHEYSAhL6', name: 'Logística' },
  { id: 'FCkdSAENU5Q2i6A8tKNo', name: 'Madera' },
  { id: 'vHOMpNkQ6LkoiGkDlxpm', name: 'Materiales' },
  { id: 'gK4q0U8c9Qv2Wb1S2SsL', name: 'Obras' },
  { id: 'MoirkMHAM0YrX8gqR2X5', name: 'Pintura' },
  { id: 'ZNulyA0KXkK50GhYwaJt', name: 'Sanitario' }
]

export const roles = [
  { id: 'role5', name: 'Abogado/a Relaciones Laborales' },
  { id: 'role3', name: 'Administrativo/a' },
  { id: 'role5', name: 'Administrativo/a Obras' },
  { id: 'role5', name: 'Administrativo/a Tesorería' },
  { id: 'role5', name: 'Aprovisionador Cds' },
  { id: 'role3', name: 'Asist. Dirección' },
  { id: 'role3', name: 'Asist. Producto' },
  { id: 'role6', name: 'Cajero/a' },
  { id: 'role5', name: 'Cajero/a Principal' },
  { id: 'role5', name: 'Canal Profesional' },
  { id: 'role5', name: 'Chief Technology Officer' },
  { id: 'role5', name: 'Cloud Operations Engineer' },
  { id: 'role5', name: 'Control de Gestión Operacional' },
  { id: 'role5', name: 'Controlador Interno' },
  { id: 'role5', name: 'Controler Gestión' },
  { id: 'role5', name: 'Coord. Logístico' },
  { id: 'role5', name: 'Coordinador/a Proyectos Cds' },
  { id: 'role5', name: 'Coor. Cad Sumi Ola' },
  { id: 'role5', name: 'Coor. Operac. Cad Sumi' },
  { id: 'role5', name: 'C. Gest. Operacional' },
  { id: 'role5', name: 'Data Analyst' },
  { id: 'role5', name: 'Data Architect' },
  { id: 'role5', name: 'Digital Data Analyst' },
  { id: 'role5', name: 'Digital Data Engineer' },
  { id: 'role4', name: 'Director/a' },
  { id: 'role5', name: 'Dtor/a Desarrollo' },
  { id: 'role5', name: 'E-Merchan' },
  { id: 'role5', name: 'Especialista Seo' },
  { id: 'role5', name: 'Gerente del Equipo de Transporte B2B' },
  { id: 'role5', name: 'Gerente del Equipo de Transporte B2C' },
  { id: 'role5', name: 'Head Of Product Fico&Hr' },
  { id: 'role5', name: 'Head Of Product Omnicommerce' },
  { id: 'role5', name: 'Head Of Product Supply & Offre' },
  { id: 'role1', name: 'SuperUser' },
  { id: 'role5', name: 'Jefe de Auditoría' },
  { id: 'role3', name: 'Jefe/a de Mercado' },
  { id: 'role3', name: 'Jefe/a de Producto' },
  { id: 'role5', name: 'Jefe/a de Sector' },
  { id: 'role5', name: 'Jefe/a Proyec Formac' },
  { id: 'role5', name: 'Jefe/a Proyecto Eficiencia Financiera' },
  { id: 'role5', name: 'Jefe/a Proyecto Mobi' },
  { id: 'role5', name: 'Jefe/a Proyecto Selección' },
  { id: 'role3', name: 'Líder' },
  { id: 'role5', name: 'Lider Asist Prod' },
  { id: 'role5', name: 'Lider de Organización y Optimización de Compras' },
  { id: 'role3', name: 'Mando SS.II' },
  { id: 'role5', name: 'Network Engineer' },
  { id: 'role5', name: 'Operations Service Manager' },
  { id: 'role5', name: 'People Experience Specialist' },
  { id: 'role5', name: 'Planner Cds' },
  { id: 'role5', name: 'Product Manager Fico&Hr' },
  { id: 'role5', name: 'Product Manager Omnicommerce' },
  { id: 'role5', name: 'Product Manager Supply & Offre' },
  { id: 'role5', name: 'Project Manager Transversal' },
  { id: 'role5', name: 'Quality Engineer' },
  { id: 'role5', name: 'Recepcionista' },
  { id: 'role6', name: 'Recepcionista Logística' },
  { id: 'role5', name: 'Resp. Comunicación' },
  { id: 'role5', name: 'Resp. Conta Gral/Rep' },
  { id: 'role5', name: 'Resp. Conta Proveedo' },
  { id: 'role5', name: 'Resp. Contabilidad' },
  { id: 'role5', name: 'Resp. Control Interno y Compliance Officer' },
  { id: 'role5', name: 'Resp. Expansión' },
  { id: 'role5', name: 'Resp. Formación' },
  { id: 'role5', name: 'Resp. Marketing/Com' },
  { id: 'role5', name: 'Resp. Merchandising' },
  { id: 'role5', name: 'Resp. Obras y Mobili' },
  { id: 'role5', name: 'Resp. Regional RRHH' },
  { id: 'role5', name: 'Resp. RR.LL. AA.PP.' },
  { id: 'role5', name: 'Resp. Selección' },
  { id: 'role5', name: 'Responsable Cajas' },
  { id: 'role5', name: 'Responsable Comercial' },
  { id: 'role5', name: 'Responsable Jurídico' },
  { id: 'role5', name: 'Responsable Logística' },
  { id: 'role5', name: 'Responsable PRL' },
  { id: 'role5', name: 'Responsable Red Logística' },
  { id: 'role3', name: 'Responsable SS.II' },
  { id: 'role5', name: 'Responsable de Planning Cds' },
  { id: 'role5', name: 'Tec. Comunicación' },
  { id: 'role5', name: 'Tec. Contabilidad' },
  { id: 'role5', name: 'Tec. Contb-Gral Ac Im' },
  { id: 'role5', name: 'Tec. Impacto Positivo' },
  { id: 'role5', name: 'Tec. Jurídico' },
  { id: 'role5', name: 'Tec. Merchandising' },
  { id: 'role5', name: 'Tec. PRL' },
  { id: 'role5', name: 'Tec. RRLL' },
  { id: 'role5', name: 'Tec. Selección' },
  { id: 'role5', name: 'Tec. Transporte' },
  { id: 'role5', name: 'Tec. de Formación' },
  { id: 'role5', name: 'Tec. de Sistemas' },
  { id: 'role5', name: 'Tec Comunicación Omnicanal' },
  { id: 'role5', name: 'Tec Compra Indirectas' },
  { id: 'role3', name: 'Técnico/a' },
  { id: 'role5', name: 'Técnico/a Concepción Proyectos' },
  { id: 'role5', name: 'Técnico/a Estudios de Mercado' },
  { id: 'role5', name: 'Técnico/a Oficina Técnica CC' },
  { id: 'role5', name: 'Técnico/a Operaciones Cds' },
  { id: 'role5', name: 'Técnico Ola Cds' },
  { id: 'role6', name: 'Vendedor/a' }
]
