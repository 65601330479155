import React from 'react'
import { IconButton } from '@mui/material'
import { ReactComponent as Logo } from 'src/assets/icons/general/metaText.svg'
import { ReactComponent as Collapse } from 'src/assets/icons/general/Collapse.svg'
import { DrawerHeader } from '../layout.styles'

interface LogoZoneProps {
  open: boolean
  handleDrawer: () => void
}

export default function LogoZone({ open, handleDrawer }: LogoZoneProps): JSX.Element {
  return (
    <DrawerHeader
      sx={{
        justifyContent: open ? 'space-between' : 'center',
        alignItems: 'center'
        // backgroundColor: theme.palette.grey[100]
      }}
    >
      <Logo onClick={handleDrawer} style={{ cursor: 'pointer' }} />
      {open && (
        <IconButton onClick={handleDrawer}>
          <Collapse />
        </IconButton>
      )}
    </DrawerHeader>
  )
}
