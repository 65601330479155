import firebase from 'src/firebase/firebase'

export const getAllPathologies = async (companyId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('pathologies')
      .where('companyId', '==', companyId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba recuperar patologías')
  }
}

export const createPathology = async (newPathology: Record<string, any>): Promise<any> => {
  try {
    return await firebase.db.collection('pathologies').add(newPathology)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba crear la patología')
  }
}

export const updatePathology = async (pathologyData: Record<string, any>): Promise<any> => {
  try {
    return firebase.db.collection('pathologies').doc(pathologyData.id).update(pathologyData)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba actualizar la patología')
  }
}

export const deletePathology = async (uid: string): Promise<any> => {
  try {
    return firebase.db.collection('pathologies').doc(uid).update({ deleted: true })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba eliminar la patología')
  }
}
