import firebase from 'src/firebase/firebase'

export const getCampaingData = async (id: string): Promise<any> => {
  return firebase.db
    .collection('campaigns')
    .doc(id)
    .get()
    .then((response) => {
      const data = response.data()
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba recuperar los datos de la campaña')
    })
}

export const getAllCampaignsByCompany = async (companyId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('campaigns')
      .where('companyId', '==', companyId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar las campañas de la empresa')
  }
}

export const getAllCampaignsByCompanyAndStore = async (companyId: string, storeId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('campaigns')
      .where('companyId', '==', companyId)
      .where('storeId', '==', storeId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar las campañas de la empresa y tienda')
  }
}

export const getAllCampaigns = async (): Promise<any> => {
  try {
    return await firebase.db
      .collection('campaigns')
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar la lista de campañas ')
  }
}

export const createCampaing = async (campaignData: Record<string, any>): Promise<any> => {
  const createCampaignCloud = firebase.functions.httpsCallable('saveCampaingCloud')

  try {
    return await createCampaignCloud(campaignData)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba crear la campaña')
  }
}

export const deleteCampaing = async (uid: string): Promise<any> => {

  try {
    return firebase.db.collection('campaigns').doc(uid).update({ deleted: true })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba eliminar la campaña')
  }
}

export const updateCampaing = async (campaignData: Record<string, any>): Promise<any> => {
  try {
    return firebase.db.collection('campaigns').doc(campaignData.id).update(campaignData)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba actualizar los datos de la campaña')
  }
}
