import React, { useContext, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { ContainerFud } from 'src/styles/general-styles'
import { HeaderContext } from 'src/context/header/headerContext'



export default function AvisoLegal(): JSX.Element {
  const { showHeader } = useContext(HeaderContext)

  const Header = () => {
    return (
      <div className="flex-center ">
     
        <Typography variant="subtitle2" component="h4">
        Aviso Legal
        </Typography>
    
      
      </div>
    )
  }

  useEffect(() => {
    showHeader(Header)
  }, [])

  return (
    <ContainerFud>
      <p>

      En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de
la Sociedad de la Información y del Comercio Electrónico (LSSICE), el propietario de la web https://metaphysio.app, le
informa de lo siguiente:
      </p>
  <ul>
    <li>Denominación social: FISIOIZQUIERDO, S.L.</li>
    <li>NIF: B87386819</li>
    <li>Domicilio: C/ Fernández De Los Ríos Nº15, 28015 - Madrid (Madrid)</li>
    <li>Inscrita en el Registro Mercantil de Madrid al Tomo 34023, folio 148, sección 8, hoja número 612226,
inscripción 3</li>
    </ul>    
<p>

Con los límites establecidos en la ley, https://metaphysio.app no asume ninguna responsabilidad derivada de la falta de
veracidad, integridad, actualización y precisión de los datos o informaciones que se contienen en sus páginas de
Internet.
</p>
<p>

Los contenidos e información no vinculan a https://metaphysio.app ni constituyen opiniones, consejos o asesoramiento
legal de ningún tipo pues se trata meramente de un servicio ofrecido con carácter informativo y divulgativo.
Las páginas de Internet de https://metaphysio.app pueden contener enlaces (links) a otras páginas de terceras partes
que https://metaphysio.app no puede controlar. Por lo tanto, https://metaphysio.app no puede asumir responsabilidades
por el contenido que pueda aparecer en páginas de terceros.
</p>
<p>
Los textos, imágenes, sonidos, animaciones, software y el resto de contenidos incluidos en este website son propiedad
exclusiva de https://metaphysio.app o sus licenciantes. Cualquier acto de transmisión, distribución, cesión,
reproducción, almacenamiento o comunicación pública total o parcial, debe contar con el consentimiento expreso de
https://metaphysio.app.
</p>
<p>

Asimismo, para acceder a algunos de los servicios que https://metaphysio.app ofrece a través del website deberá
proporcionar algunos datos de carácter personal. En cumplimiento de lo establecido en el Reglamento (UE) 2016/679
del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que
respecta al tratamiento de datos personales y a la libre circulación de estos datos le informamos que, mediante la
cumplimentación de los presentes formularios, sus datos personales quedarán incorporados y serán tratados en los
ficheros de FISIOIZQUIERDO, S.L. con el fin de poderle prestar y ofrecer nuestros servicios así como para informarle
de las mejoras del sitio Web. Asimismo, le informamos de la posibilidad de que ejerza los derechos de acceso,
rectificación, cancelación y oposición de sus datos de carácter personal, manera gratuita mediante email a
gerencia@fisioizquierdo.es o en la dirección C/ Fernández De Los Ríos Nº15, 28015 - Madrid (Madrid).
</p>
    </ContainerFud>
  )
}
