import firebase from 'src/firebase/firebase'

export const getIncidentData = async (id: string): Promise<any> => {
  return firebase.db
    .collection('incidents')
    .doc(id)
    .get()
    .then((response) => {
      const data = response.data()
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba recuperar los datos de la investigación')
    })
}

export const getAllIncidentsByCompany = async (companyId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('incidents')
      .where('companyId', '==', companyId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar los incidentes de la compañia')
  }
}

export const getAllIncidentsByUser = async (userId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('incidents')
      .where('injuredWorker.id', '==', userId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar los incidentes de la compañia')
  }
}

export const getAllIncidentsByCompanyAndStore = async (companyId: string, storeId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('incidents')
      .where('companyId', '==', companyId)
      .where('storeId', '==', storeId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar los incidentes de la compañia y tienda')
  }
}

export const getAllIncidents = async (): Promise<any> => {
  try {
    return await firebase.db
      .collection('incidents')
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba recuperar la lista de incidentes')
  }
}

export const createIncident = async (incidentData: Record<string, any>): Promise<any> => {
  const createIncidentCloud = firebase.functions.httpsCallable('saveIncidentCloud')

  try {
    return await createIncidentCloud(incidentData)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba crear la investigación')
  }
}

export const deleteIncident = async (uid: string): Promise<any> => {
  try {
    return firebase.db.collection('incidents').doc(uid).update({ deleted: true })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba eliminar la investigación')
  }
}

export const updateIncident = async (incidentData: Record<string, any>): Promise<any> => {
  try {
    return firebase.db.collection('incidents').doc(incidentData.id).update(incidentData)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba actualizar los datos de la investigación')
  }
}
