import React, { createContext, useReducer, FC, useState } from 'react'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { OPEN_LAYOUT_DEFAULT } from 'src/constants/const'
import { headerReducer } from './headerReducer'

export interface HeaderState {
  header: FC | null
  open: boolean
}

export interface HeaderContextProps {
  Header: FC | null
  openLayout: boolean
  showHeader: (header: FC) => void
  setOpenLayout: (open: boolean) => void
  isHeader: boolean
  exportOnClick: () => void
  setExportData: (data: any) => void
  exportData: any[]
  setExportName: (name: string) => void
}

// Initial State
export const headerInitialState: HeaderState = {
  header: null,
  open: OPEN_LAYOUT_DEFAULT
}

// Create context
export const HeaderContext = createContext({} as HeaderContextProps)

const HeaderProvider = ({ children }: any) => {
  const [headerState, dispatch] = useReducer(headerReducer, headerInitialState)
  const [exportData, setExportData] = useState<null | any>(null)
  const [exportName, setExportName] = useState('Exportación')

  const showHeader = (header: FC) => {
    dispatch({ type: 'showHeader', payload: header })
  }

  const setOpenLayout = (open: boolean) => {
    dispatch({ type: 'SET_OPEN', payload: open })
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportOnClick = () => {
    const ws = XLSX.utils.json_to_sheet(exportData)
    const wb = { Sheets: { 'Hoja 1': ws }, SheetNames: ['Hoja 1'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    saveAs(data, exportName + fileExtension)
  }

  return (
    <HeaderContext.Provider
      value={{
        Header: headerState.header,
        openLayout: headerState.open,
        setOpenLayout,
        showHeader,
        isHeader: !!headerState.header,
        exportOnClick,
        setExportData,
        exportData,
        setExportName
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export default HeaderProvider
