export const dateTimeConvert = (data: string | number, text?: boolean): string => {
  const date = new Date(data)
  date.toLocaleDateString('es-ES')
  if (text) return `${date.toLocaleDateString('es-ES', { month: 'long', year: 'numeric', day: 'numeric' })}`
  return `${date.toLocaleDateString('es-ES')} `
}


export const parameterToTime = (parameter: { hour: string; minutes: string }): string => {
  const hour = parameter.hour ? parameter.hour : '00'
  const minutes = parameter.minutes ? parameter.minutes : '00'
  return `${hour}:${minutes}`
  // return `${parameter.hour || '00'}:${parameter.minutes || '00'} `
}
