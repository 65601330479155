import React, { useContext } from 'react'
import { ListItemText, Paper, MenuList, MenuItem, Divider } from '@mui/material'

import { ReactComponent as Edit } from 'src/assets/icons/general/Edit.svg'
import { ReactComponent as Delete } from 'src/assets/icons/general/DeleteFilled.svg'
// import { ReactComponent as Desactivate } from 'src/assets/icons/general/Desactivate.svg'
import CheckIcon from '@mui/icons-material/Check'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import UpdateIcon from '@mui/icons-material/Update'
import { AlternateEmail } from '@mui/icons-material'
import { AuthContext } from 'src/context/auth/authContext'
import { labels } from 'src/labels/main_labels'
import moment from 'moment'

interface PopoverTableDataProps {
  rowPopover: Record<string, any>
  onDelete?: (data: any) => void
  onEdit?: (data: Record<string, unknown>) => void
  onReSchedule?: (data: Record<string, unknown>) => void
  onDeactivate?: (data: Record<string, any>) => void
  onSign?: (data: Record<string, any>) => void
  onSendMail?: (data: Record<string, any>) => void
  onChangeEmail?: (data: Record<string, any>) => void
  onCloseIncident?: (data: Record<string, any>) => void
  handleClosePopover: () => void
}

export default function PopoverTableData({
  onEdit,
  onReSchedule,
  onDeactivate,
  onSign,
  onSendMail,
  onChangeEmail,
  onDelete,
  onCloseIncident,
  rowPopover,
  handleClosePopover
}: PopoverTableDataProps): JSX.Element {
  const { userData } = useContext(AuthContext)

  const canDelete = () => {
    if (rowPopover.date) {
      const dateAppoinment = new Date(rowPopover.year, rowPopover.month, rowPopover.day, rowPopover.hour, rowPopover.minutes, 0)

      if (userData.role.id === 'role1') return true
      else if (moment().isBefore(moment(dateAppoinment).add(1, 'hours')) && !['role6'].includes(userData.role.id)) return true
      if (moment().isBefore(moment(dateAppoinment).add(1, 'hours')) && rowPopover.patient.id === userData.id) return true
    } else if (rowPopover.injuredWorker && userData.role.id === 'role6') {
      return false
    } else return true
  }

  const canEdit = () => {
    if (rowPopover.date) {
      console.log(rowPopover)
      if (rowPopover?.interStore) return false

      const dateAppoinment = new Date(rowPopover.year, rowPopover.month, rowPopover.day, rowPopover.hour, rowPopover.minutes, 0)

      if (userData.role.id === 'role1') return true
      else if (moment().isBefore(moment(dateAppoinment).add(1, 'hours')) && !['role6'].includes(userData.role.id)) return true
    } else if (rowPopover.injuredWorker && userData.role.id === 'role6') {
      return false
    } else return true
  }

  const canReSchedule = () => {
    if (['role2', 'role5', 'role1'].includes(userData.role.id) && rowPopover.completed) return true
  }

  const canComplete = () => {
    if (['role1', 'role3'].includes(userData.role.id)) return true
    else if (rowPopover.patient.id === userData.id) {
      if (rowPopover.date) {
        let dateAppoinment = new Date(rowPopover.date)
        dateAppoinment.setHours(rowPopover.hour)
        dateAppoinment.setMinutes(rowPopover.minutes)
        dateAppoinment = moment(dateAppoinment).subtract(10, 'minutes').toDate()

        if (moment().isAfter(dateAppoinment)) return true
        else return false
      }
    } else return false
  }

  const canSign = () => {
    if (rowPopover.userResponsable?.id === userData.id && !rowPopover?.signResponsable) return true
    if (rowPopover.injuredWorker?.id === userData.id && !rowPopover?.signInjuredWorker) return true
  }

  const canSendMail = () => {
    if (userData.role.id === 'role1') return true
    else if (userData.storesWorking?.map((store: any) => store.id).includes(rowPopover.storeId) && ['role2'].includes(userData.role.id))
      return true
    else return false
  }

  const canCloseIncident = () => {
    if (userData.role.id === 'role1' && rowPopover.incident && !rowPopover.incident.all.closed) return true
    else if (['role2'].includes(userData.role.id) && rowPopover.incident && !rowPopover.incident.all.closed) return true
    else return false
  }

  return (
    <Paper sx={{ width: 200, maxWidth: '100%' }}>
      <MenuList sx={{ '& .MuiListItemText-root ': { '& .MuiTypography-root': { color: '#374151' } } }}>
        {onEdit &&
          !rowPopover.completed &&
          canEdit() && [
            <div key="edit-popover">
              <MenuItem
                onClick={() => {
                  onEdit(rowPopover)
                  handleClosePopover()
                }}
              >
                <Edit />
                <ListItemText sx={{ ml: 1 }} primary={labels.es.edit} />
              </MenuItem>
            </div>
          ]}
        {onReSchedule &&
          canReSchedule() && [
            <div key="edit-popover">
              <Divider sx={{ my: 1 }} />
              <MenuItem
                onClick={() => {
                  const copyRow = { ...rowPopover }
                  delete copyRow.notes
                  onReSchedule(copyRow)

                  handleClosePopover()
                }}
              >
                <UpdateIcon sx={{ fontSize: 22, color: '#196fc7' }} />
                <ListItemText sx={{ ml: 1 }} primary={'Volver a citar'} />
              </MenuItem>
            </div>
          ]}
        {/* {onEdit && onDeactivate && rowPopover.status && <Divider sx={{ my: 1 }} />} */}
        {onDeactivate &&
          !rowPopover.completed &&
          canComplete() && [
            <div key="onDeactivate-popover">
              <Divider sx={{ my: 1 }} />
              <MenuItem
                onClick={() => {
                  onDeactivate(rowPopover)
                  handleClosePopover()
                }}
              >
                <CheckIcon sx={{ fontSize: 14, color: '#196fc7' }} />
                <ListItemText sx={{ ml: 1 }} primary={labels.es.complete} />
              </MenuItem>
            </div>
          ]}
        {onSign &&
          canSign() && [
            <div key="onSign-popover">
              <Divider sx={{ my: 1 }} />
              <MenuItem
                onClick={() => {
                  onSign(rowPopover)
                  handleClosePopover()
                }}
              >
                <CheckIcon sx={{ color: '##196fc7' }} />
                <ListItemText sx={{ ml: 1 }} primary={labels.es.sign} />
              </MenuItem>
            </div>
          ]}
        {onSendMail && rowPopover.completed && canSendMail() && (
          <div key="onSendMail-popover">
            <Divider sx={{ my: 1 }} />

            <MenuItem
              onClick={() => {
                onSendMail(rowPopover)
                handleClosePopover()
              }}
            >
              <AccessTimeIcon sx={{ fontSize: 14, color: '##196fc7' }} />
              <ListItemText sx={{ ml: 1 }} primary={labels.es.askBooking} />
            </MenuItem>
          </div>
        )}
        {onChangeEmail && (
          <div key="onChangeEmail-popover">
            <Divider sx={{ my: 1 }} />

            <MenuItem
              onClick={() => {
                onChangeEmail(rowPopover)
                handleClosePopover()
              }}
            >
              <AlternateEmail sx={{ fontSize: 14, color: '##196fc7' }} />
              <ListItemText sx={{ ml: 1 }} primary={labels.es.changeEmail} />
            </MenuItem>
          </div>
        )}
        {onCloseIncident && canCloseIncident() && (
          <div key="onCloseIncident-popover">
            <Divider sx={{ my: 1 }} />

            <MenuItem
              onClick={() => {
                onCloseIncident(rowPopover)
                handleClosePopover()
              }}
            >
              <LockOutlinedIcon sx={{ fontSize: 14, color: '##196fc7' }} />
              <ListItemText sx={{ ml: 1 }} primary={labels.es.close} />
            </MenuItem>
          </div>
        )}

        {/* {onDelete && <Divider sx={{ my: 1 }} />} */}
        {onDelete &&
          canDelete() && [
            <div key="onDelete-popover">
              <Divider sx={{ my: 1 }} />
              <MenuItem
                onClick={() => {
                  if (rowPopover.bookingId) {
                    onDelete(rowPopover)
                  } else {
                    onDelete(rowPopover.id)
                  }

                  handleClosePopover()
                }}
              >
                <Delete />
                <ListItemText sx={{ ml: 1 }} primary={labels.es.delete} />
              </MenuItem>
            </div>
          ]}
      </MenuList>
    </Paper>
  )
}
