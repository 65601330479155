import React, { useState, useEffect } from 'react'

import { Dialog, DialogContent,DialogActions,Button, Box, List, ListItem, ListItemText, Typography } from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'
import SearchInput from '../SearchInput'
import { labels as labelsT, LabelType } from 'src/labels/main_labels'

import { getLabel } from './Dialog.utils'
import Spinner from '../Spinner'

interface SearchDialogProps {
  onClose: () => void
  open: boolean
  optionsData: any[]
  setter: React.Dispatch<any>
  selected?: any[]
  text: string
  single?: boolean
  labels?: LabelType
  roles?: boolean
}
export const SearchDialog = ({
  open,
  onClose,
  optionsData,
  setter,
  selected,
  text,
  single,
  labels,
  roles
}: SearchDialogProps): JSX.Element => {
  const [options, setOptions] = useState<any[] | null>(null)

  useEffect(() => {
    const noOptions = !options || options.length === 0

    if (noOptions) setOptions(optionsData)
  }, [optionsData])

  const handleSearch = (word: string) => {
    if (labels) {
  

      const values = Object.values(labels.es)
      let filtered = values.filter((opt) => opt.toLowerCase().includes(word.toLowerCase()))
      filtered = Object.keys(labels.es).filter((key) => filtered.includes(labels.es[key]))
      setOptions(filtered)
      return
    }
    if (options) {
    
      const filtered = optionsData.filter((opt) => {
        
       
        if (opt.employeeId && (opt.employeeId).includes(word)) return true
        // if (opt.name) return opt.name.toLowerCase().includes(word.toLowerCase())
        else if (opt.name && opt.name.toLowerCase().includes(word.toLowerCase()) ) return true
        else return false
      })
      setOptions(filtered)
    }
  }

  const action = (option: any) => {
    // if(selected?.length && option?.id === selected[0]?.id){
    // if (single) onClose()
    //   return setter(null)
    // }
    setter(option)
    if (single) onClose()
  }

  const isSelected = (option: any) => {
    if (roles && selected && selected.length > 0) {
      return selected.map((elm) => elm.name).includes(option?.name ? option?.name : option)
    } else if (selected && selected.length > 0) {
      if (selected[0] && selected[0].id) {
        return selected.map((elm) => elm?.name).includes(option?.name ? option?.name : option)
      }
      return selected.includes(option.id ? option.id : option)
    }
    return false
  }

  return (
    <Dialog sx={{ '& .MuiPaper-root': { width: '350px !important', height: '500px' } }} onClose={onClose} open={open}>
      <Box sx={{ p: 3, pb: 1 }}>
        <SearchInput
          fullwidth
          placeholder={`${labelsT.es.search} ${text.toLowerCase()}`}
          name={'search'}
          handleSubmit={handleSearch}
          clean
        />
      </Box>
      <DialogContent sx={{ pt: 0 }}>
        {!options && (
          <Box sx={{ mt: 5 }}>
            <Spinner />
          </Box>
        )}
        {options && options.length > 0 && (
          <List>
            {options.map((option, idx) => (
              <ListItem
                sx={{ justifyContent: 'space-between', display: 'flex', px: 1 }}
                button
                onClick={() => action(option)}
                key={`${option}+${idx}`}
              >
                <ListItemText
                  primary={getLabel(option, labels)}
                  sx={{ '& .MuiTypography-root': { color: isSelected(option) ? '#1870c7' : 'inherit' } }}
                />
                {isSelected(option) && <CheckIcon color="primary" />}
              </ListItem>
            ))}
          </List>
        )}
        {options && options.length === 0 && (
          <Box sx={{ p: 2 }}>
            <Typography sx={{ textAlign: 'center', pb: 1, maxWidth: 270 }} variant="subtitle1">
              {labelsT.es.noResults}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
