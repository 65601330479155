import React from 'react'
import { Box } from '@mui/system'
import { ReactComponent as Logo } from 'src/assets/icons/general/Metaphysio.svg'

export default function NoResultsLogo(): JSX.Element {
  return (
    <Box sx={{ width: '100%', height: 300, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      <Logo width={300} height={300} />
    </Box>
  )
}
