import firebase from 'src/firebase/firebase'

export const getStoreData = async (id: string): Promise<any> => {
  return firebase.db
    .collection('stores')
    .doc(id)
    .get()
    .then((response) => {
      const data = response.data()
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba recuperar los datos de la tienda')
    })
}


export const getAllStoresByCompany = async (companyId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('stores')
      .where('companyId', '==', companyId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    console.log(error)
    throw new Error('Hubo un error mientras se intentaban recuperar las tiendas de la empresa')
  }
}

export const getAllStores = async (): Promise<any> => {
  try {
    return await firebase.db
      .collection('stores')
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar la lista de tiendas ')
  }
}

export const createStore = async (storeData: Record<string, any>): Promise<any> => {
  try {
    return await firebase.db.collection('stores').add(storeData)
    .then(async (docRef) => {
      await firebase.db.collection('stores').doc(docRef.id).update({id:docRef.id})
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba crear la tienda')
  }
}

export const deleteStore = async (uid: string): Promise<any> => {
  try {
    return firebase.db.collection('stores').doc(uid).update({ deleted: true })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba eliminar la tienda')
  }
}

export const updateStore = async (storeData: Record<string, any>, id: string): Promise<any> => {
  delete storeData.id
  delete storeData.createdAt
  try {
    return firebase.db.collection('stores').doc(id).update(storeData)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba actualizar los datos de la tienda')
  }
}

export const updateStoreCalendar = async (storeId: string, calendar: Record<string, any>): Promise<any> => {
  try {
    return await firebase.db.collection('stores').doc(storeId).update({ calendar: calendar })
  } catch (error) {
    console.log(error)
    throw new Error('Hubo un error mientras se intentaba actualizar el calendario de la tienda')
  }
}
