import StoreIcon from '@mui/icons-material/Store'
import BusinessIcon from '@mui/icons-material/Business'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import CampaignIcon from '@mui/icons-material/Campaign'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import PersonIcon from '@mui/icons-material/Person'
import EventIcon from '@mui/icons-material/Event'
import MemoryIcon from '@mui/icons-material/Memory'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import TextureIcon from '@mui/icons-material/Texture'
import PersonAddSharp from '@mui/icons-material/PersonAddSharp'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import PollIcon from '@mui/icons-material/Poll';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';

import { labels } from '../labels/main_labels'

// LINKS IN ADMIN LAYOUT
export const navigationLinkGeneral = [
  {
    text: labels.es.bookings,
    Icon: EventIcon,
    link: '/citas',
    roles: ['role1', 'role3', 'role4', 'role2', 'role5', 'role6']
  },
  { text: labels.es.companies, Icon: BusinessIcon, link: '/companias', roles: ['role1'] },
  { text: labels.es.stores, Icon: StoreIcon, link: '/tiendas', roles: ['role1'] },
  {
    text: labels.es.users,
    Icon: PersonIcon,
    link: '/usuarios',
    roles: ['role1', 'role3', 'role4', 'role5']
  },
  {
    text: labels.es.campaigns,
    Icon: CampaignIcon,
    link: '/campanas',
    roles: ['role1', 'role3', 'role4', 'role5']
  },
  {
    text: labels.es.incidents,
    Icon: AdminPanelSettingsIcon,
    link: '/incidentes',
    roles: ['role1', 'role3', 'role4', 'role5', 'role6']
  },
  {
    text: labels.es.calendars,
    Icon: CalendarMonthIcon,
    link: '/calendarios',
    roles: ['role1', 'role2']
  },
  { text: labels.es.statitics, Icon: QueryStatsIcon, link: '/estadisticas', roles: ['role1', 'role3', 'role4'] },
  
  {
    text: 'Utilidades',
    Icon: SettingsAccessibilityIcon,
    link: '/utilidades',
    roles: ['role1', 'role3', 'role4', 'role2', 'role5','role6']
  },
  {
    text: labels.es.help,
    Icon: HelpOutlineIcon,
    link: '/ayuda',
    roles: ['role1', 'role3', 'role4', 'role2', 'role5']
  }
]

export const navigationLinkRrhh = [
  { text: labels.es.areas, Icon: TextureIcon, link: '/areas', roles: ['role1', 'role3'] },
  
  { text: labels.es.pathologies, Icon: AccessibilityNewIcon, link: '/patologias', roles: ['role1'] },
  
  { text: labels.es.processes, Icon: MemoryIcon, link: '/procesos', roles: ['role1', 'role3'] },
  { text: labels.es.sectors, Icon: AccountTreeIcon, link: '/sectores', roles: ['role1', 'role3'] },
  { text: labels.es.machines, Icon: PrecisionManufacturingIcon, link: '/maquinaria', roles: ['role1', 'role3'] },
  {
    text: labels.es.positionJobs,
    Icon: PersonAddSharp,
    link: '/roles',
    roles: ['role1', 'role3']
  }
]

