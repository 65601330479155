const deleteElements = async (data: string | string[], deleteFnc: (id: string) => Promise<void>): Promise<void> => {
  try {
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        const id = data[i]
        await deleteFnc(id)
      }
    } else {
      await deleteFnc(data)
    }
  } catch (error) {
    if (error instanceof Error) throw new Error(error.message)
  }
}

export default deleteElements
