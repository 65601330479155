import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const SearchBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  backgroundColor: '#F3F4F6',
  borderRadius: 3,
  position: 'relative',
  maxHeight: '40px',
  minWidth: '150px'
}))
