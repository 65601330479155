import React, { useContext, useEffect, useState } from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'

import { labels } from 'src/labels/main_labels'
import Table from 'src/components/Table/Table'
import { useParams, useHistory,useLocation } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { grey } from '@mui/material/colors'
import { getUserBookings } from 'src/services/bookings.services'
import NoResultsLogo from 'src/components/NoResultsLogo'

const headCells: any[] = [
  {
    idField: 'date',
    align: 'left',
    disablePadding: true,
    label: labels.es.date,
    order: true,
    fieldtype: 'date-only'
  },
  {
    idField: 'hourFormatted',
    align: 'left',
    disablePadding: false,
    label: labels.es.hour,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'CAM/INC/PC',
    align: 'left',
    disablePadding: true,
    label: 'CAM/INC/PC',
    order: true,
    fieldtype: 'typeBooking'
  },
  {
    idField: 'affectedZone',
    align: 'left',
    disablePadding: true,
    label: labels.es.reason,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'notes',
    align: 'left',
    disablePadding: true,
    label: labels.es.notes,
    fieldtype: 'string'
  },
  {
    idField: 'completed',
    align: 'center',
    disablePadding: true,
    label: labels.es.assist,
    order: true,
    fieldtype: 'booking-completed'
  }
]

export default function UsersDetail(): JSX.Element {
  const { idUser } = useParams<{ idUser: string }>()
  const history = useHistory()
  const location:any = useLocation()

  const { showHeader, setExportData } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const [data, setData] = useState<any | null>(null)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])


  const Header = () => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <IconButton sx={{ ml: '-14px' }} onClick={() => history.goBack()}>
          <ChevronLeftIcon sx={{ color: grey[500] }} />
        </IconButton>

        <Typography variant="subtitle1" component="h1">
         
          {location && location?.state?.name+ ' - '}  Historial Citas
        </Typography>
        <div />
      </Stack>
    )
  }

  const fetchData = async () => {
    setLoadingTable(true)
    try {
      const resp = await getUserBookings(idUser)
      setData(resp)

      setExportData(
        resp.map((booking: any) => {
          return {
            Fecha: booking.date,
            Hora: booking.hourFormatted,
            'CAM/INC/PC':
              booking.campaign && booking.campaign?.name
                ? booking.campaign?.name
                : booking.incident?.name && booking.incident?.name
                ? booking.incident?.name
                : 'Patología Común',
            Motivo: booking?.affectedZone?.name,
            Notas: booking.notes
          }
        })
      )
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }

  useEffect(() => {
   if(data === null) fetchData()

    showHeader(Header)
  }, [data])

  return (
    <>
      {data && (
        <Table
          // noCheckbox={userData.role.id !== ('role1' || 'role3')}
          headCells={headCells}
          rows={data.sort((a: any, b: any) => a.hourFormatted.localeCompare(b.hourFormatted))
            ?.sort((a: any, b: any) => b.date.localeCompare(a.date))}
          snackBar
          // onDelete={onDelete}
          // onEdit={userData.role.id.includes('role6' || 'role6') ? false : onEdit}
          // onEdit={onEdit}
          loading={loadingTable}
          selected={selected}
          setSelected={setSelected}
          // onDeactivate={onDeactivate}
          // onSendMail={sendAskBooking}
          // onCloseIncident={onCloseIncident}
          // onRowClick={onRowClick}
        />
      )}
      {(!data || data.length === 0) && <NoResultsLogo />}
    </>
  )
}
