import firebase from 'src/firebase/firebase'

export const getAllIssues= async (): Promise<any> => {
  try {
    return await firebase.db
      .collection('issues')
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar las citas')
  }
}

export const getAllIssuesByCompany = async (companyId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('issues')
      .where('companyId', '==', companyId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar las citas')
  }
}

export const getAllIssuesByCompanyAndStore = async (companyId: string, storeId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('issues')
      .where('companyId', '==', companyId)
      .where('storeId', '==', storeId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar las citas')
  }
}

export const getIssueData = async (id: string): Promise<any> => {

  return firebase.db
    .collection('issues')
    .doc(id)
    .get()
    .then((response) => {
     
      const data = response.data()
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba recuperar los datos de la incidencia')
    })
}

export const createIssue = async (issueData: Record<string, any>): Promise<any> => {
  try {
    return await firebase.db.collection('issues').add(issueData)
  } catch (error) {
    console.log(error)
    throw new Error('Hubo un error mientras se intentaba crear la incidencia')
  }
}




