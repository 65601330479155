import React from 'react'
import { Button, Typography,CircularProgress } from '@mui/material'
import { ReactComponent as Delete } from 'src/assets/icons/general/Delete.svg'

import LoadingButton from '@mui/lab/LoadingButton'
import { labels } from 'src/labels/main_labels'
import { FooterFormBox } from './form.styles'

interface FooterFormProps {
  editing: boolean
  closeModal: () => void
  onSave: () => void
  text: string
  extraInfo?: string
  onDelete?: () => void
  loading?: boolean
  disabled?: boolean
  client?: boolean
  mainText?: boolean
}

export default function FooterForm({
  editing,
  text,
  closeModal,
  onSave,
  extraInfo,
  onDelete,
  loading,
  disabled,
  client,
  mainText
}: FooterFormProps): JSX.Element {
  return (
    <FooterFormBox sx={{ justifyContent: (editing && onDelete) || extraInfo || client ? 'space-between' : 'flex-end' }}>
      {editing && onDelete && (
        <Button sx={{ mr: 2 }} startIcon={<Delete />} variant="text" color="info" onClick={onDelete}>
          {labels.es.delete} {text}
        </Button>
      )}
      {extraInfo && (
        <Typography variant="subtitle2" component="h6" sx={{ ml: 1, textAlign: { xs: 'center', sm: 'left' } }}>
          {extraInfo}
        </Typography>
      )}
      <div>
        <Button variant="outlined" color="info" onClick={closeModal}>
          {labels.es.cancel}
        </Button>
       
        <LoadingButton
          sx={{ ml: 2, fontSize: '14px', fontFamily: 'Inter' }}
          onClick={onSave}
          loading={loading}
          loadingIndicator={
      <CircularProgress size={22} sx={{ color: 'white' }} />

          }
          loadingPosition='center'
          variant="contained"
          disabled={disabled}
        >
         <span> {loading ? (<span style={{color:'#aac9e8'}} >Guardando</span>) : mainText ? (
            text
          ) : (
            <>
              {editing ? labels.es.update : labels.es.add} {text}
            </>
          )}
          </span>
        </LoadingButton>
      </div>
    </FooterFormBox>
  )
}
