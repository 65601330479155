import React, { useState, useContext } from 'react'
import { FirebaseError } from '@firebase/util'
import { Stack, Button } from '@mui/material'

import Modal from 'src/components/Modals/Modal'
import { FooterFormBox } from 'src/components/Form/form.styles'
import TextInput from 'src/components/Form/TextInput'

import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { labels } from 'src/labels/main_labels'
import { reauthenticate } from 'src/utils/reauthenticate'

interface EditEmailProps {
  open: boolean
  handleClose?: () => void
  mandatory?: boolean
}

export const UpdatePassword = ({ open, handleClose, mandatory }: EditEmailProps): JSX.Element => {
  const { showSnackbar } = useContext(AlertContext)
  const { firebase, user } = useContext(AuthContext)

  const [state, setState] = useState({ password: '', newPassword: '', repeatNewPassword: '' })
  const [error1, setError1] = useState('')
  const [error2, setError2] = useState('')

  const updatePassword = async () => {
    setError1('')
    setError2('')
    if (!state.password || !state.newPassword || !state.repeatNewPassword) return

    if (state.newPassword !== state.repeatNewPassword) {
      setError2(labels.es.passwordsNotEqual)
      return
    }

    const currentCustomer = firebase.auth.currentUser
    if (!currentCustomer) {
      return
    }
    try {
      await currentCustomer.updatePassword(state?.newPassword)
      showSnackbar('success', labels.es.passwordChanged)
      if (handleClose) handleClose()
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/requires-recent-login') {
          reauthenticate(user?.user.email, state.password)
            .then(async () => {
              await currentCustomer.updatePassword(state?.newPassword)
              showSnackbar('success', labels.es.passwordChanged)
              if (handleClose) handleClose()
            })
            .catch((error) => {
              if (error.code === 'auth/requires-recent-login') {
                setError1(labels.es.INVALID_PASSWORD)
              } else showSnackbar('error', labels.es.passwordChangingError)
            })
        } else {
          showSnackbar('error', labels.es.passwordChangingError)
        }
      } else {
        showSnackbar('error', labels.es.passwordChangingError)
      }
    }
  }

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState({ ...state, [ev.target.name]: ev.target.value })
  }

  const closeModal = () => {
    if (handleClose) handleClose()
  }

  //   const navLink = (type: string) => {
  //     let url = '/'
  //     if (type === 'terms') url = termsAndConditionsUrl
  //     if (type === 'privacy') url = privacyPolicy
  //     window.open(url)
  //   }

  const disabledSaveButton = () => {
    if (!state.password || !state.newPassword || !state.repeatNewPassword) return true
    if (state.newPassword.length < 6 || state.newPassword.length > 14) return true
    return false
  }

  return (
    <Modal
      size={'sm'}
      title={mandatory ? labels.es.changePasswordToContinue : labels.es.changePassword}
      open={open}
      handleClose={closeModal}
      noCloseIcon={mandatory}
      footer={
        <FooterFormBox sx={{ justifyContent: mandatory ? 'space-between' : 'flex-end' }}>
          {/* {mandatory && (
            <Stack direction="row" alignItems="center">
              <Checkbox
                sx={{ pl: 0, '&:hover': { backgroundColor: 'transparent' } }}
                value={check}
                onChange={(e) => setCheck(e.target.checked)}
                color="primary"
              />
              <Typography variant="body2" sx={{ color: '#374151', fontWeight: 400 }}>
                {labels.es.readAndAcceptPolicies1}{' '}
                <span onClick={() => navLink('terms')} style={{ color: theme.palette.primary.main, cursor: 'pointer' }}>
                  {labels.es.termsConditions}
                </span>
                {labels.es.readAndAcceptPolicies2}{' '}
                <span onClick={() => navLink('privacy')} style={{ color: theme.palette.primary.main, cursor: 'pointer' }}>
                  {labels.es.privacyPolicy}
                </span>
              </Typography>
            </Stack>
          )} */}
          <Stack spacing={2} direction="row">
            {!mandatory && (
              <Button variant="outlined" color="info" onClick={handleClose}>
                {labels.es.cancel}
              </Button>
            )}
            <Button sx={{ ml: 2 }} onClick={() => updatePassword()} variant="contained" disabled={disabledSaveButton()}>
              {labels.es.save}
            </Button>
          </Stack>
        </FooterFormBox>
      }
    >
      <form noValidate>
        <TextInput
          value={state.password}
          handleChange={handleChange}
          name="password"
          text={labels.es.password}
          placeholder={labels.es.password}
          type="password"
          errorBadge={error1}
        />
        <TextInput
          value={state.newPassword}
          handleChange={handleChange}
          name="newPassword"
          text={labels.es.newPassword}
          placeholder={labels.es.newPassword}
          type="password"
        />

        <TextInput
          value={state.repeatNewPassword}
          handleChange={handleChange}
          name="repeatNewPassword"
          text={labels.es.repeatNewPassword}
          placeholder={labels.es.repeatNewPassword}
          type="password"
          noBottomBorder
          errorBadge={error2}
        />
      </form>
    </Modal>
  )
}
