import React from 'react'
import { Chip, Grid, Typography, TextField } from '@mui/material'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import { GridFlexBetween, MainBox } from './form.styles'
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import { es } from 'date-fns/locale'
import { labels } from 'src/labels/main_labels'
interface TextInputProps {
  ariaLabel?: string
  // handleChange?: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleChange?: (any: any) => void

  name: string
  id?: string
  text: string
  placeholder: string
  value: string | number
  multiline?: boolean
  type?: string
  // Badge error into text input
  errorBadge?: string
  required?: boolean
  price?: boolean
  disabled?: boolean
  noTopBorder?: boolean
  noBottomBorder?: boolean
  min?: number
  max?: number
  byDefaultText?: boolean
  shouldDisableDate?: (date: Date) => boolean
  rows?: number
}

export default function TextInput({
  ariaLabel,
  name,
  id,
  text,
  placeholder,
  handleChange,
  value,
  multiline,
  type = 'text',
  errorBadge,
  required,
  price,
  disabled,
  noTopBorder,
  noBottomBorder,
  byDefaultText,
  rows = 1,
  shouldDisableDate,
  ...props
}: TextInputProps & InputBaseProps): JSX.Element {
  return (
    <MainBox sx={{ borderTop: noTopBorder ? 'none' : '', borderBottom: noBottomBorder ? 'none' : '' }}>
      <GridFlexBetween container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2" component="h6">
            {text} {required && '*'}
          </Typography>
        </Grid>
        <Grid item xs={8} sx={{ position: 'relative' }}>
          {type === 'date' && (
            <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="Selecciona una fecha"
                cancelText="Cancelar"
                inputFormat="dd/MM/yyyy"
                value={value}
                disabled={disabled || false}
                onChange={(fecha) => {
                  if (fecha && handleChange) {
                    handleChange({ target: { name: name, value: fecha.toLocaleDateString('fr-CA') } })
                  }
                }}
                shouldDisableDate={shouldDisableDate}
                renderInput={(params) => {
                  const openClick: () => void = (params as any).InputProps?.endAdornment?._owner?.pendingProps?.openPicker
                  // params.inputProps = { ...params.inputProps, placeholder: placeholder }
                  // params.InputProps = { ...params.InputProps, endAdornment: '' }
                  return (
                    <TextField
                      sx={{
                        width: '100%',
                        '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                        '.MuiInputBase-root:after': { borderBottom: 'none !important' },
                        input: { fontFamily: 'Inter', fontSize: 14 },
                        label: { fontFamily: 'Inter', fontSize: 14 }
                      }}
                      variant="standard"
                      {...params}
                      onClick={openClick}
                    />
                  )
                }}
              />
            </LocalizationProvider>
          )}

          {type === 'number' && (
            <InputBase
              sx={{ fontFamily: 'Inter' }}
              value={value}
              onChange={handleChange ? (e) => handleChange(e) : undefined}
              name={name}
              id={id || name}
              fullWidth
              placeholder={placeholder}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: 200, 'aria-label': ariaLabel || text }}
              type="number"
              disabled={disabled}
              endAdornment={
                errorBadge ? (
                  <Chip
                    sx={{ height: 20, backgroundColor: '#FEE2E2', color: '#991B1B', fontSize: '12px', lineHeight: '16px', fontWeight: 500 }}
                    label={errorBadge}
                  />
                ) : (
                  <></>
                )
              }
              {...props}
            />
          )}

          {type !== 'number' && type !== 'date' && (
            <InputBase
              sx={{ color: type === 'date' ? '#9CA3AF' : 'inherit', fontFamily: 'Inter' }}
              value={value}
              onChange={handleChange ? (e) => handleChange(e) : undefined}
              name={name}
              id={id || name}
              placeholder={placeholder}
              inputProps={
                type === 'number'
                  ? { inputMode: 'numeric', pattern: '[0-9]*', min: 0, 'aria-label': ariaLabel || text }
                  : { 'aria-label': ariaLabel || text }
              }
              fullWidth
              multiline={multiline}
              rows={rows}
              type={type}
              disabled={disabled}
              autoComplete="off"
              endAdornment={
                errorBadge ? (
                  <Chip
                    sx={{
                      height: 20,
                      backgroundColor: '#FEE2E2',
                      color: '#991B1B',
                      fontSize: '12px',
                      lineHeight: '16px',
                      fontWeight: 500
                    }}
                    label={errorBadge}
                  />
                ) : (
                  <></>
                )
              }
              {...props}
            />
          )}

          {price && value && (
            <Typography
              sx={{
                color: disabled ? '#6B7280' : '#111827',
                position: 'absolute',
                top: 20,
                left: `${value.toString().length * 10 + 14}px`
              }}
            >
              ,{Number(value).toFixed(2).split('.')[1]} €{' '}
              <span style={{ color: '#6B7280' }}>{byDefaultText && `(${labels.es.byDefault})`}</span>
            </Typography>
          )}
          {byDefaultText && !price && (
            <Typography sx={{ position: 'absolute', top: 20, left: `${value.toString().length * 10 + 15}px` }} variant="body1">
              ({labels.es.byDefault})
            </Typography>
          )}
        </Grid>
      </GridFlexBetween>
    </MainBox>
  )
}
