import React, { FC, useContext, PropsWithChildren, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  CssBaseline,
  Divider,
  IconButton,
  ListItemText,
  Button,
  Box,
  Paper,
  MenuList,
  MenuItem,
  Popover,
  Typography,
  useTheme
} from '@mui/material'

import NavElements from './NavElements'
import LogoZone from './components/LogoZone'
import AppBarLayout from './components/AppBarLayout'
import ButtonExportData from './components/ButtonExportData'

import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { ReactComponent as Logout } from 'src/assets/icons/general/Logout.svg'
import { ReactComponent as Edit } from 'src/assets/icons/general/Edit.svg'

import { navigationLinkGeneral, navigationLinkRrhh } from 'src/routes/navigationLinks'
import { FOOTER_BAR_HEIGHT, OPEN_LAYOUT_DEFAULT } from 'src/constants/const'
import { logoutSwalConfig } from 'src/utils/swal.utils'
import { DrawerHeader, Drawer, DrawerNav, ListCustom, NavTitle, BoxNavBottom, Main } from './layout.styles'
import { labels } from 'src/labels/main_labels'
import { UpdatePassword } from './components/UpdatePassword'

interface LayoutProps {
  children: React.ReactNode
}

interface NavElement {
  roles: string[]
  link: string
  Icon: FC
  text: string
}

export default function LayoutAdmin({ children }: PropsWithChildren<LayoutProps>): JSX.Element {
  const history = useHistory()
  const theme = useTheme()
  const [open, setOpen] = useState(OPEN_LAYOUT_DEFAULT)
  const [width, setWidth] = useState(window.innerWidth)
  const { setOpenLayout, exportOnClick, exportData } = useContext(HeaderContext)
  const { userData, logout } = useContext(AuthContext)
  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const path = location.pathname === '/' ? location.hash.split('/')[1] : location.pathname.split('/')[1]
  const pathId = location.pathname === '/' ? location.hash.split('/')[2] : location.pathname.split('/')[2]
 
  useEffect(() => {
    if (width < 900 && path !== undefined) {
     
      setOpen(false)
      setOpenLayout(false)
    } else {
      setOpen(true)
      setOpenLayout(true)
    }
  }, [width])

  useEffect(() => {
    function handleResize() {
      
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Import header from context
  const handleDrawer = () => {
    setOpen(!open)
    setOpenLayout(!open)
  }

  const navLink = (link: string) => {
    history.push(link)
    if (width < 900) {
      setOpen(false)
      setOpenLayout(false)
    }
  }

  const onLogout = () => {
    Swal.fire(logoutSwalConfig(theme.palette.primary.main)).then((result: any) => {
      if (result.isConfirmed) {
        logout()
      }
    })
  }

  // POPOVER
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const openPopover = Boolean(anchorEl)
  const idPop = openPopover ? 'logout-popover' : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <LogoZone open={open} handleDrawer={handleDrawer} />
        <Divider />
        <DrawerNav>
          <div>
            <NavTitle variant="subtitle2">{labels.es.general}</NavTitle>
            <ListCustom disablePadding>
              {navigationLinkGeneral.map((elm: NavElement, index: number) => (
                <div key={index}>
                  {elm.roles.includes(userData.role.id) && (
                    <NavElements open={open} link={elm.link} Icon={elm.Icon} text={elm.text} navLink={navLink} />
                  )}
                </div>
              ))}
            </ListCustom>


            {userData.role.id &&
              // TO DO ACTIVAR AL ENTREGAR
              ['role1'].includes(userData.role.id) && (
                <>
                  <NavTitle variant="subtitle2">{labels.es.rrhh}</NavTitle>
                  <ListCustom disablePadding>
                    {navigationLinkRrhh.map((elm: NavElement, index: number) => (
                      <div key={index}>
                        {elm.roles.includes(userData.role.id) && (
                          <NavElements open={open} link={elm.link} Icon={elm.Icon} text={elm.text} navLink={navLink} />
                        )}
                      </div>
                    ))}
                  </ListCustom>
                </>
              )}
          </div>
          <ListCustom disablePadding>
            {exportData && exportData?.length > 0 && path === 'usuarios' && pathId && (
              <ButtonExportData onClick={exportOnClick} open={open} />
            )}
            <Box sx={{ height: FOOTER_BAR_HEIGHT }}>
              <Divider />
              <BoxNavBottom>
                <Button disabled={open} sx={{ pl: 1, textAlign: 'left' }} onClick={(e) => handlePopover(e)}>
                  {userData && (
                    <Typography variant="body2" sx={{ color: '#1A2D30' }}>
                      {userData.name}
                    </Typography>
                  )}
                </Button>
                {open && (
                  <IconButton sx={{ width: '40px' }} onClick={(e) => handlePopover(e)}>
                    <MoreHorizIcon color="info" />
                  </IconButton>
                )}
              </BoxNavBottom>
            </Box>
          </ListCustom>
        </DrawerNav>
      </Drawer>
      <Main>
        <DrawerHeader />
        <AppBarLayout handleDrawer={handleDrawer} open={open} />
        {children}
      </Main>

      <Popover
        id={idPop}
        elevation={1}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Paper sx={{ width: 200, maxWidth: '100%' }}>
          <MenuList sx={{ '& .MuiListItemText-root ': { '& .MuiTypography-root': { color: '#374151' } } }}>
            <MenuItem onClick={onLogout}>
              <Logout />
              <ListItemText sx={{ ml: 1 }} primary={labels.es.logout} />
            </MenuItem>
            <MenuItem onClick={() => setOpenPasswordModal(true)}>
              <Edit />
              <ListItemText sx={{ ml: 1 }} primary={labels.es.changePassword} />
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
      <UpdatePassword open={openPasswordModal} handleClose={() => setOpenPasswordModal(false)} />
    </Box>
  )
}
