import { Button, Grid, Popover } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CalendarGrid = styled(Grid)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily
}))

export const CalendarText = styled('p')(() => ({
  margin: 0,
  width: 25,
  height: 25,
  textAlign: 'center'
}))

export const CalendarButton = styled(Button)(({ theme }) => ({
  minWidth: 200,
  margin: 10,
  fontFamily: theme.typography.fontFamily
}))

export const CalendarButtonNoMargin = styled(Button)(({ theme }) => ({
  minWidth: 200,
  fontFamily: theme.typography.fontFamily
}))

export const CalendarPopover = styled(Popover)(({ theme }) => ({
  pointerEvents: 'none',
  fontFamily: theme.typography.fontFamily
}))
