import React, { useContext, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext'
import { Grid, Container, TextareaAutosize } from '@mui/material'
import { ReactComponent as Faq } from 'src/assets/icons/ayuda/Faq.svg'
import { ReactComponent as ComoFunciona } from 'src/assets/icons/ayuda/ComoFunciona.svg'
// import { ReactComponent as Email } from 'src/assets/icons/ayuda/Email.svg'
import Email from '@mui/icons-material/Email';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CookieIcon from '@mui/icons-material/Cookie';
import SecurityIcon from '@mui/icons-material/Security';
import GavelIcon from '@mui/icons-material/Gavel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import PollIcon from '@mui/icons-material/Poll';
import ChatIcon from '@mui/icons-material/Chat';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import { CloudDownload } from '@mui/icons-material'
import { ReactComponent as Phone } from 'src/assets/icons/ayuda/Phone.svg'
import { ReactComponent as ContactForm } from 'src/assets/icons/ayuda/ContactForm.svg'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'

import { ReactComponent as Check } from 'src/assets/icons/ayuda/Check.svg'
import { AlertContext } from 'src/context/alert/alertContext'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import CardAyuda from 'src/components/Cards/CardAyuda'
import { CardSummaryGrid } from 'src/components/Cards/cards.styles'
import { labels } from 'src/labels/main_labels'
import { privacyPolicy, termsAndConditionsUrl } from 'src/constants/const'
import TextInput from 'src/components/Form/TextInput'
import { sendInquiryMessage } from 'src/services/emails.services'

export default function Utilidades(): JSX.Element {
  const { showHeader } = useContext(HeaderContext)
  const { userData } = useContext(AuthContext)
  const [openModal, setOpenModal] = useState(false)
  const [question, setQuestion] = useState('')
  const [loading, setLoading] = useState(false)
  const { showSnackbar } = useContext(AlertContext)

  const Header = () => {
    return (
      <div className="flex-center ">
        <Typography variant="h3" component="h3">
          Utilidades
        </Typography>
        <Typography variant="subtitle2" component="h3"></Typography>
      </div>
    )
  }

  useEffect(() => {
    showHeader(Header)
  }, [])

  const onSave = async () => {
    if (question !== '') {
      const data = {
        message: question,
        emailFrom: userData?.email,
        userFullName: userData.name + ' ' + userData.lastname + ' ' + userData.lastname2,
        store: userData.store?.name || '',
        position: userData?.role?.name || ''
      }
      try {
        await sendInquiryMessage(data)
        showSnackbar('success', labels.es.questionSended)
        setQuestion('')
        setOpenModal(false)
      } catch (error: any) {
        showSnackbar('error', error.message)
      }
    }
  }

  return (
    <Container sx={{ my: 3 }}>
      <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
        Utilidades
      </Typography>
      <Grid container spacing={3}>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={ChatIcon} text="Comunicación interna" title="Chat" link="/chat" />
        </CardSummaryGrid>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={CrisisAlertIcon} text="Ver registro de anulaciones y cambios de cita" title="Incidencias" link="/incidencias" />
        </CardSummaryGrid>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={CrisisAlertIcon} text="Registro de citas entre almacenes" title="Citas entre almacenes" link="/interalmacenes" />
        </CardSummaryGrid>
        <CardSummaryGrid item xs={12} md={4} onClick={() => setOpenModal(true)}>
          <CardAyuda
            Icon={ContactMailIcon}
            text="Si tienes algún trabajador con el que necesites ayuda sobre como proceder contáctanos a través de este formulario o en consulta@metaphysio.app"
            title="Consulta de expediente"
          />
        </CardSummaryGrid>
      <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={CalendarMonthIcon} text="Consulta los horarios de servicio en cada tienda" title="Días de Servicio" link="/dias" />
        </CardSummaryGrid>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={PollIcon} text="Ayúdanos a mejorar. 
Rellena esta encuesta de calidad en menos de 1 minuto
" title="Encuesta" link="/encuesta" />
        </CardSummaryGrid>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={CloudDownload} text="Exporta registros de datos predefinidos" title="Exportación datos" link="/exportar-datos" />
        </CardSummaryGrid>
      </Grid>
      <Modal
        title={'Consulta de expedientes'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        footer={
          <FooterForm
            mainText
            text={labels.es.send}
            editing={false}
            closeModal={() => setOpenModal(false)}
            onSave={onSave}
            loading={loading}
            disabled={!question}
          />
        }
      >
        <TextInput
          noTopBorder
          value={question}
          handleChange={(e) => setQuestion(e.target.value)}
          name="name"
          text={labels.es.query}
          placeholder={labels.es.query}
          multiline
          rows={3}
        />
      </Modal>
    </Container>
  )
}
