import React, { useContext, useEffect, useState, useRef,useCallback } from 'react'
import Swal from 'sweetalert2'
import { Box, Stack, useTheme, IconButton, TextField } from '@mui/material'
import { deleteBookingsSwalConfig ,deleteBookingSameDay} from 'src/utils/swal.utils'
import TablePag from 'src/components/Table/TablePag'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { Clear } from '@mui/icons-material'
import { labels } from 'src/labels/main_labels'
import {
  deleteBooking
} from 'src/services/bookings.services'
import { sendNewBookingMessage } from 'src/services/emails.services'
import { AddButton } from 'src/components/Buttons/AddButton'
import BookingsAddEdit from './BookingsAddEdit'
import BookingsReSchedule from './BookingsReSchedule'
import FooterForm from 'src/components/Form/FooterForm'
import Modal from 'src/components/Modals/Modal'
import SignatureCanvas from 'react-signature-canvas'
import { useHistory } from 'react-router-dom'
import TextInput from 'src/components/Form/TextInput'
import SimpleSelect from 'src/components/Form/Select'
import { InfoContext } from 'src/context/info/infoContext'
import { LocalizationProvider, MobileDatePicker } from '@mui/lab'
import { es } from 'date-fns/locale'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import SelectDialog from 'src/components/Form/SelectDialog'
import { createIssue } from 'src/services/issues.services'
import { isSameDay } from 'date-fns'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'


const headCells: any[] = [
  {
    idField: 'date',
    align: 'left',
    disablePadding: true,
    label: labels.es.date,
    order: true,
    fieldtype: 'date-only'
  },
  {
    idField: 'hourFormatted',
    align: 'left',
    disablePadding: false,
    label: labels.es.hour,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'patient',
    align: 'left',
    disablePadding: true,
    label: labels.es.worker,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'CAM/INC/PC',
    align: 'left',
    disablePadding: true,
    label: 'CAM/INC/PC',
    order: true,
    fieldtype: 'typeBooking'
  },
  {
    idField: 'affectedZone',
    align: 'left',
    disablePadding: true,
    label: labels.es.reason,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'notes',
    align: 'left',
    disablePadding: true,
    label: labels.es.notes,
    fieldtype: 'string'
  },
  {
    idField: 'completed',
    align: 'left',
    disablePadding: false,
    label: labels.es.assist,
    order: true,
    fieldtype: 'booking-completed'
  }
]

const headCellsPhysio: any[] = [
  {
    idField: 'date',
    align: 'left',
    disablePadding: true,
    label: labels.es.date,
    order: true,
    fieldtype: 'date-only'
  },
  {
    idField: 'hourFormatted',
    align: 'left',
    disablePadding: false,
    label: labels.es.hour,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'patient',
    align: 'left',
    disablePadding: true,
    label: labels.es.worker,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'store',
    align: 'left',
    disablePadding: true,
    label: labels.es.store,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'CAM/INC/PC',
    align: 'left',
    disablePadding: true,
    label: 'CAM/INC/PC',
    order: true,
    fieldtype: 'typeBooking'
  },
  {
    idField: 'affectedZone',
    align: 'left',
    disablePadding: true,
    label: labels.es.reason,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'notes',
    align: 'left',
    disablePadding: true,
    label: labels.es.notes,
    fieldtype: 'string'
  },
  {
    idField: 'completed',
    align: 'left',
    disablePadding: false,
    label: labels.es.assist,
    order: true,
    fieldtype: 'booking-completed'
  }
]

const today = new Date().toLocaleDateString('fr-CA')

export default function Bookings(): JSX.Element {
  const { userData, user, firebase } = useContext(AuthContext)
  const history = useHistory()
  const theme = useTheme()
  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const { allCompanies, allStores } = useContext(InfoContext)
  const [data, setData] = useState<any | null>([])
  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  const [editDataSchedule, setEditDataSchedule] = useState<any | null>(null)
  const [initHeader, setInitHeader] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [loadingSign, setLoadingSign] = useState(false)
  const [bookingSelectedSign, setBookingSelectedSign] = useState<any | null>(null)
  const [question, setQuestion] = useState('')
  const [loading, setLoading] = useState(false)
  const [userToBook, setUserToBook] = useState<any | never>(null)
  const [openModal, setOpenModal] = useState(false)
  const [openModalSchedule, setOpenModalSchedule] = useState(false)
  const [openModalAskBooking, setOpenModalAskBooking] = useState(false)
  const [incidentSelectedClose, setIncidentSelectedClose] = useState<any | null>(null)
  const [reasonCloseIncident, setReasonCloseIncident] = useState<any | null>(null)
 const [onlyMyBookings, setOnlyMyBookings] = useState(false)
  const refSign = useRef()

  const [filterCompany, setFilterCompany] = useState<string>('')
  const [filterStore, setFilterStore] = useState<string>('')
  const [filterStoreOptions, setFilterStoreOptions] = useState<any[] | never>([])
  const [filterDate, setFilterDate] = useState<string | null>(today)

   // PAGINATION
   const [page, setPage] = useState(0)
  const [searchString, setSearchString] = useState('')


  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }

  const closeModalSchedule = () => {
    setOpenModalSchedule(false)
    setEditDataSchedule(null)
  }

  const [openSignModal, setOpenSignModal] = useState(false)
  const closeSignModal = () => {
    setOpenSignModal(false)
  }

  const [openCloseIncidentModal, setOpenCloseIncidentModal] = useState(false)
  const closeIncidentModal = () => {
    setOpenCloseIncidentModal(false)
  }

  interface PageData {
    [key: number]: any[]
  }

  interface State {
    totalCount: number
    lastVisible: any
    pagesData: PageData
  }

  const [state, setState] = useState<State>({
    totalCount: 0,
    lastVisible: null,
    pagesData: {}
  })

 
  const fetchData = async (pag?: number) => {

    if (loadingTable) return
    
    
   
    const pagina = pag || page
    // if (!initHeader && !searchString) setInitHeader(true)
    setLoadingTable(true)
    try {
     
  
      
      if (!state.pagesData[pagina]) {
      


      
        let query = firebase.db.collection('bookings').orderBy('date','desc').orderBy('hourFormatted','asc').limit(25)
        query = query.where('deleted', '==', false)

        if (searchString !== '') {
          const searchCoded = searchString.charAt(0).toUpperCase() + searchString.slice(1)
          query = query.where('patient.name', '>=', searchCoded)
        }
        if (filterCompany) {
          query = query.where('companyId', '==', `${filterCompany}`)
        }
        if (filterStore) {
          query = query.where('storeId', '==', `${filterStore}`)
        }

        if (filterDate) {

          query = query.where('date', '==', `${filterDate}`)
        }
        if ((userData.role.id === 'role3' || userData.role.id === '') && !onlyMyBookings) {
          query = query.where('companyId', '==', `${filterCompany}`)
        }

        if(userData.role.id === 'role2'){
        query = query.where('storeId', 'in', userData.storesWorking.map((store: any) => store.id))
        }
      
        if (['role4', 'role5'].includes(userData.role.id)  && !onlyMyBookings) {
          query = query.where('storeId', '==', `${userData?.storeId}`)
          query = query.where('companyId', '==', `${userData?.companyId}`)
        }

        if (['role6'].includes(userData.role.id) ||onlyMyBookings) {
          query = query.where('patient.id', '==', `${userData?.id}`)
        }

        if (state.lastVisible) {
          query = query.startAfter(state.lastVisible)
        }
      
        const querySnapshot = await query.get()
        const newPagesData = { ...state.pagesData }
        
        newPagesData[pagina] = querySnapshot.docs.map((doc: any) => doc.data())
        

        setData(data.concat(querySnapshot.docs.map((doc: any) => {  return { id: doc.id, ...doc.data() }})))
        setState({
          ...state,
          lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
          pagesData: newPagesData,
          totalCount: querySnapshot.size
        })
      } 
    } catch (error) {
      console.log(error)
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }


  const refreshSearch = async () => {
    if (filterCompany || filterStore || searchString || filterDate ||filterDate === null) {
    
      setData([])
      setPage(0)
      setState({ totalCount: 0, lastVisible: null, pagesData: {} })
    } 
   
  }

  useEffect(() => {
  
    if(!Object.keys(state.pagesData).length) fetchData(0)
   
  }, [state.pagesData])

  useEffect(() => {
    refreshSearch()
  }, [ filterCompany, filterStore, searchString,filterDate,onlyMyBookings])

  useEffect(() => {
    if (userData && userData.role.id) fetchData(page)
    if (userData.role.id === 'role3' && userData.companyId) setFilterCompany(userData.companyId)
  }, [page])

  // ANTIGUO GET DATA SIN PAGINACIÓN

  // const fetchBookingsList = async () => {
  //   setLoadingTable(true)
  //   let finalData
  //   try {
  //     if (userData.companyId && userData.storeId) {
  //       const resp = await getAllBookingsByCompanyAndStore(userData.companyId, userData.storeId)
  //       if (userData.role.id === 'role6') {
  //         const data = resp.filter((row: any) => row.deleted !== true && row.patient.id === userData.id)
  //         finalData = data
  //         finalData = data
  //       } else {
  //         const data = resp.filter((row: any) => row.deleted !== true)
  //         finalData = data
  //         finalData = data
  //       }
  //     } else if (userData.companyId) {
  //       const resp = await getAllBookingsByCompany(userData.companyId)
  //       const data = resp.filter((row: any) => row.deleted !== true)
  //       finalData = data
  //       finalData = data
  //     } else if (!userData.companyId && !userData.storeId && userData.role.id === 'role1') {
  //       const resp = await getAllBookings()
  //       const data = resp.filter((row: any) => row.deleted !== true)
  //       finalData = data
  //       finalData = data
  //     } else if (!userData.companyId && !userData.storeId && userData.role.id === 'role2') {
  //       const resp = await getAllBookingsForPhysio(userData.storesWorking.map((store: any) => store.id))
  //       const data = resp.filter((row: any) => row.deleted !== true)
  //       finalData = data
  //       finalData = data
  //     }

  //     if (finalData) finalData = finalData.sort((a: any, b: any) => a.hourFormatted.localeCompare(b.hourFormatted))
  //     if (finalData) finalData = finalData.sort((a: any, b: any) => a.date.localeCompare(b.date))
  //     setData(finalData)
  //     setRowsData(finalData)
  //     if (!initHeader) setInitHeader(true)
  //   } catch (error) {
  //     if (error instanceof Error) showSnackbar('error', error.message)
  //   } finally {
  //     setLoadingTable(false)
  //   }
  // }

  // const handleSearch = async (word: string) => {
  //   if (!word) {
  //     if (data) setRowsData(data.filter((elm: any) => elm.date === filterDate))
  //   } else {
  //     if (data) setRowsData(data.filter((booking: any) => booking.patient?.name.toLowerCase().includes(word.toLowerCase())))
  //   }
  // }

  const saveCloseIncident = async () => {
    try {
      await firebase.db
        .collection('incidents')
        .doc(incidentSelectedClose.incident.id)
        .update({ closed: { closedBy: user.user.uid, closeReason: reasonCloseIncident, closedAt: Date.now() } })
      await firebase.db
        .collection('bookings')
        .doc(incidentSelectedClose.id)
        .update({
          incident: {
            ...incidentSelectedClose.incident,
            all: {
              ...incidentSelectedClose.incident.all,
              closed: { closedBy: user.user.uid, closeReason: reasonCloseIncident, closedAt: Date.now() }
            }
          }
        })
      showSnackbar('success', labels.es.incidentCloseOk)
      closeIncidentModal()
      // await fetchBookingsList()
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const Header = () => {
    return (
      <Stack direction="row" justifyContent="space-between">
            <FormControlLabel
           labelPlacement='start'
          control={ 
            <Switch checked={onlyMyBookings} onClick={()=> setOnlyMyBookings(!onlyMyBookings)} name="myBookings"/>
          }
          label="Mis citas"
        /> 
        {/* <SearchInput placeholder={labels.es.searchBookings} name="search" handleSubmit={setSearchString} clean fullwidth /> */}
        {userData.role.id !== 'role6' && <AddButton onClick={() => setOpenModal(true)} text={labels.es.addBookings} />}
      </Stack>
    )
  }

  // useEffect(() => {
  //   if (userData) fetchBookingsList()
  // }, [])

  // useEffect(() => {
  //   if (!initHeader) return
  //   showHeader(Header)
  // }, [initHeader, data])

  useEffect(() => {
    // if (!initHeader) return
    showHeader(Header)
  }, [onlyMyBookings])

  const onDelete = async (data: any) => {  
    if(isSameDay(new Date(data?.date), new Date()) && userData.role.id ==="role6" ){
      return  Swal.fire(deleteBookingSameDay(theme.palette.primary.main))
    }
          Swal.fire(deleteBookingsSwalConfig(labels.es.booking, theme.palette.primary.main)).then(async (result) => {
        
            if (result.isConfirmed) {
           
              await deleteBooking(data.id)
              await createIssue({...data,issueType: 'Cancelación',cancelledAt: Date.now(), cancelationReason: result.value, cancelledBy: userData?.name || '', cancelledByLastname: userData?.lastname || '', cancelledById : userData.id || 'no tiene', cancelledBooking :data.bookingId})
              .then(async (docRef) => {
                await firebase.db.collection('issues').doc(docRef.id).update({issueId:docRef.id})
                .then(async () => {
                  showSnackbar('success', labels.es.bookingDeleted)
                  closeModal()
                  // await fetchBookingsList()
                  setSelected([])
                  refreshSearch()
                })
                })
              .catch((error) => {
                if (error instanceof Error) showSnackbar('error', error.message)
              })
      
        }
      })    
    }

    const onEdit = async (data: Record<string, any>) => {
    // const resp = await getCompanyById(data.id)

    setEditData(data)
    setOpenModal(true)
  }


  const onReSchedule = async (data: Record<string, any>) => {
    // const resp = await getCompanyById(data.id)

    setEditDataSchedule(data)
    setOpenModalSchedule(true)
  }

  const onDeactivate = async (data: Record<string, any>) => {
    setBookingSelectedSign(data.id)
    setOpenSignModal(true)
  }

  const onCloseIncident = async (data: Record<string, any>) => {
    setIncidentSelectedClose(data)
    setOpenCloseIncidentModal(true)
  }

  const onRowClick = (row: Record<string, any>) => {
    if(userData.role.id !== "role6") history.push(`/citas/${row.id}`)
  }

  const sendAskBooking = (data: any) => {
    setOpenModalAskBooking(true)
    setUserToBook(data)
  }

  const sendBookingRequest = async () => {
    if (question !== '' && userToBook) {
      const data = {
        message: question,
        emailTo: userToBook?.email,
        physioName: userData?.name + ' ' + userData?.lastname + ' ' + userData?.lastname2
      }
      setLoading(true)
      try {
        await sendNewBookingMessage(data)
        showSnackbar('success', labels.es.questionSended)
        setQuestion('')
        setOpenModalAskBooking(false)
        setUserToBook(null)
      } catch (error: any) {
        showSnackbar('error', error.message)
        setOpenModalAskBooking(false)
      } finally {
        setLoading(false)
      }
    }
  }



  const onSignSave = async () => {
    try {
      setLoadingSign(true)
      const imgSign = (refSign as any).current?.getTrimmedCanvas().toDataURL('image/png')
      const ref = firebase.files.ref().child(`bookings/${bookingSelectedSign}`)

      ref.putString(imgSign, 'data_url').then(() => {
        firebase.files
          .ref()
          .child(`bookings/${bookingSelectedSign}`)
          .getDownloadURL()
          .catch((error: any) => console.log('error uploading sign', error))
          .then(async (url: string) => {
            await firebase.db.collection('bookings').doc(bookingSelectedSign).update({ signImg: url, completed: true })
          })
          .catch((error: any) => console.log('error saving sign', error))

          .then(async () => {
            showSnackbar('success', labels.es.bookingCompleted)
            // await fetchBookingsList()
          })
          .catch((error: any) => console.log('error uploading sign', error))
      })
    } catch (error) {
      showSnackbar('error', labels.es.bookingCompletedError)
    }
    finally{
      setLoadingSign(false)
      setBookingSelectedSign(null)
      closeSignModal()
      refreshSearch()
    }
  }

  const filterCompanyOptions = [{ id: '', name: labels.es.filterByCompany }, ...allCompanies].map((elm) => ({
    value: elm.id,
    label: elm.name
  }))

  useEffect(() => {
    setFilterStore('')
  
    const loadfilterStoreOptions = allStores
      .filter((elm) => elm.companyId === filterCompany)
      .map((elm) => ({
        value: elm.id,
        label: elm.storeName
      }))
     

    setFilterStoreOptions([{ value: '', label: labels.es.filterByStore }, ...loadfilterStoreOptions])
  }, [filterCompany])

  function handleClr(e:any) {
    e.stopPropagation();
    setFilterDate(null);
  } 

  return (
    <>
      <TablePag
        noCheckbox
        headCells={['role1','role2','role3'].includes(userData.role.id) ? headCellsPhysio : headCells}
        rows={data}
        snackBar
        onDelete={onDelete}
        onEdit={onEdit}
        onReSchedule={onReSchedule}
        loading={loadingTable}
        selected={selected}
        setSelected={setSelected}
        onDeactivate={onDeactivate}
        onSendMail={sendAskBooking}
        page={page}
        setPage={setPage}
        onCloseIncident={onCloseIncident}
        onRowClick={onRowClick}
        filters={
          userData.role.id === 'role1' ? (
            <>
              <Box
                sx={{
                  // width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  '> div': { ml: 2, my: theme.breakpoints.down('sm') ? 0.5 : 0 },
                  [theme.breakpoints.down('sm')]: {
                    overflowX: 'scroll'
                  }
                }}
              >
              
                <SimpleSelect
                  minWidth={200}
                  name="filterCompany"
                  data={filterCompany}
                  handleChange={(e) => setFilterCompany(e.target.value)}
                  options={filterCompanyOptions}
                />
                {filterStoreOptions && filterCompany && (
                  <SimpleSelect
                    minWidth={200}
                    name="filterStore"
                    data={filterStore}
                    handleChange={(e) => setFilterStore(e.target.value)}
                    options={filterStoreOptions}
                
                  />
                )}
                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    toolbarTitle="Selecciona una fecha"
                    cancelText="Cancelar"
                    value={filterDate}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={(e) => handleClr(e)}>
                          <Clear />
                        </IconButton>
                      )
                    }}
                    onChange={(input) => {
                      if (input) {
                        setFilterDate((input as unknown as Date).toLocaleDateString('fr-CA'))
                      }
                    }}
                    
                    renderInput={(params) => {
                      const openClick: () => void = (params as any).InputProps?.endAdornment?._owner?.pendingProps?.openPicker
                      return (
                        <TextField
                          sx={{
                            width: 130,
                            alignItems: 'center',
                            '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                            '.MuiInputBase-root:after': { borderBottom: 'none !important' },
                            input: { fontFamily: 'Inter', fontSize: 14 },
                            label: { fontFamily: 'Inter', fontSize: 14 }
                          }}
                          variant="standard"
                          {...params}
                          onClick={openClick}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Elige una fecha"
                          }}
                        />
                      )
                    }}
                  />
                </LocalizationProvider>
             
              </Box>
            </>
          ) : userData.role.id === 'role2' ? (
            <>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  '> div': { ml: 2, my: theme.breakpoints.down('sm') ? 0.5 : 0 },
                  [theme.breakpoints.down('sm')]: {
                    overflowX: 'scroll'
                  }
                }}
              >
                <SimpleSelect
                  minWidth={200}
                  name="filterStore"
                  data={filterStore}
                  handleChange={(e) => setFilterStore(e.target.value)}
                  options={[{ id: '', name: labels.es.filterByStore }, ...userData.storesWorking].map((elm: any) => ({
                    value: elm.id,
                    label: elm.name
                  }))}
                />
                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    value={filterDate}
                    toolbarTitle="Selecciona una fecha"
                    cancelText="Cancelar"
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={(e) => handleClr(e)}>
                          <Clear />
                        </IconButton>
                      )
                    }}
                    onChange={(input) => {
                      if (input) {
                        setFilterDate((input as unknown as Date).toLocaleDateString('fr-CA'))
                      }
                    }}
                    renderInput={(params) => {
                      const openClick: () => void = (params as any).InputProps?.endAdornment?._owner?.pendingProps?.openPicker
                      return (
                        <TextField
                          sx={{
                            width: 130,
                            alignItems: 'center',
                            '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                            '.MuiInputBase-root:after': { borderBottom: 'none !important' },
                            input: { fontFamily: 'Inter', fontSize: 14 },
                            label: { fontFamily: 'Inter', fontSize: 14 }
                          }}
                          variant="standard"
                          {...params}
                          onClick={openClick}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Elige una fecha"
                          }}
                        />
                      )
                    }}
                  />
                </LocalizationProvider>
                
              </Box>
            </>
          ) : userData.role.id === 'role3' ? (
            <>
              {filterStoreOptions && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    '> div': { ml: 2, my: theme.breakpoints.down('sm') ? 0.5 : 0 },
                    [theme.breakpoints.down('sm')]: {
                      overflowX: 'scroll'
                    }
                  }}
                >
                  <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={filterDate}
                    toolbarTitle="Selecciona una fecha"
                    cancelText="Cancelar"
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={(e) => handleClr(e)}>
                            <Clear />
                          </IconButton>
                        )
                      }}
                      onChange={(input) => {
                        if (input) {
                          setFilterDate((input as unknown as Date).toLocaleDateString('fr-CA'))
                        }
                      }}
                      renderInput={(params) => {
                        const openClick: () => void = (params as any).InputProps?.endAdornment?._owner?.pendingProps?.openPicker
                        return (
                          <TextField
                            sx={{
                              width: 130,
                              alignItems: 'center',
                              '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                              '.MuiInputBase-root:after': { borderBottom: 'none !important' },
                              input: { fontFamily: 'Inter', fontSize: 14 },
                              label: { fontFamily: 'Inter', fontSize: 14 }
                            }}
                            variant="standard"
                            {...params}
                            onClick={openClick}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "Elige una fecha"
                            }}
                          />
                        )
                      }}
                    />
                  </LocalizationProvider>
                  <SimpleSelect
                    minWidth={200}
                    name="filterStore"
                    data={filterStore}
                    handleChange={(e) => setFilterStore(e.target.value)}
                    options={filterStoreOptions}
                  />
                  {/* <IconButton sx={{ mx: 2 }} color="primary" onClick={cleanFilters}>
                    <FilterAltOffIcon />
                  </IconButton> */}
                </Box>
              )}
            </>
          ) : (
            <>
              {' '}
              <Box sx={{ ml: 2 }}>
                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    value={filterDate}
                    toolbarTitle="Selecciona una fecha"
                    cancelText="Cancelar"
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={(e) => handleClr(e)}>
                          <Clear />
                        </IconButton>
                      )
                    }}
                    onChange={(input) => {
                      if (input) {
                        setFilterDate((input as unknown as Date).toLocaleDateString('fr-CA'))
                      }
                    }}
                    renderInput={(params) => {
                      const openClick: () => void = (params as any).InputProps?.endAdornment?._owner?.pendingProps?.openPicker
                      return (
                        <TextField
                          sx={{
                            width: 160,
                            alignItems: 'center',
                            '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                            '.MuiInputBase-root:after': { borderBottom: 'none !important' },
                            input: { fontFamily: 'Inter', fontSize: 14 },
                            label: { fontFamily: 'Inter', fontSize: 14 }
                          }}
                          variant="standard"
                          {...params}
                          onClick={openClick}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Elige una fecha"
                          }}
                        />
                      )
                    }}
                  />
                </LocalizationProvider>
                
              </Box>
            </>
          )
        }
      />
      <BookingsAddEdit
        openModal={openModal}
        closeModal={closeModal}
        dataToEdit={editData}
        onDelete={onDelete}
        fetchData={refreshSearch}
      />
      <BookingsReSchedule
        openModal={openModalSchedule}
        closeModal={closeModalSchedule}
        dataToEdit={editDataSchedule}
        fetchData={refreshSearch}
      />
      <Modal
        title={labels.es.confirmWithSign}
        open={openSignModal}
        handleClose={closeSignModal}
        footer={
          <FooterForm
            text={labels.es.save}
            mainText
            closeModal={closeSignModal}
            onSave={onSignSave}
            loading={loadingSign}
            editing={false}
            // disabled={si no hay firma}
          />
        }
      >
        <SignatureCanvas penColor="black" canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} ref={refSign} />
      </Modal>
      <Modal
        title={labels.es.closeReason}
        open={openCloseIncidentModal}
        handleClose={closeIncidentModal}
        footer={
          <FooterForm
            text={labels.es.save}
            mainText
            closeModal={closeIncidentModal}
            onSave={saveCloseIncident}
            // loading={loadingSign}
            editing={false}
            // disabled={si no hay firma}
          />
        }
      >
        <SelectDialog
          text={labels.es.reason}
          setData={setReasonCloseIncident}
          data={reasonCloseIncident}
          options={[
            { name: 'Alta', id: 'alta' },
            { name: 'Sin Resolución', id: 'sinresolución' }
          ].map((elm: any) => {
            return { name: elm.name, id: elm.id }
          })}
        />
      </Modal>
      <Modal
        title={'Solicitud nueva cita'}
        open={openModalAskBooking}
        handleClose={() => setOpenModalAskBooking(false)}
        footer={
          <FooterForm
            mainText
            text={labels.es.send}
            editing={false}
            closeModal={() => setOpenModalAskBooking(false)}
            onSave={sendBookingRequest}
            loading={loading}
            disabled={!question}
          />
        }
      >
        <TextInput
          noTopBorder
          value={question}
          handleChange={(e) => setQuestion(e.target.value)}
          name="name"
          text={labels.es.query}
          placeholder={labels.es.query}
          multiline
          rows={3}
        />
      </Modal>
    </>
  )
}
