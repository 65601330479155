import React, { useContext, useEffect, useState } from 'react'
import { IconButton, LinearProgress, Stack, Typography, Button } from '@mui/material'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'

import { labels } from 'src/labels/main_labels'

import { useParams, useHistory } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { grey } from '@mui/material/colors'
import { getCampaingData } from 'src/services/campaigns.services'
import { ContainerFud } from 'src/styles/general-styles'
import TextInput from 'src/components/Form/TextInput'
import { saveAs } from 'file-saver'
import { Image, Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer'

// rename helper for react18 overload
const MyDocument: any = Document
const MyPage: any = Page

// Create styles for PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  text: {
    fontSize: 14,
    fontFamily: 'Courier',
    fontWeight: 'normal'
  }
})

export default function CampaignDetail(): JSX.Element {
  const { idCampaign } = useParams<{ idCampaign: string }>()
  const history = useHistory()

  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const [data, setData] = useState<any | null>(null)
  const [loading, setLoading] = useState(false)

  const MyDocumentPdf = (data: any) => (
    <MyDocument>
      <MyPage size="A4" style={styles.page} orientation={'landscape'}>
        
        <View style={styles.section}>
        <Text style={styles.text}>
            {labels.es.company}: #{data?.values.company.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.store}: #{data?.values.store.name}
          </Text>
          <Text style={styles.text}>Campaña: #{data?.values.name}</Text>
          <Text style={styles.text}>
            {labels.es.userResponsable}: {data?.values.userResponsable.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.description} {data?.values?.description}
          </Text>
          <Text style={styles.text}>
            {labels.es.sector}: {data?.values?.sector.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.process}: {data?.values?.process.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.product}: {data?.values?.product}
          </Text>
          <Text style={styles.text}>
            {labels.es.machine}: {data?.values?.machine?.name}
          </Text>
        </View>
        <Image
          style={{ position: 'absolute', bottom: 20, right: 0, height: 200, width: 200 }}
          src={require('../../../assets/icons/general/MetaphysioLogo.png')}
          cache={false}
        />
      </MyPage>
    </MyDocument>
  )

  const Header = () => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <IconButton sx={{ ml: '-14px' }} onClick={() => history.goBack()}>
          <ChevronLeftIcon sx={{ color: grey[500] }} />
        </IconButton>

        <Typography variant="subtitle1" component="h1">
          {labels.es.campaignDetail}
        </Typography>
        <div />
      </Stack>
    )
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const resp = await getCampaingData(idCampaign)
      setData(resp)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()

    showHeader(Header)
  }, [])

  return (
    <>
      {loading && <LinearProgress />}

      {data && (
        <>
          <ContainerFud>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                size="small"
                variant="outlined"
                // className={classes.newOrderButton}
                style={{ marginBottom: 10 }}
                onClick={async () => {
                  const doc = <MyDocumentPdf values={data} />
                  const asPdf = pdf() // {} is important, throws without an argument
                  asPdf.updateContainer(doc)
                  const blob = await asPdf.toBlob()
                  saveAs(blob, `Campaña#${data && data.name}.pdf`)
                }}
              >
                Descargar PDF
              </Button>
            </div>
            <TextInput disabled value={data.company.name || ''} name="company" text={labels.es.company} placeholder={''} />
          <TextInput disabled value={data.store.name || ''} name="store" text={labels.es.store} placeholder={''} />

            <TextInput disabled value={data ? data.name : ''} name="campaign" text={labels.es.campaignName} placeholder={''} />
            {/* <TextInput disabled value={campaign.campaignId || ''} name="campaign" text={labels.es.campaignId} placeholder={''} /> */}
            <TextInput disabled value={data.userResponsable.name || ''} name="campaign" text={labels.es.userResponsable} placeholder={''} />
            <TextInput disabled value={data.description || ''} name="campaign" text={labels.es.description} placeholder={''} />
            <TextInput disabled value={data.sector ? data.sector.name : ''} name="sector" text={labels.es.sector} placeholder={''} />
            <TextInput disabled value={data.process ? data.process.name : ''} name="process" text={labels.es.process} placeholder={''} />
            <TextInput disabled value={data.product || ''} name="product" text={labels.es.product} placeholder={''} />
            <TextInput disabled value={data.machine ? data.machine.name : ''} name="sector" text={labels.es.machine} placeholder={''} />
            {/* <TextInput
              disabled
              value={data.affectedZone ? data.affectedZone.name : ''}
              name="affectedZone"
              text={labels.es.affectedZone}
              placeholder={''}
            /> */}
          </ContainerFud>
        </>
      )}
    </>
  )
}
