import React, { PropsWithChildren } from 'react'
import { DialogContent, DialogActions, Breakpoint, Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { ModalTitle, ModalDialog } from './modals.styles'

export interface Props {
  open: boolean
  handleClose: () => void
  title: string
  text?: string
  type?: string
  size?: Breakpoint
  noIcon?: boolean
  btnText?: string
  children?: React.ReactNode
  footer?: JSX.Element
  modal2?: boolean
  noCloseIcon?: boolean
}

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
  modal2?: boolean
  noCloseIcon?: boolean
}

const ModalTopTitle = (props: DialogTitleProps) => {
  const { children, modal2, onClose, noCloseIcon, ...other } = props

  return (
    <ModalTitle
      sx={{
        fontSize: modal2 ? '20px' : '18px',
        fontWeight: modal2 ? 700 : 500,
        padding: modal2 ? '12px 16px 12px 16px' : 2,
        paddingLeft: modal2 ? '16px' : 3,
        '& .MuiButtonBase-root': {
          p: modal2 ? '4px' : ''
        }
      }}
      {...other}
    >
      {children}
      {!noCloseIcon ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : (
        <Box sx={{ height: 40 }} />
      )}
    </ModalTitle>
  )
}
export default function Modal({
  open,
  handleClose,
  title,
  size = 'sm',
  children,
  footer,
  modal2,
  noCloseIcon
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <ModalDialog
    hideBackdrop // Disable the backdrop color/image
    disableEnforceFocus // Let the user focus on elements outside the dialog
    
    fullWidth maxWidth={size} aria-labelledby={title.replace(' ', '-')} open={open}>
      <ModalTopTitle id={title.replace(' ', '-')} onClose={handleClose} modal2={modal2} noCloseIcon={noCloseIcon}>
        {title}
      </ModalTopTitle>
      <DialogContent dividers>{children}</DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </ModalDialog>
  )
}
