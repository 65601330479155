import React, { useContext, useEffect, useState } from 'react'
import { IconButton, LinearProgress, Stack, Typography, Button } from '@mui/material'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { labels } from 'src/labels/main_labels'
import { useParams, useHistory } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { grey } from '@mui/material/colors'
import { ContainerFud } from 'src/styles/general-styles'
import TextInput from 'src/components/Form/TextInput'
import { dateTimeConvert } from 'src/utils/time-helpers'
import { getIncidentData } from 'src/services/incidents.services'
import { saveAs } from 'file-saver'
import { Image, Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer'

// rename helper for react18 overload
const MyDocument: any = Document
const MyPage: any = Page

// Create styles for PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  text: {
    fontSize: 14,
    fontFamily: 'Courier',
    fontWeight: 'normal'
  }
})

export default function BookingsDetail(): JSX.Element {
  const { idIncident } = useParams<{ idIncident: string }>()
  const history = useHistory()

  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const [data, setData] = useState<any | null>(null)

  const [loading, setLoading] = useState(false)

  const MyDocumentPdf = (data: any) => (
    <MyDocument>
      <MyPage size="A4" style={styles.page} orientation={'landscape'}>
        <View style={styles.section}>
        <Text style={styles.text}>
            {labels.es.company}: #{data?.values.company.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.store}: #{data?.values.store.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.incident}: #{data?.values.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.userResponsable}: {data?.values.userResponsable.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.worker}: {data?.values.injuredWorker?.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.signed}:{' '}
            {!data?.values.signInjuredWorker && !data?.values.signResponsable
              ? 'Faltan ambas'
              : data?.values.signInjuredWorker && !data?.values.signResponsable
              ? 'Falta Responsable'
              : !data?.values.signInjuredWorker && data?.values.signResponsable
              ? 'Falta Colaborador'
              : data?.values.signInjuredWorker && data?.values.signResponsable && 'Firmado'}
          </Text>
          <Text style={styles.text}>
            {labels.es.description}: {data?.values?.description}
          </Text>
          <Text style={styles.text}>
            {labels.es.workWindow}: {data?.values?.timeWindow.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.sector}: {data?.values?.sector.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.process}: {data?.values?.process.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.product}: {data?.values?.product}
          </Text>
          <Text style={styles.text}>
            {labels.es.machine}: {data?.values?.machine?.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.affectedZone}: {data?.values?.affectedZone.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.daysSinceDayOff}: {data?.values?.daysSinceDayOff}
          </Text>
          <Text style={styles.text}>
            {labels.es.whatHappened}: {data?.values?.whatHappened}
          </Text>
          <Text style={styles.text}>
            {labels.es.why}: {data?.values?.why}
          </Text>
          <Text style={styles.text}>
            {labels.es.when} {data?.values?.when}
          </Text>
          <Text style={styles.text}>
            {labels.es.where} {data?.values?.where.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.how}: {data?.values?.how.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.sessions}: {data?.values?.sessions}
          </Text>
          <Text style={styles.text}>
            {labels.es.whoParticipatedAnalyse}:
            {data?.values.whoParticipatedAnalyse.map((elm: any, idx: number) => {
              return elm?.name + ' '
            }) || ''}
          </Text>
          <Text style={styles.text}>
            {labels.es.testigos}:
            {data?.values.testigos.map((elm: any, idx: number) => {
              return elm?.name + ' '
            }) || ''}
          </Text>

          {data.values.closed && (
            <>
              <Text style={styles.text}>
                {labels.es.closeReason} {data?.values?.closed.closeReason.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.closeDate} {new Date(data.values.closed.closedAt).toLocaleDateString('es-ES')}
              </Text>
            </>
          )}
        </View>
        <Image
          style={{ position: 'absolute', bottom: 20, right: 0, height: 200, width: 200 }}
          src={require('../../../assets/icons/general/MetaphysioLogo.png')}
          cache={false}
        />
      </MyPage>
    </MyDocument>
  )

  const Header = () => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <IconButton sx={{ ml: '-14px' }} onClick={() => history.goBack()}>
          <ChevronLeftIcon sx={{ color: grey[500] }} />
        </IconButton>

        <Typography variant="subtitle1" component="h1">
          {labels.es.incidentDetail}
        </Typography>
        <div />
      </Stack>
    )
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const resp = await getIncidentData(idIncident)
      setData(resp)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()

    showHeader(Header)
  }, [])

  return (
    <>
      {loading && <LinearProgress />}
      {data && (
        <ContainerFud>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              size="small"
              variant="outlined"
              // className={classes.newOrderButton}
              style={{ marginBottom: 10 }}
              onClick={async () => {
                const doc = <MyDocumentPdf values={data} />
                const asPdf = pdf() // {} is important, throws without an argument
                asPdf.updateContainer(doc)
                const blob = await asPdf.toBlob()
                saveAs(blob, `Incidente#${data && data.name}.pdf`)
              }}
            >
              Descargar PDF
            </Button>
          </div>
          {/* <TextInput noTopBorder disabled value={data.bookingId || ''} name="bookingId" text={labels.es.bookingId} placeholder={''} /> */}
          {/* {data.company && <TextInput disabled value={data.company.name || ''} name="company" text={labels.es.company} placeholder={''} />}
          {data.store && <TextInput disabled value={data.store.name || ''} name="store" text={labels.es.store} placeholder={''} />}
          */}
          <TextInput disabled value={data.company.name || ''} name="company" text={labels.es.company} placeholder={''} />
          <TextInput disabled value={data.store.name || ''} name="store" text={labels.es.store} placeholder={''} />

          <TextInput disabled value={data.name || ''} name="incident" text={labels.es.incident} placeholder={''} />
          <TextInput disabled value={data.userResponsable.name} name="description" text={labels.es.userResponsable} placeholder={''} />
          <TextInput disabled value={data.injuredWorker.name} name="worker" text={labels.es.worker} placeholder={''} />
          <TextInput
            name="signed"
            text={labels.es.signed}
            placeholder=""
            disabled
            value={
              !data?.signInjuredWorker && !data?.signResponsable
                ? 'Faltan ambas'
                : data?.signInjuredWorker && !data?.signResponsable
                ? 'Falta Responsable'
                : !data?.signInjuredWorker && data?.signResponsable
                ? 'Falta Colaborador'
                : data?.signInjuredWorker && data?.signResponsable && 'Firmado'
            }
          ></TextInput>
          <TextInput disabled value={data.description} name="description" text={labels.es.description} placeholder={''} />
          <TextInput disabled value={data.timeWindow.name} name="timeWindow" text={labels.es.workWindow} placeholder={''} />
          <TextInput disabled value={data.sector ? data.sector.name : ''} name="sector" text={labels.es.sector} placeholder={''} />
          <TextInput disabled value={data.process ? data.process.name : ''} name="sector" text={labels.es.process} placeholder={''} />
          <TextInput disabled value={data.product} name="product" text={labels.es.product} placeholder={labels.es.product} />
          <TextInput disabled value={data.machine ? data.machine.name : ''} name="machine" text={labels.es.machine} placeholder={''} />
          <TextInput
            disabled
            value={data.affectedZone ? data.affectedZone?.name : ''}
            name="affectedZone"
            text={labels.es.affectedZone}
            placeholder={labels.es.affectedZone}
          />
          <TextInput disabled value={data.daysSinceDayOff} name="daysSinceDayOff" text={labels.es.daysSinceDayOff} placeholder={''} />
          <TextInput disabled value={data.whatHappened || ''} name="data" text={labels.es.whatHappened} placeholder={''} />
          <TextInput disabled value={data.why || ''} name="data" text={labels.es.why} placeholder={''} />

          <TextInput
            disabled
            value={data.when ? dateTimeConvert(data.when, true) : ''}
            name="when"
            text={labels.es.when}
            placeholder={''}
          />
          <TextInput
            disabled
            value={
              data?.whoParticipatedAnalyse.map((elm: any, idx: number) => {
                return elm?.name + ' '
              }) || ''
            }
            name="whoParticipatedAnalyse"
            text={labels.es.whoParticipatedAnalyse}
            placeholder={''}
          />
          <TextInput
            disabled
            value={
              data?.testigos.map((elm: any, idx: number) => {
                return elm?.name + ' '
              }) || ''
            }
            name="testigos"
            text={labels.es.testigos}
            placeholder={''}
          />

          <TextInput disabled value={data.where.name || ''} name="where" text={labels.es.where} placeholder={''} />
          <TextInput disabled value={data.how.name || ''} name="how" text={labels.es.how} placeholder={''} />
          <TextInput disabled value={data.sessions || ''} name="sessions" text={labels.es.sessions} placeholder={''} />
          {data.closed && (
            <>
              <TextInput disabled value={data.closed.closeReason.name || ''} name="where" text={labels.es.reason} placeholder={''} />
              <TextInput
                disabled
                value={new Date(data.closed.closedAt).toLocaleDateString('es-ES') || ''}
                name="where"
                text={labels.es.closeDate}
                placeholder={''}
              />
            </>
          )}

          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {data.signResponsable && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{data.userResponsable.name}</span>
                {data.signResponsable && <img src={data.signResponsable} width={'40%'} />}
              </div>
            )}
            {data.signInjuredWorker && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{data.injuredWorker.name}</span>
                {data.signInjuredWorker && <img src={data.signInjuredWorker} width={'40%'} />}
              </div>
            )}
          </div>
        </ContainerFud>
      )}
    </>
  )
}
