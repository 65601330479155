import { useState, ChangeEvent, useEffect } from 'react'

export const useForm = <T extends Record<string, any>>(initialValues: T, form: T): any => {
  const [state, setState] = useState<any | null>(initialValues)
  const [photo, setPhoto] = useState<string | null>(null)

  const resetForm = () => {
    setState(initialValues)
    setPhoto(null)
  }

  useEffect(() => {
    if (initialValues.photo) setPhoto(form.photo || initialValues.photo)
    if (form) {
      const formTemp = { ...initialValues, ...form }
      setState(formTemp)
    }
    return () => resetForm()
  }, [form])

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.name.includes('.')) {
      const parent = event.target.name.split('.')[0]
      const child = event.target.name.split('.')[1]
      setState({ ...state, [parent]: { ...state[parent], [child]: event.target.value } })
      return
    }
    setState({ ...state, [event.target.name]: event.target.value })
  }

  return {
    values: { ...state },
    photo: photo,
    handleChange,
    handleSwitch,
    resetForm
  }
}
