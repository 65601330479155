import React, { PropsWithChildren, useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import { Dialog, DialogContentText, DialogContent, DialogActions, Breakpoint, Box, FormHelperText } from '@mui/material'
import { ReactComponent as ErrorRed } from 'src/assets/icons/dialog-icons/ErrorRed.svg'
import { ReactComponent as SuccessGreen } from 'src/assets/icons/dialog-icons/SuccessGreen.svg'
import { ReactComponent as SuccessRed } from 'src/assets/icons/dialog-icons/SuccessRed.svg'
import { ModalTitle } from './modals.styles'
import { labels, labels as labelsT } from 'src/labels/main_labels'
import { Input } from 'src/pages/Welcome/welcome.styles'
import { validateEmail } from 'src/utils/validateForm'
import { saveAs } from 'file-saver'
import { LoadingButton } from '@mui/lab'

interface Props {
  open: boolean
  handleClose: () => void
  handleAction?: () => void
  title: string
  text?: string
  size?: Breakpoint
  noIcon?: boolean
  noFooter?: boolean
  btnText?: string
  children?: React.ReactNode
  category?: string
  name?: string
  qrCode?: string
}

export const SimpleDialog = ({
  open,
  handleClose,
  title,
  text,
  size = 'xs',
  noIcon,
  btnText,
  noFooter,
  category,
  children
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <Dialog fullWidth maxWidth={size} open={open}>
      <Box sx={{ mt: 3 }} className="flex-column-center ">
        {!noIcon && category === 'error' && <ErrorRed />}
        {!noIcon && category === 'successRed' && <SuccessRed />}
        {!noIcon && !['error', 'successRed'].includes(category || '') && <SuccessGreen />}
        {children && children}
        <ModalTitle sx={{ pb: 0 }}>{title}</ModalTitle>
      </Box>
      <DialogContent sx={{ pb: 0, pt: 1 }}>
        {text && <DialogContentText sx={{ textAlign: 'center' }}>{text}</DialogContentText>}
      </DialogContent>
      {!noFooter && (
        <DialogActions>
          <Button type="submit" fullWidth variant="contained" sx={{ m: 2 }} onClick={handleClose}>
            {btnText || 'Aceptar'}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export const QrDialog = ({ open, handleClose, title, text, size = 'xs', name, qrCode }: PropsWithChildren<Props>): JSX.Element => {
  const handleDownload = () => {
    if (qrCode) {
      const convertBase64ToFile = (base64String: any, fileName: any) => {
        const arr = base64String.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const uint8Array = new Uint8Array(n)
        while (n--) {
          uint8Array[n] = bstr.charCodeAt(n)
        }
        const file = new File([uint8Array], fileName, { type: mime })
        return file
      }

      const file = convertBase64ToFile(qrCode, name)
      saveAs(file, name)
    }
  }

  return (
    <Dialog fullWidth maxWidth={size} open={open}>
      <Box sx={{ mt: 3 }} className="flex-column-center ">
        {qrCode && <img width={200} height={200} src={qrCode} alt={`qrcode-${name}`} />}
        <ModalTitle sx={{ pb: 0 }}>{title}</ModalTitle>
      </Box>
      <DialogContent sx={{ pb: 0, pt: 1 }}>
        {text && <DialogContentText sx={{ textAlign: 'center' }}>{text}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button color="info" fullWidth variant="outlined" sx={{ m: 2 }} onClick={handleDownload}>
          {labels.es.downloadQr}
        </Button>
        <Button fullWidth variant="contained" sx={{ m: 2 }} onClick={handleClose}>
          {labels.es.accept}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const QuestionDialog = ({
  open,
  handleClose,
  title,
  text,
  size = 'xs',
  noIcon,
  noFooter,
  btnText,
  handleAction
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <Dialog fullWidth maxWidth={size} open={open}>
      <Box sx={{ mt: 3 }} className="flex-column-center ">
        {!noIcon && <SuccessGreen />}
        <ModalTitle sx={{ pb: 0, textAlign: 'center' }}>{title}</ModalTitle>
      </Box>
      {text && (
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText sx={{ textAlign: 'center' }}>{text}</DialogContentText>
        </DialogContent>
      )}
      {!noFooter && (
        <DialogActions>
          <Button type="submit" fullWidth variant="contained" sx={{ m: 2 }} onClick={handleAction}>
            {btnText || 'Aceptar'}
          </Button>
          <Button type="submit" color="info" fullWidth variant="outlined" sx={{ m: 2 }} onClick={handleClose}>
            {'Cancelar'}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export const DeleteDialog = ({ open, handleClose, handleAction }: PropsWithChildren<Props>): JSX.Element => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <Box sx={{ mt: 3 }} className="flex-column-center ">
        <SuccessGreen />
        <ModalTitle sx={{ pb: 0, textAlign: 'center' }}>¿Estás seguro?</ModalTitle>
      </Box>

      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText sx={{ textAlign: 'center' }}>No podrás recuperar el elemento borrado</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="info" fullWidth variant="outlined" sx={{ m: 2 }} onClick={handleClose}>
          {'Cancelar'}
        </Button>
        <Button type="submit" fullWidth variant="contained" sx={{ m: 2 }} onClick={handleAction}>
          {'Si'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface DialogRecoverProps {
  open: boolean
  handleClose: () => void
  handleAction: (email: string) => void
  loading: boolean
}

export const RecoverPasswordDialog = ({ open, handleClose, handleAction, loading }: DialogRecoverProps): JSX.Element => {
  const [state, setState] = useState('')
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setError(null)
  }, [open])

  const submit = () => {
    setError(null)
    if (validateEmail(state)) {
      handleAction(state)
    } else {
      setError(labelsT.es.emailError)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState(e.target.value)
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText sx={{ textAlign: 'center', mb: 2 }}>{labelsT.es.recoverPasswordText}</DialogContentText>
        <Input
          placeholder={labelsT.es.email}
          required
          fullWidth
          name="email"
          type="email"
          id="email"
          autoComplete="recover-email"
          onChange={(e) => handleChange(e)}
        />
        {error ? (
          <FormHelperText sx={{ mt: '4px' }} error id="form-login">
            {error}
          </FormHelperText>
        ) : (
          <Box sx={{ mt: 3 }} />
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} type="submit" fullWidth variant="contained" sx={{ mx: 2, mb: 2 }} onClick={submit}>
          {labelsT.es.recoverPassword}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
