import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Alert, Box, Stack, useTheme } from '@mui/material'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import SearchInput from 'src/components/SearchInput'
import RolesAddEdit from './RolesAddEdit'
import Table from 'src/components/Table/Table'
import deleteElements from 'src/services/deleteElements'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { labels } from 'src/labels/main_labels'
import { AddButton } from 'src/components/Buttons/AddButton'
import { InfoContext } from 'src/context/info/infoContext'
import { AuthContext } from 'src/context/auth/authContext'
import SimpleSelect from 'src/components/Form/Select'
import NoResultsLogo from 'src/components/NoResultsLogo'
import { deleteCompanyConfig } from 'src/services/companies.services'

const headCells: any[] = [
  {
    idField: 'name',
    align: 'left',
    disablePadding: true,
    label: labels.es.name,
    order: true,
    fieldtype: 'name'
  },
  {
    idField: 'role',
    align: 'left',
    disablePadding: true,
    label: labels.es.role,
    order: true,
    fieldtype: 'name'
  }
]
export default function Roles(): JSX.Element {
  const { roles, allCompanies, fetchCompanyConfigData, loadingData } = useContext(InfoContext)
  const { userData } = useContext(AuthContext)

  const theme = useTheme()
  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  const [initHeader, setInitHeader] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [filter, setFilter] = useState<string>('')

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }

  const handleSearch = async (word: string) => {
    if (!word) {
      if (roles) setRowsData(roles)
    } else {
      if (roles) setRowsData(roles.filter((role: any) => role.name.toLowerCase().includes(word.toLowerCase())))
    }
  }

  useEffect(() => {
    if (roles && filter) fetchCompanyConfigData(filter)
    if (roles && !filter) setRowsData(null)
  }, [filter])

  const fetchRolesFiltered = () => {
    if (roles && filter) fetchCompanyConfigData(filter)
    if (roles && !filter) setRowsData(null)
  }

  const Header = () => {
    return (
      <Stack direction="row" justifyContent="space-between">
        <SearchInput placeholder={labels.es.searchRoles} name="search" handleSubmit={handleSearch} clean fullwidth />
        <AddButton onClick={() => setOpenModal(true)} text={labels.es.addPositions} />
      </Stack>
    )
  }

  useEffect(() => {
    if (roles) {
      setRowsData(roles)
      setInitHeader(true)
    }
  }, [roles])

  useEffect(() => {
    if (initHeader) showHeader(Header)
  }, [initHeader])

  const onDelete = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels.es.roles, theme.palette.primary.main)).then((result) => {
      if (result.isConfirmed) {
        deleteElements(data, deleteCompanyConfig)
          .then(() => {
            showSnackbar('success', labels.es.rolesDeleted)
            fetchCompanyConfigData(filter)
            closeModal()
            setSelected([])
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const onEdit = async (data: Record<string, any>) => {
    setEditData(data)
    setOpenModal(true)
  }

  const filterOptions = [{ id: '', name: labels.es.filterByCompany }, ...allCompanies].map((elm) => ({
    value: elm.id,
    label: elm.name
  }))

  const companySelected = filter ? allCompanies.find((elm) => elm.id === filter) : undefined

  return (
    <>
      <Table
        headCells={headCells}
        rows={rowsData}
        snackBar
        onDelete={onDelete}
        onEdit={onEdit}
        loading={loadingData}
        selected={selected}
        setSelected={setSelected}
        filters={
          <>
            {userData.role.id === 'role1' ? (
              <Box sx={{ ml: 2 }}>
                <SimpleSelect
                  minWidth={200}
                  name="filters"
                  data={filter}
                  handleChange={(e) => setFilter(e.target.value)}
                  options={filterOptions}
                />
              </Box>
            ) : (
              <></>
            )}
          </>
        }
      />
      {userData.role.id === 'role1' && (!rowsData || rowsData.length === 0) && (
        <Alert sx={{ backgroundColor: 'transparent', justifyContent: 'center' }} severity="warning">
          {filter ? 'No hay resultados disponibles' : 'Selecciona una compañia para ver resultados'}
        </Alert>
      )}
      {(!rowsData || rowsData.length === 0) && <NoResultsLogo />}
      <RolesAddEdit
        openModal={openModal}
        closeModal={closeModal}
        dataToEdit={editData}
        onDelete={onDelete}
        fetchData={fetchRolesFiltered}
        companyEdit={companySelected}
      />
    </>
  )
}
