import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from '../../../types/pages.types'
import { useForm } from 'src/hooks/useForm'
import { AlertContext } from 'src/context/alert/alertContext'
import { labels } from 'src/labels/main_labels'
import { createCompany, updateCompany } from 'src/services/companies.services'
import { validateEmail } from 'src/utils/validateForm'
import { InfoContext } from 'src/context/info/infoContext'

const INITIAL_VALUES = {
  name: '',
  address: '',
  email: '',
  phone: '',
  cif: ''
}

export default function CompaniesAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData }: AddEditProps): JSX.Element {
  const editing = !!dataToEdit
  const { showSnackbar } = useContext(AlertContext)
  const { fetchCompanies } = useContext(InfoContext)

  const { handleChange, values, resetForm } = useForm(INITIAL_VALUES, dataToEdit)
  const [loading, setLoading] = useState(false)
  const [errorEmail, setErrorEmail] = useState<string | undefined>(undefined)

  const resetComponent = () => {
    setLoading(false)
    resetForm()
    setErrorEmail(undefined)
  }

  useEffect(() => {
    return () => resetComponent()
  }, [dataToEdit])

  const onSave = async () => {
    setErrorEmail(undefined)
    if (!validateEmail(values.email)) {
      setErrorEmail(labels.es.errorEmailValidation)
    }
    setLoading(true)
    const newCompany = JSON.parse(JSON.stringify(values))

    try {
      if (!dataToEdit) {
        newCompany.createdAt = Date.now()
        newCompany.updatedAt = Date.now()

        await createCompany(newCompany)
        // if (idnewUser) history.push(`/restaurantes/${idnewUser}`)
        showSnackbar('success', labels.es.companyCreated)
      } else {
        newCompany.updatedAt = Date.now()

        await updateCompany(newCompany, dataToEdit.id)
        showSnackbar('success', labels.es.companyUpdated)
      }
      resetComponent()
      if (fetchData) fetchData()
      fetchCompanies()
      closeModal()
    } catch (error: any) {
      showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit.id)
  }

  return (
    <Modal
      title={editing ? dataToEdit.name : labels.es.addCompany}
      open={openModal}
      handleClose={closeModal}
      footer={
        <FooterForm
          text={labels.es.company}
          closeModal={closeModal}
          onSave={onSave}
          onDelete={onDelete ? handleDelete : undefined}
          editing={editing}
          loading={loading}
          disabled={!values.name || !values.email || !values.phone || !values.cif || !values.address}
        />
      }
    >
      <Box id="form-users">
        <TextInput
          required
          value={values.name}
          handleChange={(e) => handleChange(e)}
          name="name"
          text={labels.es.name}
          placeholder={labels.es.name}
        />
        <TextInput
          required
          value={values.address}
          handleChange={(e) => handleChange(e)}
          name="address"
          text={labels.es.address}
          placeholder={labels.es.address}
        />
        <TextInput
          required
          value={values.email}
          handleChange={(e) => handleChange(e)}
          name="email"
          text={labels.es.email}
          placeholder={labels.es.email}
          errorBadge={errorEmail}
          type="email"
        />
        <TextInput
          required
          value={values.phone}
          handleChange={(e) => handleChange(e)}
          name="phone"
          text={labels.es.phone}
          placeholder={labels.es.phone}
          type="phone"
        />

        <TextInput
          required
          value={values.cif}
          handleChange={(e) => handleChange(e)}
          name="cif"
          text={labels.es.cif}
          placeholder={labels.es.cif}
        />
      </Box>
    </Modal>
  )
}
