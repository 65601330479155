import React, { useState, useContext, useEffect, ChangeEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { FirebaseError } from '@firebase/util'
import CssBaseline from '@mui/material/CssBaseline'
import LoadingButton from '@mui/lab/LoadingButton'
import FormControlLabel from '@mui/material/FormControlLabel'

import { AuthContext } from 'src/context/auth/authContext'
import { Checkbox, Link, Grid, Box, FormHelperText } from '@mui/material'

import { Main, Input, WelcomeBox } from './welcome.styles'
import { ReactComponent as Logo } from 'src/assets/icons/general/Metaphysio.svg'
import { RecoverPasswordDialog, SimpleDialog } from 'src/components/Modals/Dialog'
import { recoverAccountPassword } from 'src/services/auth.services'
import { labels } from 'src/labels/main_labels'
import { validateEmail } from 'src/utils/validateForm'

export default function Welcome(): JSX.Element {
  const history = useHistory()
  const [error, setError] = useState<string | null>(null)
  const [openRecoverPass, setOpenRecoverPass] = useState<boolean>(false)
  const [openRecoverPassError, setOpenRecoverPassError] = useState<boolean>(false)
  const [persistence, setPersistence] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [loadingRecover, setLoadingRecover] = useState(false)
  const [recoverPasswordDialog, setRecoverPasswordDialog] = useState(false)
  const [values, setValues] = useState({ email: '', password: '' })

  const { firebase } = useContext(AuthContext)

  useEffect(() => {
    return () => setLoading(false)
  }, [])

  const loginWithOIDC = async () => {
    firebase.loginOIDC()
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setError(null)
    event.preventDefault()

    const data = new FormData(event.currentTarget)
    if (!data.get('email') || !data.get('password')) return
    if (!validateEmail(data.get('email') as string)) {
      setError(labels.es.emailError)
      return
    }
    setLoading(true)

    firebase
      .login(data.get('email'), data.get('password'), persistence)
      .then(() => history.push('/'))
      .catch(async (error: unknown) => {
        setLoading(false)
        if (error instanceof FirebaseError) {
          console.log(error.message)
          if (error.message.includes('EMAIL_NOT_FOUND')) {
            setError(labels.es.ERR_INVALID_EMAIL)
          } else if (error.message.includes('wrong-password')) {
            setError(labels.es.ERR_WRONG_PASS)
          } else if (error.message.includes('attempts')) {
            setError(labels.es.ERR_PASS_WRONG_TIMES)
          } else if (error.message.includes('requirements')) {
            try {
              setError(labels.es.ERR_PASS_REQUIREMENTS)
              // await recoverAccountPassword(data.get('email') as string)
            } catch (error) {
              console.log(error)
            }
          } else {
            setError(labels.es.ERR_NO_EMAIL)
          }
        }
      })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const recoverPassword = (email: string) => {
    setLoadingRecover(true)
    recoverAccountPassword(email)
      .then(() => {
        setRecoverPasswordDialog(false)
        setOpenRecoverPass(true)
        setLoadingRecover(false)
      })
      .catch(() => {
        setOpenRecoverPassError(true)
        setLoadingRecover(false)
      })
  }

  return (
    <Main>
      <CssBaseline />
      <WelcomeBox>
        <Logo width={300} height={300} />
        {/* <Typography sx={{ my: 3, textAlign: { xs: 'center' } }} component="h1" variant="h2">
          {labels.es.loginTitle}
        </Typography> */}
        <Box id="form-login" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, maxWidth: '100%', width: { md: 500 } }}>
          <Input
            sx={{ '& .MuiInputBase-input': { borderEndStartRadius: 0, borderEndEndRadius: 0 } }}
            placeholder={labels.es.email}
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => handleChange(e)}
          />
          <Input
            sx={{ '& .MuiInputBase-input': { borderTop: 0, borderStartStartRadius: 0, borderStartEndRadius: 0 } }}
            placeholder={labels.es.password}
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => handleChange(e)}
          />

          {error ? (
            <FormHelperText sx={{ mt: '5px' }} error id="form-login">
              {error}
            </FormHelperText>
          ) : (
            <Box sx={{ mt: 3 }} />
          )}
          <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item>
              <FormControlLabel
                control={<Checkbox value="remember" onChange={(e) => setPersistence(e.target.checked)} color="primary" />}
                label={labels.es.maintainSession}
              />
            </Grid>
            <Grid item>
              <Link sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => setRecoverPasswordDialog(true)} color="primary">
                {labels.es.forgotPass}
              </Link>
            </Grid>
          </Grid>
          <LoadingButton
            disabled={!values.email || !values.password}
            type="submit"
            loading={loading}
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
          >
            {labels.es.login}
          </LoadingButton>
          <LoadingButton
            disabled={values.email !== 'oidctest'}
            type="button"
            onClick={loginWithOIDC}
            loading={loading}
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
          >
            {'Iniciar sesión con PingId'}
          </LoadingButton>
          <SimpleDialog
            open={openRecoverPass}
            handleClose={() => setOpenRecoverPass(false)}
            title={labels.es.weSendEmail}
            text={labels.es.recoverYourPassword}
          />
          <SimpleDialog
            category="error"
            open={openRecoverPassError}
            handleClose={() => setOpenRecoverPassError(false)}
            title={labels.es.errorWhenSendingEmail}
            text={labels.es.errorWhenSendingEmail2}
          />
          <RecoverPasswordDialog
            open={recoverPasswordDialog}
            handleClose={() => setRecoverPasswordDialog(false)}
            handleAction={recoverPassword}
            loading={loadingRecover}
          />
        </Box>
      </WelcomeBox>
    </Main>
  )
}
