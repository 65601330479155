export interface LabelType {
  es: Record<string, string>
}

export const labels: LabelType = {
  es: {
    // STORES
    stores: 'Almacenes',
    bookings: 'Citas',
    booking: 'Cita',
    companies: 'Compañias',
    company: 'Compañia',
    store: 'Almacén',
    addStore: 'Añadir Almacén',
    companyDeleted: 'Almacén eliminado',
    companyCreated: 'Almacén creado',
    companyUpdated: 'Almacén actualizado',
    addCompany: 'Añadir Compañia',
    storeDeleted: 'Almacén eliminado',
    storeCreated: 'Almacén creado',
    storeUpdated: 'Almacén actualizado',
    mainCashier: 'Cajera principal',
    addBooking: 'Añadir Cita',
    editBooking: 'Modificar cita',
    addBookings: 'Añadir Citas',
    addBookingInterStores: 'Añadir cita entre almacenes',
    searchBookings: 'Buscar Citas',
    bookingDeleted: 'Cita eliminada',
    bookingCreated: 'Cita creada',
    bookingUpdated: 'Cita actualizada',
    reason: 'Motivo',
    medicalHistory: 'Historial médico',
    notes: 'Notas',
    patient: 'Paciente',
    cif: 'CIF',
    positionJob: 'Puesto',
    positionJobLow: 'puesto',
    positionJobs: 'Puestos',
    physios: 'Fisios',
    locations: 'Ubicaciones',
    storeName: 'Nombre de Almacén',
    storeUsers: 'Usuarios Almacén',
    searchStore: 'Buscar almacenes',
    role: 'Rol',
    lastname: '1º Apellido',
    lastname2: '2º Apellido',
    lastnames: 'Apellidos',
    userResponsable: 'Usuario Responsable',
    product: 'Producto',
    workWindow: 'Turno',
    when: '¿Cuando ocurrió?',
    where: '¿Donde ocurrió?',
    category: 'Categoria',
    signed: 'Conforme',
    sinthomesRelated: 'Zona afectada',
    whoParticipatedAnalyse: 'Participan en el analisis',
    testigos: 'Testigos',
    comunPatology: 'Patología Común',
    bookingReason: 'Motivo de Cita',
    campaignId: 'Id Campaña',
    campaignName: 'Nombre Campaña',
    bookingDetail: 'Detalle cita',
    createdBy: 'Agendado por',
    userDetail: 'Detalle Usuario',
    incidentDetail: 'Detalle Incidencia',
    campaignDetail: 'Detalle Campaña',
    incidentId: 'Id Incidente',
    bookingId: 'Id Cita',
    sign: 'Firmar',
    daysSinceDayOff: 'Dias desde última libranza',
    injuredWorker: 'Trabajador lesionado',
    availabilityUpdated: 'Disponibilidad actualizada',
    filterByRole: 'Filtrar por puesto',
    filterByCompany: 'Filtrar por compañia',
    filterByStore: 'Filtrar por almacén',
    // OLDER
    login: 'Iniciar Sesión',
    loginTitle: 'Acceso Metaphysio',
    password: 'Contraseña',
    passwordChanged: 'La contraseña ha sido actualizada correctamente',
    passwordChangingError: 'Ha ocurrido un problema al intentar actualizar la contraseña, por favor intentelo mas tarde.',
    incorrectPassword: 'La contraseña actual introducida es incorrecta',
    newPassword: 'Nueva contraseña',
    repeatNewPassword: 'Repite tu nueva contraseña',
    maintainSession: 'Mantener sesión iniciada',
    forgotPass: 'He olvidado mi contraseña',
    newEmail: 'Nuevo email',
    changeEmail: 'Cambiar email',
    emailInUse: 'Este email ya esta en uso',
    weSendEmail: 'Te hemos enviado un email',
    weSendEmail2: 'Hemos enviado un enlace a tu nuevo email para verificar tu cuenta.',
    recoverYourPassword:
      'Consulta tu bandeja de entrada para restablecer tu contraseña. Es posible que tengas que comprobar la carpeta de correo no deseado.',
    errorWhenSendingEmail1: 'Error en el envío de email',
    errorWhenSendingEmail2: 'Ha ocurrido un error al enviar el email de restaurar contraseña, por favor intentelo de nuevo mas tarde.',
    search: 'Buscar',
    area: 'Area',
    update: 'Actualizar',
    edit: 'Modificar',
    add: 'Añadir',
    accept: 'Aceptar',
    downloadQr: 'Descargar QR',
    delete: 'Eliminar',
    cancel: 'Cancelar',
    cancelled: 'Cancelado',
    name: 'Nombre',
    email: 'Email',
    nameSurname: 'Nombre y apellidos',
    information: 'Información',
    payments: 'Pagos',
    state: 'Estado',
    address: 'Dirección',
    foodTypes: 'Tipos de cocina',
    phone: 'Teléfono',
    message: 'Mensaje',
    age: 'Edad',
    description: 'Descripción',
    contactPerson: 'Persona de contacto',
    beneficiaryName: 'Nombre del beneficiario',
    optional: 'Opcional',
    nationalId: 'NIF/CIF',
    quantity: 'Cantidad',
    quality: 'Calidad',
    likes: 'Favoritos',
    summary: 'Resumen',
    general: 'General',
    city: 'Ciudad',
    registerDate: 'Fecha de registro',
    contact: 'Contacto',
    export: 'Exportar',
    deselectAll: 'Deseleccionar todos',
    selectAll: 'Seleccionar todos',
    selected: 'Seleccionado',
    selecteds: 'Seleccionados',
    deactivated: 'Desactivado',
    deactivate: 'Desactivar',
    deactivateAll: 'Modificar estado',
    activate: 'Activar',
    activated: 'Activado',
    value: 'Valor',
    history: 'Historial',
    hour: 'hora',
    minutes: 'minutos',
    min: 'min',
    ayuda: 'Help',
    faq: 'Preguntas frecuentes',
    horarios: 'Horarios',
    helpCenter: 'Centro de ayuda',
    someIncorrectData: '¿Algún dato incorrecto?',
    instagram: 'Instagram',
    deleted: 'Eliminado',
    recoverPassword: 'Recuperar contraseña',
    recoverPasswordText: 'Te enviaremos un correo electrónico para que actualices tu contraseña',
    emailError: 'Introduce un correo electrónico correcto',
    notifications: 'Notificaciones',
    settings: 'Ajustes',
    logout: 'Cerrar sesión',
    logoutText: 'Estás a punto de cerrar la sesión, ¿estás seguro?',
    next: 'Siguiente',
    return: 'Volver',
    save: 'Guardar',
    import: 'Importar',
    statusChanged: 'El estado ha sido cambiado',
    status: 'Estado',
    active: 'Activo',
    disabled: 'Inactivo',
    location: 'Localidad',
    only: 'Solo',
    noResults: 'No hay resultados',
    moreThanThreeCharacters: 'Empieza a escribir para ver resultados',
    continue: 'Continuar',
    percent: 'Porcentaje',
    csvImport: 'Importar CSV',
    importUsers: 'Importar usuarios',
    waiting: 'Esperando',
    changePassword: 'Cambiar contraseña',
    successDialogUpdatePassword: 'Contraseña cambiada con éxito',
    newOrdersCancelationsEmails: 'Emails sobre nuevos pedidos y cancelaciones',
    offersEmail: 'Emails con ofertas especiales y promociones',
    selectTime: 'Seleccionar hora',
    todayPlate: 'Plato de Hoy',
    date: 'Fecha',
    exportData: 'Exportar Datos ↓',
    yourRestaurant: 'Tu restaurante',
    management: 'Gestión',
    expirationDate: 'Fecha de vencimiento',
    byDefault: '↪ Por defecto:',
    loadMore: 'Cargar más',
    noFilters: 'Sin filtros',
    complete: 'Firmar',
    completeBooking: 'Completar cita con firma paciente',
    askBooking: 'Solicitar Cita',
    timezone: 'Franja horaria',
    bookingCompleted: 'Asistencia confirmada',
    bookingCompletedError: 'Ha ocurrido un error al cerrar la cita',
    signedError: 'Ha ocurrido un error al guardar la firma',

    completed: 'Asiste',
    noAssist: 'No asiste',
    assist: 'Asistencia',
    pending: 'Pendiente',
    close: 'Cierre',
    closeReason: 'Motivo cierre de incidencia',
    closeDate: 'Fecha de cierre',
    incidentCloseOk: 'Investigación cerrada correctamente',
    sessions: 'Sesiones',

    // HELP
    help: 'Ayuda',
    helpEmail: 'Si necesitas asistencia, ponte en contacto con nosotros en ',
    helpInstagram: 'Síguenos en redes sociales y mantente al día de las novedades de Metaphysio.',
    helpPhone: 'Si necesitas asistencia, ponte en contacto directo con nosotros en el ',
    termsConditions: 'Aviso Legal',
    privacyPolicy: 'Política de privacidad',
    cookiesPolicy: 'Política de cookies',
    contactMetaphysio: 'Contactar con Metaphysio',
    contactMetaphysio2: 'Contacta con Metaphysio',
    metaphysioPolitic: 'Política de Metaphysio',
    query: 'Consulta',
    questionSended: 'Consulta enviada',
    send: 'Enviar',

    // ERRORS
    hasBeenAnError: 'Hubo un error, por favor prueba de nuevo más tarde',
    changeCompleted: 'Cambio completado con éxito',
    // USERS
    users: 'Usuarios',
    user: 'usuario',
    addUser: 'Añadir usuario',
    searchUser: ' Buscar usuario',
    userName: 'Nombre de usuario',
    userDeleted: 'El usuario ha sido borrado',
    userCreated: 'El usuario ha sido creado',
    userUpdated: 'El usuario ha sido actualizado',
    emailSent: 'Email enviado correctamente',
    emailSentDialogText1: 'Se ha enviado un email a ',
    emailSentDialogText2: 'con las instrucciones para validar su cuenta de usuario.',
    errorDialogUser: 'El email introducido ya está en uso. Contacta con el usuario para utilizar otro diferente.',
    preUser: 'Pre-Usuario',
    birthday: 'Fecha nacimiento',
    gender: 'Sexo',
    sector: 'Sector',
    position: 'Position',
    employeeId: 'Nº de Empleado(Ldap)',
    genderEditing: 'Elige el sexo del usuario',
    offerPromotion: 'Emails con ofertas y promociones',
    pickupReminder: 'Recordatorio de recogida',
    orderTimer: 'Hora de hacer pedidos',
    personalData: 'Datos Personales',
    workingSince: 'Fecha de antigüedad',
    comercialManager: 'Responsable comercial',
    cashier: 'Cajero/a',
    cashManager: 'Responsable de cajas',
    logisticManager: 'Responsable de logística',
    seller: 'Vendedor/a',
    whatHappened: '¿Qué ha pasado?',
    why: '¿Por qué?',
    how: '¿Como?',
    affectedZone: 'Zona Afectada',
    professionalChannel: 'Canal profesional',
    paymentMethods: 'Formas de pago',
    Man: 'Hombre',
    Woman: 'Mujer',
    file: 'Archivo',
    importSuccess: 'Importación realizada',
    importUsersTitle: 'Usuarios añadidos correctamente',
    importUsersText: 'Hemos enviado un email a los usuarios con las instrucciones para validar sus cuentas.',
    importUsersError: 'Uy, algo no funcionó',
    importUsersErrorText1: 'Los siguientes emails ya están en uso:',
    importUsersErrorText2: 'Contacta con estos usuarios para utilizar emails diferentes.',
    importUsersPrevalidation: 'Los campos "user", "surname" y "email" son obligatorios en todas las filas.',
    importFileSuccess: 'Archivo de importación correcto',
    confirmWithSign: 'Confirmar asistencia con firma',
    confirmWithSignIncident: 'Firmar parte de investigación',
    incidentSignedOk: 'Incidente firmado correctamente',
    // WEEKDAYS
    today: 'Hoy',
    tomorrow: 'Mañana',
    Monday: 'Lunes',
    Tuesday: 'Martes',
    Wednesday: 'Miércoles',
    Thursday: 'Jueves',
    Friday: 'Viernes',
    Saturday: 'Sábado',
    Sunday: 'Domingo',
    // LOGIN ERRORS
    ERR_NO_EMAIL: 'El email introducido no pertenece a ninguna cuenta. Prueba con otro.',
    ERR_INVALID_EMAIL: 'El email introducido no es válido',
    ERR_WRONG_PASS: 'Contraseña incorrecta. Prueba de nuevo.',
    ERR_PASS_WRONG_TIMES: 'Contraseña incorrecta demasiadas veces. Prueba de nuevo en 5 minutos o pulsa "He olvidado mi contraseña',
    // ERR_PASS_REQUIREMENTS: 'La contraseña no cumple los requisitos requeridos, te hemos enviado un email para que puedas resetearla',
    ERR_PASS_REQUIREMENTS:
      'La contraseña no cumple los requisitos requeridos, pulsa en He olvidado mi contraseña para solicitar un correo para cambiarla.',
    // 404
    returnToList: 'Por favor, vuelve al listado y elige otro.',
    notFound: 'No hemos encontrado nada',
    // ERRORBOUNDARY
    boundaryTitle: 'Lo sentimos :( ha habido un error',
    errorEmailValidation: 'Debería ser un email válido',
    errorEmployeeIdValidation: 'El número de empleado esta repetido',
    // NAVIGATION
    machines: 'Máquinas',
    processes: 'Procesos',
    statitics: 'Estadísticas',
    calendars: 'Calendarios',
    calendar: 'Calendario',
    sectors: 'Sectores',
    areas: 'Areas',
    // companies: 'Compañias',
    pathologies: 'Patologías',
    incidents: 'Incidentes',
    incident: 'Incidente',
    issues: 'Incidencias',
    searchIssues: 'Buscar incidencias',
    campaigns: 'Campañas',
    rrhh: 'RRHH',
    admin: 'Admin',
    utils: 'Utilidades',
    searchCompany: 'Buscar compañia',
    // Pathologies
    pathologiesDeleted: 'Patología borrada',
    searchPathologies: 'Buscar patología',
    addPathologies: 'Añadir patología',
    pathologyCreated: 'Patología creada',
    pathologyUpdated: 'Patología actualizada',
    pathology: 'Patología',
    // Machines
    machinesDeleted: 'Máquina borrada',
    searchMachines: 'Buscar máquina',
    addMachines: 'Añadir máquina',
    machinesCreated: 'Máquina creada',
    machinesUpdated: 'Máquina actualizada',
    machine: 'Máquina',
    // Incident
    incidentDeleted: 'Incidente borrada',
    searchIncident: 'Buscar incidente',
    addIncident: 'Añadir incidente',
    editIncident: 'Editar incidente',
    incidentCreated: 'Incidente creado',
    incidentUpdated: 'Incidente actualizado',
    incidentResearch: 'Añadir Investigación de incidente',
    open: 'Abierta',
    alta: 'Alta',
    noResolution: 'Sin resolución',
    full: 'Máximo de sesiones',
    statusIncident: 'Estado Investigación',
    // Sectors
    sectorsDeleted: 'Sector borrado',
    rolesDeleted: 'Puesto borrado',
    searchSectors: 'Buscar sector',
    addSectors: 'Añadir sector',
    sectorsCreated: 'Sector creado',
    sectorsUpdated: 'Sector actualizado',
    // Areas
    areasDeleted: 'Area borrada',
    searchAreas: 'Buscar area',
    addAreas: 'Añadir area',
    areasCreated: 'Area creada',
    areasUpdated: 'Area actualizada',
    // Processes
    processesDeleted: 'Proceso borrado',
    searchProcesses: 'Buscar proceso',
    addProcesses: 'Añadir proceso',
    processesCreated: 'Proceso creado',
    processesUpdated: 'Proceso actualizado',
    process: 'Proceso',
    // Campaigns
    campaignsDeleted: 'Campaña borrada',
    searchCampaigns: 'Buscar campaña',
    addCampaigns: 'Añadir campaña',
    editCampaign: 'Editar campaña',
    campaignsCreated: 'Campaña creada',
    campaignsUpdated: 'Campaña actualizada',
    incorcamp: 'Incidencia/Campaña/PC',
    campaign: 'Campaña',
    // ROLES
    roles: 'Roles',
    addRoles: 'Añadir roles',
    addPositions: 'Añadir puestos',
    rolesUpdated: 'Rol actualizado',
    rolesCreated: 'Rol creado',
    hr: 'RRHH',
    physio: 'Fisioterapeuta',
    worker: 'Colaborador',
    storeManager: 'Jefe de almacén',
    director: 'role4',
    departmentManager: 'Jefe de sector',
    January: 'Enero',
    February: 'Febrero',
    March: 'Marzo',
    April: 'Abril',
    May: 'Mayo',
    June: 'Junio',
    July: 'Julio',
    August: 'Agosto',
    September: 'Septiembre',
    October: 'Octubre',
    November: 'Noviembre',
    December: 'Diciembre'
  }
}
