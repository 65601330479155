import React, { useContext, useEffect, useState } from 'react'
import { Box, useTheme, IconButton, TextField } from '@mui/material'
import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { labels } from 'src/labels/main_labels'
import { useHistory } from 'react-router-dom'
import SimpleSelect from 'src/components/Form/Select'
import { InfoContext } from 'src/context/info/infoContext'
import { MobileDatePicker, LocalizationProvider } from '@mui/lab'
import { es } from 'date-fns/locale'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import TablePag from 'src/components/Table/TablePag'
import { Clear } from '@mui/icons-material'


const headCells: any[] = [
  {
    idField: 'date',
    align: 'left',
    disablePadding: true,
    label: labels.es.date,
    order: true,
    fieldtype: 'date-only'
  },
  {
    idField: 'hourFormatted',
    align: 'left',
    disablePadding: false,
    label: labels.es.hour,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'patient',
    align: 'left',
    disablePadding: true,
    label: labels.es.worker,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'store',
    align: 'left',
    disablePadding: true,
    label: labels.es.store,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'issueType',
    align: 'left',
    disablePadding: true,
    label: 'Tipo',
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'cancelationReason',
    align: 'left',
    disablePadding: true,
    label: 'Motivo',
    order: true,
    fieldtype: 'typeIssue'
  },
  {
    idField: 'CAM/INC/PC',
    align: 'left',
    disablePadding: true,
    label: 'CAM/INC/PC',
    order: true,
    fieldtype: 'typeBooking'
  }
]


const today = new Date().toLocaleDateString('fr-CA')

export default function Issues(): JSX.Element {
  const { userData, user, firebase } = useContext(AuthContext)
  const history = useHistory()
  const theme = useTheme()
  const { showSnackbar } = useContext(AlertContext)
  const { allCompanies, allStores } = useContext(InfoContext)
  const [data, setData] = useState<any | null>([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [filterCompany, setFilterCompany] = useState<string>('')
  const [filterStore, setFilterStore] = useState<string>('')
  const [filterStoreOptions, setFilterStoreOptions] = useState<any[] | never>([])
  const [filterDate, setFilterDate] = useState<string | null>(today)

  // PAGINATION

  const [page, setPage] = useState(0)

  interface PageData {
    [key: number]: any[]
  }

  interface State {
    totalCount: number
    lastVisible: any
    pagesData: PageData
  }

  const [state, setState] = useState<State>({
    totalCount: 0,
    lastVisible: null,
    pagesData: {}
  })



  const fetchData = async (pag?: number) => {

    if (loadingTable) return
    
    
   
    const pagina = pag || page
    // if (!initHeader && !searchString) setInitHeader(true)
    setLoadingTable(true)
    try {
     
  
      
      if (!state.pagesData[pagina]) {
      


      
        let query = firebase.db.collection('issues').orderBy('date','desc').orderBy('hourFormatted','asc').limit(25)
        query = query.where('deleted', '==', false)

        // if (searchString !== '') {
        //   const searchCoded = searchString.charAt(0).toUpperCase() + searchString.slice(1)
        //   query = query.where('patient.name', '>=', searchCoded)
        // }
        if (filterCompany) {
          query = query.where('companyId', '==', `${filterCompany}`)
        }
        if (filterStore) {
          query = query.where('storeId', '==', `${filterStore}`)
        }

        if (filterDate) {

          query = query.where('date', '==', `${filterDate}`)
        }
        if (userData.role.id === 'role3' || userData.role.id === '') {
          query = query.where('companyId', '==', `${filterCompany}`)
        }

        if(userData.role.id === 'role2'){
        query = query.where('storeId', 'in', userData.storesWorking.map((store: any) => store.id))
        }
      
        if (['role4', 'role5'].includes(userData.role.id)) {
          query = query.where('storeId', '==', `${userData?.storeId}`)
          query = query.where('companyId', '==', `${userData?.companyId}`)
        }

        if (['role4', 'role5', 'role6'].includes(userData.role.id)) {
          query = query.where('storeId', '==', `${userData?.storeId}`)
          query = query.where('companyId', '==', `${userData?.companyId}`)
        }

        if (state.lastVisible) {
          query = query.startAfter(state.lastVisible)
        }
      
        const querySnapshot = await query.get()
        const newPagesData = { ...state.pagesData }
        
        newPagesData[pagina] = querySnapshot.docs.map((doc: any) => doc.data())
        

        setData(data.concat(querySnapshot.docs.map((doc: any) => {  return { id: doc.id, ...doc.data() }})))
        setState({
          ...state,
          lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
          pagesData: newPagesData,
          totalCount: querySnapshot.size
        })
      } 
    } catch (error) {
      console.log(error)
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }

  const refreshSearch = async () => {
    if (filterCompany || filterStore || filterDate ||filterDate === null) {
    
      setData([])
      setPage(0)
      setState({ totalCount: 0, lastVisible: null, pagesData: {} })
    } 
   
  }

  useEffect(() => {
  
    if(!Object.keys(state.pagesData).length) fetchData(0)
   
  }, [state.pagesData])

  useEffect(() => {
    refreshSearch()
  }, [ filterCompany, filterStore,filterDate])

  useEffect(() => {
    if (userData && userData.role.id) fetchData(page)
    if (userData.role.id === 'role3' && userData.companyId) setFilterCompany(userData.companyId)
  }, [page])

  const onRowClick = (row: Record<string, any>) => {
    history.push(`/incidencias/${row.issueId}`)
  }

  const filterCompanyOptions = [{ id: '', name: labels.es.filterByCompany }, ...allCompanies].map((elm) => ({
    value: elm.id,
    label: elm.name
  }))

  useEffect(() => {
    const loadfilterStoreOptions = allStores
      .filter((elm) => elm.companyId === filterCompany)
      .map((elm) => ({
        value: elm.id,
        label: elm.storeName
      }))

    setFilterStoreOptions([{ value: '', label: labels.es.filterByStore }, ...loadfilterStoreOptions])
  }, [filterCompany])

  function handleClr(e:any) {
    e.stopPropagation();
    setFilterDate(null);
  } 

  return (
    <>
      <TablePag
        noCheckbox
        noActions
        headCells={headCells}
        rows={data}
        snackBar
        loading={loadingTable}
        selected={selected}
        setSelected={setSelected}
        onRowClick={onRowClick}
        page={page}
        setPage={setPage}
        filters={
          userData.role.id === 'role1' ? (
            <>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  '> div': { ml: 2, my: theme.breakpoints.down('sm') ? 0.5 : 0 },
                  [theme.breakpoints.down('sm')]: {
                    overflowX: 'scroll'
                  }
                }}
              >
                <SimpleSelect
                  minWidth={200}
                  name="filterCompany"
                  data={filterCompany}
                  handleChange={(e) => setFilterCompany(e.target.value)}
                  options={filterCompanyOptions}
                />
                {filterStoreOptions && filterCompany && (
                  <SimpleSelect
                    minWidth={200}
                    name="filterStore"
                    data={filterStore}
                    handleChange={(e) => setFilterStore(e.target.value)}
                    options={filterStoreOptions}
                  />
                )}
                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    toolbarTitle="Selecciona una fecha"
                    cancelText="Cancelar"
                    value={filterDate}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={(e) => handleClr(e)}>
                          <Clear />
                        </IconButton>
                      )
                    }}
                    onChange={(input) => {
                      if (input) {
                        setFilterDate((input as unknown as Date).toLocaleDateString('fr-CA'))
                      }
                    }}
                    
                    renderInput={(params) => {
                      const openClick: () => void = (params as any).InputProps?.endAdornment?._owner?.pendingProps?.openPicker
                      return (
                        <TextField
                          sx={{
                            width: 130,
                            alignItems: 'center',
                            '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                            '.MuiInputBase-root:after': { borderBottom: 'none !important' },
                            input: { fontFamily: 'Inter', fontSize: 14 },
                            label: { fontFamily: 'Inter', fontSize: 14 }
                          }}
                          variant="standard"
                          {...params}
                          onClick={openClick}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Elige una fecha"
                          }}
                        />
                      )
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </>
          ) : userData.role.id === 'role2' ? (
            <>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  '> div': { ml: 2, my: theme.breakpoints.down('sm') ? 0.5 : 0 },
                  [theme.breakpoints.down('sm')]: {
                    overflowX: 'scroll'
                  }
                }}
              >
                <SimpleSelect
                  minWidth={200}
                  name="filterStore"
                  data={filterStore}
                  handleChange={(e) => setFilterStore(e.target.value)}
                  options={[{ id: '', name: labels.es.filterByStore }, ...userData.storesWorking].map((elm: any) => ({
                    value: elm.id,
                    label: elm.name
                  }))}
                />
                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    toolbarTitle="Selecciona una fecha"
                    cancelText="Cancelar"
                    value={filterDate}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={(e) => handleClr(e)}>
                          <Clear />
                        </IconButton>
                      )
                    }}
                    onChange={(input) => {
                      if (input) {
                        setFilterDate((input as unknown as Date).toLocaleDateString('fr-CA'))
                      }
                    }}
                    
                    renderInput={(params) => {
                      const openClick: () => void = (params as any).InputProps?.endAdornment?._owner?.pendingProps?.openPicker
                      return (
                        <TextField
                          sx={{
                            width: 130,
                            alignItems: 'center',
                            '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                            '.MuiInputBase-root:after': { borderBottom: 'none !important' },
                            input: { fontFamily: 'Inter', fontSize: 14 },
                            label: { fontFamily: 'Inter', fontSize: 14 }
                          }}
                          variant="standard"
                          {...params}
                          onClick={openClick}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Elige una fecha"
                          }}
                        />
                      )
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </>
          ) : userData.role.id === 'role3' ? (
            <>
              {filterStoreOptions && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    '> div': { ml: 2, my: theme.breakpoints.down('sm') ? 0.5 : 0 },
                    [theme.breakpoints.down('sm')]: {
                      overflowX: 'scroll'
                    }
                  }}
                >
                  <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    toolbarTitle="Selecciona una fecha"
                    cancelText="Cancelar"
                    value={filterDate}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={(e) => handleClr(e)}>
                          <Clear />
                        </IconButton>
                      )
                    }}
                    onChange={(input) => {
                      if (input) {
                        setFilterDate((input as unknown as Date).toLocaleDateString('fr-CA'))
                      }
                    }}
                    
                    renderInput={(params) => {
                      const openClick: () => void = (params as any).InputProps?.endAdornment?._owner?.pendingProps?.openPicker
                      return (
                        <TextField
                          sx={{
                            width: 130,
                            alignItems: 'center',
                            '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                            '.MuiInputBase-root:after': { borderBottom: 'none !important' },
                            input: { fontFamily: 'Inter', fontSize: 14 },
                            label: { fontFamily: 'Inter', fontSize: 14 }
                          }}
                          variant="standard"
                          {...params}
                          onClick={openClick}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Elige una fecha"
                          }}
                        />
                      )
                    }}
                  />
                  </LocalizationProvider>
                  <SimpleSelect
                    minWidth={200}
                    name="filterStore"
                    data={filterStore}
                    handleChange={(e) => setFilterStore(e.target.value)}
                    options={filterStoreOptions}
                  />
                </Box>
              )}
            </>
          ) : (
            <>
              {' '}
              <Box sx={{ ml: 2 }}>
                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    toolbarTitle="Selecciona una fecha"
                    cancelText="Cancelar"
                    value={filterDate}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={(e) => handleClr(e)}>
                          <Clear />
                        </IconButton>
                      )
                    }}
                    onChange={(input) => {
                      if (input) {
                        setFilterDate((input as unknown as Date).toLocaleDateString('fr-CA'))
                      }
                    }}
                    
                    renderInput={(params) => {
                      const openClick: () => void = (params as any).InputProps?.endAdornment?._owner?.pendingProps?.openPicker
                      return (
                        <TextField
                          sx={{
                            width: 130,
                            alignItems: 'center',
                            '.MuiInputBase-root:before ': { borderBottom: 'none !important' },
                            '.MuiInputBase-root:after': { borderBottom: 'none !important' },
                            input: { fontFamily: 'Inter', fontSize: 14 },
                            label: { fontFamily: 'Inter', fontSize: 14 }
                          }}
                          variant="standard"
                          {...params}
                          onClick={openClick}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Elige una fecha"
                          }}
                        />
                      )
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </>
          )
        }
      />
     
     
    
  
    </>
  )
}
