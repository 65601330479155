import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Box, Stack, useTheme } from '@mui/material'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import SearchInput from 'src/components/SearchInput'
import StoresAddEdit from './StoresAddEdit'
import Table from 'src/components/Table/Table'
import deleteElements from 'src/services/deleteElements'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { labels } from 'src/labels/main_labels'
import { getAllStores, deleteStore } from 'src/services/stores.services'
import { AddButton } from 'src/components/Buttons/AddButton'
import { InfoContext } from 'src/context/info/infoContext'
import SimpleSelect from 'src/components/Form/Select'

const headCells: any[] = [
  {
    idField: 'storeName',
    align: 'left',
    disablePadding: true,
    label: labels.es.storeName,
    order: true,
    fieldtype: 'name'
  },
  {
    idField: 'address',
    align: 'left',
    disablePadding: true,
    label: labels.es.address,
    // order: true,
    fieldtype: 'string'
  },
  {
    idField: 'email',
    align: 'center',
    disablePadding: false,
    label: labels.es.email,
    fieldtype: 'string'
  },
  {
    idField: 'phone',
    align: 'center',
    disablePadding: false,
    label: labels.es.phone,
    fieldtype: 'string'
  },
  {
    idField: 'createdAt',
    align: 'center',
    disablePadding: true,
    label: labels.es.registerDate,
    order: true,
    fieldtype: 'date-time'
  }
]
export default function Stores(): JSX.Element {
  const { userData } = useContext(AuthContext)
  const { allCompanies } = useContext(InfoContext)
  const theme = useTheme()
  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const [data, setData] = useState<any | null>(null)
  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  const [initHeader, setInitHeader] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [filter, setFilter] = useState<string>('')

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }

  const fetchStoresList = async () => {
    setLoadingTable(true)
    try {
      const resp = await getAllStores()
      const data = resp.filter((row: any) => row.deleted !== true)
      setData(data)

      setRowsData(data)
      if (!initHeader) setInitHeader(true)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }

  const handleSearch = async (word: string) => {
    if (!word) {
      if (data) setRowsData(data)
    } else {
      if (data) {
        const filteredData = data.filter((store: any) => store.storeName.toLowerCase().includes(word.toLowerCase()))

        if (filteredData) setRowsData(filteredData)
      }
    }
  }

  useEffect(() => {
    if (data && filter) {
      setRowsData(data.filter((store: any) => store.companyId === filter))
    }
    if (data && !filter) setRowsData(data)
  }, [filter])

  const Header = () => {
    return (
      <Stack direction="row" justifyContent="space-between">
        <SearchInput placeholder={labels.es.searchStore} name="search" handleSubmit={handleSearch} clean fullwidth />
        <AddButton onClick={() => setOpenModal(true)} text={labels.es.addStore} />
      </Stack>
    )
  }

  useEffect(() => {
    if (userData) fetchStoresList()
  }, [])

  useEffect(() => {
    if (initHeader) showHeader(Header)
  }, [initHeader])

  const onDelete = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels.es.stores, theme.palette.primary.main)).then((result) => {
      if (result.isConfirmed) {
        deleteElements(data, deleteStore)
          .then(() => {
            showSnackbar('success', labels.es.storeDeleted)
            fetchStoresList()
            closeModal()
            setSelected([])
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const onEdit = async (data: Record<string, any>) => {
    // const resp = await getCompanyById(data.id)
    setEditData(data)
    setOpenModal(true)
  }

  const filterOptions = [{ id: '', name: labels.es.filterByCompany }, ...allCompanies].map((elm) => ({
    value: elm.id,
    label: elm.name
  }))

  return (
    <>
      <Table
        headCells={headCells}
        rows={rowsData}
        snackBar
        onDelete={onDelete}
        onEdit={onEdit}
        loading={loadingTable}
        selected={selected}
        setSelected={setSelected}
        initialOrderBy="storeName"
        filters={
          userData.role.id === 'role1' ? (
            <Box sx={{ ml: 2 }}>
              <SimpleSelect
                minWidth={200}
                name="filters"
                data={filter}
                handleChange={(e) => setFilter(e.target.value)}
                options={filterOptions}
              />
            </Box>
          ) : undefined
        }
      />
      <StoresAddEdit openModal={openModal} closeModal={closeModal} dataToEdit={editData} onDelete={onDelete} fetchData={fetchStoresList} />
    </>
  )
}
