/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { IconButton, Grid, Card, Alert } from '@mui/material'
import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { CalendarButton, CalendarButtonNoMargin, CalendarGrid, CalendarPopover, CalendarText } from './Calendar.styles'
import { labels } from 'src/labels/main_labels'


export default function CalendarTemplate({ availability, setAvailability, startTime = '7:00', endTime = '22:00', saving = false }) {
 

  const useMonths = (year) => ({
    1: {
      lastDay: 31,
      month: 'January',
      firstDay: moment(`01/01/${year}`)
    },
    2: {
      lastDay: year % 4 === 0 ? 29 : 28,
      month: 'February',
      firstDay: moment(`02/01/${year}`)
    },
    3: {
      lastDay: 31,
      month: 'March',
      firstDay: moment(`03/01/${year}`)
    },
    4: {
      lastDay: 30,
      month: 'April',
      firstDay: moment(`04/01/${year}`)
    },
    5: {
      lastDay: 31,
      month: 'May',
      firstDay: moment(`05/01/${year}`)
    },
    6: {
      lastDay: 30,
      month: 'June',
      firstDay: moment(`06/01/${year}`)
    },
    7: {
      lastDay: 31,
      month: 'July',
      firstDay: moment(`07/01/${year}`)
    },
    8: {
      lastDay: 31,
      month: 'August',
      firstDay: moment(`08/01/${year}`)
    },
    9: {
      lastDay: 30,
      month: 'September',
      firstDay: moment(`09/01/${year}`)
    },
    10: {
      lastDay: 31,
      month: 'October',
      firstDay: moment(`10/01/${year}`)
    },
    11: {
      lastDay: 30,
      month: 'November',
      firstDay: moment(`11/01/${year}`)
    },
    12: {
      lastDay: 31,
      month: 'December',
      firstDay: moment(`12/01/${year}`)
    }
  })

  const getDefaultTimes = () => {
    const times = [
      {
        time: '0:00',
        available: false
      },
      {
        time: '0:30',
        available: false
      },
      {
        time: '1:00',
        available: false
      },
      {
        time: '1:30',
        available: false
      },
      {
        time: '2:00',
        available: false
      },
      {
        time: '2:30',
        available: false
      },
      {
        time: '3:00',
        available: false
      },
      {
        time: '3:30',
        available: false
      },
      {
        time: '4:00',
        available: false
      },
      {
        time: '4:30',
        available: false
      },
      {
        time: '5:00',
        available: false
      },
      {
        time: '5:30',
        available: false
      },
      {
        time: '6:00',
        available: false
      },
      {
        time: '6:30',
        available: false
      },
      {
        time: '7:00',
        available: false
      },
      {
        time: '7:30',
        available: false
      },
      {
        time: '8:00',
        available: false
      },
      {
        time: '8:30',
        available: false
      },
      {
        time: '9:00',
        available: false
      },
      {
        time: '9:30',
        available: false
      },
      {
        time: '10:00',
        available: false
      },
      {
        time: '10:30',
        available: false
      },
      {
        time: '11:00',
        available: false
      },
      {
        time: '11:30',
        available: false
      },
      {
        time: '12:00',
        available: false
      },
      {
        time: '12:30',
        available: false
      },
      {
        time: '13:00',
        available: false
      },
      {
        time: '13:30',
        available: false
      },
      {
        time: '14:00',
        available: false
      },
      {
        time: '14:30',
        available: false
      },
      {
        time: '15:00',
        available: false
      },
      {
        time: '15:30',
        available: false
      },
      {
        time: '16:00',
        available: false
      },
      {
        time: '16:30',
        available: false
      },
      {
        time: '17:00',
        available: false
      },
      {
        time: '17:30',
        available: false
      },
      {
        time: '18:00',
        available: false
      },
      {
        time: '18:30',
        available: false
      },
      {
        time: '19:00',
        available: false
      },
      {
        time: '19:30',
        available: false
      },
      {
        time: '20:00',
        available: false
      },
      {
        time: '20:30',
        available: false
      },
      {
        time: '21:00',
        available: false
      },
      {
        time: '21:30',
        available: false
      },
      {
        time: '22:00',
        available: false
      },
      {
        time: '22:30',
        available: false
      },
      {
        time: '23:00',
        available: false
      },
      {
        time: '23:30',
        available: false
      },
      {
        time: '0:00',
        available: false
      }
    ]
    let include = false
    return times.filter((time) => {
      if (time.time === startTime) {
        include = true
      }
      if (time.time === endTime) {
        include = false
        return true
      }
      return include
    })
  }

  function TimeButton({ start, end, available, handleClick, disabled }) {
    return (
      <CalendarButton onClick={handleClick} variant={available ? 'contained' : 'outlined'} disabled={disabled}>
        {start} - {end}
      </CalendarButton>
    )
  }

  function getDaysArray() {
    return [
      ['', '', '', '', '', '', ''],
      ['', '', '', '', '', '', ''],
      ['', '', '', '', '', '', ''],
      ['', '', '', '', '', '', ''],
      ['', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '']
    ]
  }

  function fillOutputWithDefaultTimes(output, year, month, day) {
    if (output.hasOwnProperty(year)) {
      if (output[year].hasOwnProperty(month)) {
        if (!output[year][month].hasOwnProperty(day)) {
          output[year][month][day] = getDefaultTimes()
        }
      } else {
        output[year][month] = {
          [day]: getDefaultTimes()
        }
      }
    } else {
      output[year] = {
        [month]: {
          [day]: getDefaultTimes()
        }
      }
    }
  }

  const convertAvailabilityFromDatabase = (availability) => {

    const output = {}
    for (const range of availability) {

      
     
      // const startTime = String(range.hour).padStart(2, '0')+':'+String(range.minutes).padStart(2, '0')
     
   
      
      fillOutputWithDefaultTimes(output, range.year, range.monthString, range.day)
   
      let i = 0

      while (i < output[range.year][range.monthString][range.day].length && output[range.year][range.monthString][range.day][i].time !== range.fullTime) i++
      while (i < output[range.year][range.monthString][range.day].length && output[range.year][range.monthString][range.day][i].time === range.fullTime) {
        output[range.year][range.monthString][range.day][i].reserved = range.reserved
        output[range.year][range.monthString][range.day][i].available =  true
        i++
      }
    }
    return output
  }

  function addActiveDayToOutput(activeDay, output, month, day, year) {
    
   
    

  
    for (const time of activeDay) {
   
      if (time.available) {
    
        output.push({
         reserved :Boolean(time.reserved),
         available: true,
         year : +year,
         month: +moment().month(month).format("M") - 1,
         monthString: month,
         fullTime: time.time,
         day : +day,
         hour: time.time.substring(0,time.time.indexOf(":")),
         minutes: time.time.slice(-2)
        })
      } 
     
    }
  }

  const convertAvailabilityForDatabase = (availability) => {
    const output = []
    for (const year in availability) {
      for (const month in availability[year]) {
        for (const day in availability[year][month]) {
          const activeDay = availability[year][month][day]
          addActiveDayToOutput(activeDay, output, month, day, year)
        }
      }
    }
    
    return output
  }

  const combineTimeArrays = (a, b) => {
    for (let i = 0; i < a.length; i++) {
      a[i].available = a[i].available || b[i].available
    }
    return a
  }



  const today = moment()
  const [availabilityState, setAvailabilityState] = useState(convertAvailabilityFromDatabase(availability))
  // POPOVER
  // const [quickAvailability, setQuickAvailability] = useState(makeQuickAvailability(availability))

  const [activeDay, setActiveDay] = useState(null)
  const [year, setYear] = useState(Number(today.format('YYYY')))
  const [monthNumber, setMonthNumber] = useState(Number(today.format('M')))
  const [settingMultiple, setSettingMultiple] = useState(false)
  const months = useMonths(year)
  const { firstDay, month, lastDay } = months[monthNumber]
  let dayOfWeek = Number(moment(firstDay).format('d')) - 1
  const days = getDaysArray()
  const [times, setTimes] = useState(getDefaultTimes())
  let week = 0
  let dayOfMonth = 1

  useEffect(() => {
    if (availability) {
      
    setActiveDay(null)

      setAvailabilityState(convertAvailabilityFromDatabase(availability))
      // setQuickAvailability(makeQuickAvailability(availability))
    }
  }, [availability])

  while (week < 6 && dayOfMonth <= lastDay) {
    days[week][dayOfWeek] = dayOfMonth
    dayOfMonth++
    dayOfWeek++
    if (dayOfWeek === 7) {
      week++
      dayOfWeek = 0
    }
  }
  const createArrowHandler = (delta) => () => {
    let newMonth = monthNumber + delta
    if (newMonth > 12) {
      setYear(year + 1)
      newMonth = 1
    } else if (newMonth < 1) {
      setYear(year - 1)
      newMonth = 12
    }
    setActiveDay(null)
    !settingMultiple && setTimes(getDefaultTimes())

    setMonthNumber(newMonth)
  }

  function addTimeToDay(newTimes) {
    const newAvail = availabilityState
    if (newAvail.hasOwnProperty(year)) {
      if (newAvail[year].hasOwnProperty(month)) {
        newAvail[year][month][activeDay] = newTimes
      } else {
        newAvail[year][month] = {
          [activeDay]: newTimes
        }
      }
    } else {
      newAvail[year] = {
        [month]: {
          [activeDay]: newTimes
        }
      }
    }
    
    setAvailabilityState(newAvail)
    // setQuickAvailability(makeQuickAvailability(convertAvailabilityForDatabase(newAvail)))
  }

  async function addTimesToDay(day) {
   
    const cleanTimes = async (timesArr) => {
   

      const newArr = []
      for await (const time of timesArr) {
       
      newArr.push({time: time.time, available:time.available, reserved: false})
      }
      return newArr
    }
    
    const newAvail = { ...availabilityState }
    if (newAvail[year]) {
      
  
   

      if (newAvail[year][month]) {
      
  

        if (newAvail[year][month][day]) {
  

        
          newAvail[year][month][day] = combineTimeArrays(newAvail[year][month][day], await cleanTimes(times) )
        } else {
     
        

        //  console.log(times,times?.map(time => ( {time: time.time, available:false, reserved: false})))
        // newAvail[year][month][day] = times

          newAvail[year][month][day] = await cleanTimes(times) 
          
        // times?.map(time => ( {time: time.time, available:false, reserved: false}))
        }
      } else {
   
        


        newAvail[year][month] = {
          [day]: await cleanTimes(times)
        }
      }
    } else {
    
      


      newAvail[year] = {
        [month]: {
          [day]: await cleanTimes(times) 
        }
      }
    }
   
    setAvailabilityState(newAvail)
    // setQuickAvailability(makeQuickAvailability(convertAvailabilityForDatabase(newAvail)))
  }

  function examineAvailabilityForDay(day) {
    if (availabilityState[year] && availabilityState[year][month] && availabilityState[year][month][day]) {
      setTimes(availabilityState[year][month][day])
    } else {
      setTimes(getDefaultTimes())
    }
    setActiveDay(day)
  }

  const createTimeHandler = (i) => () => {
    const newTimes = [...times]
    newTimes[i].available = !newTimes[i].available
    if (activeDay) {
      addTimeToDay(newTimes)
    }
    setTimes(newTimes)
  }
  const createDayHandler = (day) => {
    if (settingMultiple) {
      
      addTimesToDay(day)
    } else {
      

      examineAvailabilityForDay(day)
    }
  }
  const handleSetMultiple = () => {
    setActiveDay(null)
    setSettingMultiple(!settingMultiple)
  }

  
  const handleSaveAvailability = () => {
    
    const data = convertAvailabilityForDatabase(availabilityState)

    setAvailability(data)
  }
  const handleJumpToCurrent = () => {
    setYear(Number(today.format('YYYY')))
    setMonthNumber(Number(today.format('M')))
    setActiveDay(null)
    !settingMultiple && setTimes(getDefaultTimes())
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverContent, setPopoverContent] = useState(null)
  // const handleOpenPopover = (date) => {
  //   return (e) => {
  //     if (quickAvailability[date]) {
  //       setPopoverContent(
  //         quickAvailability[date].map((time, idx) => (
  //           <p style={{ marginLeft: 20, marginRight: 20 }} key={`${time}-${idx}`}>
  //             {time}
  //           </p>
  //         ))
  //       )
  //       setAnchorEl(e.target)
  //     }
  //   }
  // }
  // const handleClosePopover = () => {
  //   setAnchorEl(null)
  //   setPopoverContent(null)
  // }
  return (
    // <ThemeProvider theme={theme}>
    <CalendarGrid container direction="column" alignItems="center">
      <Grid item>
        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Grid item>
            <IconButton disabled={year === Number(today.format('YYYY')) && month === today.format('MMMM')} onClick={createArrowHandler(-1)}>
              <ArrowLeft />
            </IconButton>
          </Grid>
          <Grid item>
            <Card sx={{ padding: 4, margin: 2 }} variant="outlined">
              <Grid container direction="column" alignItems="center">
                <h3>
                  {labels.es[month]} {year}
                </h3>
                {days.map((week, i) => (
                  <Grid key={i} item>
                    <Grid container direction="row">
                      {week.map((day, i) => (
                        <Grid key={year + month + i} item>
                          <IconButton
                            onClick={() => createDayHandler(day)}
                            color={
                              activeDay === day
                                ? 'primary'
                                : availabilityState[year] &&
                                  availabilityState[year][month] &&
                                  availabilityState[year][month][day] &&
                                  availabilityState[year][month][day]?.filter((x) => x.available).length > 0
                                ? 'warning'
                                : 'default'
                            }
                            disabled={
                              !day ||
                              (year === Number(today.format('YYYY')) && month === today.format('MMMM') && day < Number(today.format('D')))
                            }
                            size="medium"
                            // onMouseEnter={handleOpenPopover(`${month} ${day}, ${year}`)}
                            // onMouseLeave={handleClosePopover}
                          >
                            <CalendarText>{day}</CalendarText>
                          </IconButton>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
                <CalendarPopover
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                >
                  {popoverContent}
                </CalendarPopover>
                <CalendarButtonNoMargin
                  disabled={year === Number(today.format('YYYY')) && month === today.format('MMMM')}
                  onClick={handleJumpToCurrent}
                >
                  Ir a mes actual
                </CalendarButtonNoMargin>
              </Grid>
            </Card>
          </Grid>
          <Grid item>
            <IconButton onClick={createArrowHandler(1)}>
              <ArrowRight />
            </IconButton>
          </Grid>
        </Grid>
          {activeDay && (
            <Grid item>
              <Grid container justifyContent="center" alignItems="center" wrap="wrap">
                <Grid item>
                  <Grid style={{ maxHeight: '75vh' }} container direction="column" alignItems="center" wrap="wrap">
             
                    {times.map(
                      (time, i) =>
                        i < times.length - 1 && (
                          <TimeButton
                            key={time.time}
                            start={time.time}
                            end={times[i + 1].time}
                            handleClick={createTimeHandler(i)}
                            available={time.available}
                            disabled={time.reserved}
                          />
                        )
                    )}
                  </Grid>
                </Grid>
                {/* <Grid item>
                  <Grid container direction="column" alignItems="center" wrap="wrap">
                    {times.map(
                      (time, i) =>
                        i < times.length - 1 &&
                        i > 5 && (
                          <TimeButton
                            key={time.time}
                            start={time.time}
                            end={times[i + 1].time}
                            handleClick={createTimeHandler(i)}
                            available={time.available}
                            disabled={time.reserved}
                          />
                        )
                    )}
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          )}
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Grid item>
   

            <CalendarButton color="primary" variant="contained" onClick={handleSetMultiple}>
              {settingMultiple ? 'Listo' : 'Añadir seleccionados a otros días'}
            </CalendarButton>
  
          </Grid>
          <Grid item>
            <CalendarButton disabled={saving} color="primary" variant="contained" onClick={handleSaveAvailability}>
              Actualizar Disponibilidad
            </CalendarButton>
          </Grid>
        </Grid>
      </Grid>

      {!activeDay && (
        <Alert sx={{ mt: 2 }} severity="warning">
          Selecciona un día para ver la disponibilidad
        </Alert>
      )}
    </CalendarGrid>
    // </ThemeProvider>
  )
}
