import React from 'react'
import { styled, Box, Grid, Chip } from '@mui/material'
import { grey, red } from '@mui/material/colors'
import Switch, { SwitchProps } from '@mui/material/Switch'

export const MainBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  borderTop: '1px solid',
  // borderBottom: '1px solid',
  borderColor: '#E5F2FA',
  '& .Mui-disabled': { color: `${grey[600]} !important`, WebkitTextFillColor: `${grey[600]} !important` }
}))

export const GridFlexBetween = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

export const ChipGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  padding: theme.spacing(0.5),
  margin: theme.spacing(0)
}))

export const ChipElement = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.3),
  listStyle: 'none',
  '& .MuiChip-root': {
    backgroundColor: grey[200],
    borderColor: grey[200],
    fontSize: '12px',
    lineHeight: '16px',
    color: grey[800]
  }
}))

export const ChipAdd = styled(Chip)(({ theme }) => ({
  backgroundColor: red[100],
  color: red[800],
  margin: theme.spacing(0.3),
  fontSize: '12px',
  fontWeight: 500,
  flexDirection: 'row-reverse',
  '& .MuiChip-icon': { margin: 0, marginRight: 2 },
  '& .MuiChip-label': { paddingRight: 0 },
  '&:hover': { backgroundColor: red[100] }
}))

export const SwitchFitted = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5)
}))

export const CustomSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary,
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  })
)

export const FooterFormBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row'
  }
}))
