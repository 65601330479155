import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from '../../../types/pages.types'
import { useForm } from 'src/hooks/useForm'
import { AlertContext } from 'src/context/alert/alertContext'
import { labels } from 'src/labels/main_labels'
import { createCompanyConfig, updateCompanyConfig } from 'src/services/companies.services'
import { AuthContext } from 'src/context/auth/authContext'
import SelectDialog from 'src/components/Form/SelectDialog'
import { InfoContext } from 'src/context/info/infoContext'

const INITIAL_VALUES = {
  name: ''
}

export default function SectorsAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData }: AddEditProps): JSX.Element {
  const editing = !!dataToEdit
  const { showSnackbar } = useContext(AlertContext)
  const { userData } = useContext(AuthContext)
  const { allCompanies } = useContext(InfoContext)
  const [company, setCompany] = useState<any | never>('')
  const [sectorType, setSectorType] = useState<any | never>('')


  const { handleChange, values, resetForm } = useForm(INITIAL_VALUES, dataToEdit)
  const [loading, setLoading] = useState(false)

  const resetComponent = () => {
    setLoading(false)
    setCompany('')
    setSectorType('')
    resetForm()
  }

  useEffect(() => {
    return () => resetComponent()
  }, [dataToEdit])

  const onSave = async () => {
    setLoading(true)
    const newSectors = JSON.parse(JSON.stringify(values))
    const companyId = userData.role.id === 'role1' ? company?.id : userData.companyId
    if (!companyId) return
    try {
      if (!dataToEdit) {
        newSectors.createdAt = Date.now()
        newSectors.updatedAt = Date.now()
        newSectors.type = 'sectors'
        newSectors.companyId = companyId
        newSectors.sectorType = sectorType

        await createCompanyConfig(newSectors)
        showSnackbar('success', labels.es.sectorsCreated)
      } else {
        newSectors.updatedAt = Date.now()
        newSectors.sectorType = sectorType
        newSectors.companyId = companyId

        await updateCompanyConfig(newSectors)
        showSnackbar('success', labels.es.sectorsUpdated)
      }
      resetComponent()
      if (fetchData) fetchData()
      closeModal()
    } catch (error: any) {
      showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit.id)
  }

  return (
    <Modal
      title={editing ? dataToEdit.name : labels.es.addSectors}
      open={openModal}
      handleClose={closeModal}
      footer={
        <FooterForm
          text={labels.es.sector}
          closeModal={closeModal}
          onSave={onSave}
          onDelete={onDelete ? handleDelete : undefined}
          editing={editing}
          loading={loading}
          disabled={!values.name}
        />
      }
    >
      <Box id="form-sectors">
        {userData.role.id === 'role1' && (
          <SelectDialog required text={labels.es.company} setData={setCompany} data={company} options={allCompanies} />
        )}
   
        {userData.role.id === 'role1' && (
          <SelectDialog required text={'Tipo'} setData={setSectorType} data={sectorType} options={[{id:'Oficina',name:'Oficina'},{id:'Almacén',name:'Almacén'}]} />
        )}
        <TextInput
          required
          value={values.name}
          handleChange={(e) => handleChange(e)}
          name="name"
          text={labels.es.name}
          placeholder={labels.es.name}
        />
      </Box>
    </Modal>
  )
}
