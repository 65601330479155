export function validateForm(fields: Array<string>): boolean {
  let isPassed = false

  fields.forEach((input) => {
    if (input !== '') isPassed = true
  })

  return isPassed
}

export function compareInfoToEdit(previousData: Record<string, unknown>, newData: Record<string, unknown>): unknown {
  const keys = Object.keys(newData)
  const newObj: Record<string, unknown> = {}
  keys.forEach((elm) => {
    if (newData[elm] !== previousData[elm] || !previousData[elm]) newObj[elm] = newData[elm]
  })
  return newObj
}

export const validateEmail = (email: string): boolean => {
  // const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
  // eslint-disable-next-line no-useless-escape
  const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  return !!email.match(pattern)
}
