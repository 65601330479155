import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from '../../../types/pages.types'
import { useForm } from 'src/hooks/useForm'
import { AlertContext } from 'src/context/alert/alertContext'
import { labels } from 'src/labels/main_labels'
import { createCompanyConfig, updateCompanyConfig } from 'src/services/companies.services'
import { InfoContext } from 'src/context/info/infoContext'
import { AuthContext } from 'src/context/auth/authContext'
import SelectDialog from 'src/components/Form/SelectDialog'

const INITIAL_VALUES = {
  name: ''
}

export default function RolesAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData, companyEdit }: AddEditProps): JSX.Element {
  const editing = !!dataToEdit
  const { showSnackbar } = useContext(AlertContext)
  const { userData } = useContext(AuthContext)
  const { allCompanies } = useContext(InfoContext)
  const [company, setCompany] = useState<any | never>('')
  const [role, setRole] = useState<any | never>('')

  const { handleChange, values, resetForm } = useForm(INITIAL_VALUES, dataToEdit)
  const [loading, setLoading] = useState(false)

  const resetComponent = () => {
    setLoading(false)
    setCompany('')
    resetForm()
  }

  useEffect(() => {
    if (companyEdit) setCompany(companyEdit)
    if (dataToEdit) setRole(dataToEdit.rol)
    return () => resetComponent()
  }, [dataToEdit])

  const onSave = async () => {
    setLoading(true)
    const newRoles = JSON.parse(JSON.stringify(values))
    newRoles.role = role.id
    const companyId = userData.role.id === 'role1' ? company?.id : userData.companyId
    if (!companyId) return
    try {
      if (!dataToEdit) {
        newRoles.createdAt = Date.now()
        newRoles.updatedAt = Date.now()
        newRoles.type = 'roles'
        newRoles.companyId = companyId

        await createCompanyConfig(newRoles)
        showSnackbar('success', labels.es.rolesCreated)
      } else {
        newRoles.updatedAt = Date.now()
        await updateCompanyConfig(newRoles)
        showSnackbar('success', labels.es.rolesUpdated)
      }
      resetComponent()
      if (fetchData) fetchData()
      closeModal()
    } catch (error: any) {
      showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit.id)
  }

  const disabledFooter = () => {
    if (userData.role.id === 'role1' && !values.name && !company) return true
    else if (!values.name) return true
    else if (!role) return true
    else return false
  }

  const allRoles = [
    { id: 'role1', name: 'Rol 1' },
    { id: 'role2', name: 'Rol 2' },
    { id: 'role3', name: 'Rol 3' },
    { id: 'role4', name: 'Rol 4' },
    { id: 'role5', name: 'Rol 5' },
    { id: 'role6', name: 'Rol 6' }
  ]

  return (
    <Modal
      title={editing ? dataToEdit.name : labels.es.addPositions}
      open={openModal}
      handleClose={closeModal}
      footer={
        <FooterForm
          text={labels.es.role}
          closeModal={closeModal}
          onSave={onSave}
          onDelete={onDelete ? handleDelete : undefined}
          editing={editing}
          loading={loading}
          disabled={disabledFooter()}
        />
      }
    >
      <Box id="form-roles">
        {userData.role.id === 'role1' && (
          <SelectDialog required text={labels.es.company} setData={setCompany} data={company} options={allCompanies} />
        )}
        <SelectDialog required text={labels.es.roles} setData={setRole} data={role} options={allRoles} />
        <TextInput
          required
          value={values.name}
          handleChange={(e) => handleChange(e)}
          name="name"
          text={labels.es.name}
          placeholder={labels.es.name}
        />
      </Box>
    </Modal>
  )
}
