import firebase from 'src/firebase/firebase'

export const getBookingData = async (id: string): Promise<any> => {
  return firebase.db
    .collection('bookings')
    .doc(id)
    .get()
    .then((response) => {
      const data = response.data()
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba recuperar los datos de la cita')
    })
}

export const getAllBookings = async (): Promise<any> => {
  try {
    return await firebase.db
      .collection('bookings')
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar las citas')
  }
}

export const getAllBookingsByCompany = async (companyId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('bookings')
      .where('companyId', '==', companyId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar las citas')
  }
}

export const getAllBookingsByCompanyAndStore = async (companyId: string, storeId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('bookings')
      .where('companyId', '==', companyId)
      .where('storeId', '==', storeId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar las citas')
  }
}

export const getAllBookingsForPhysio = async (storesArr: []): Promise<any> => {
  try {
    return await firebase.db
      .collection('bookings')
      .where('storeId', 'in', storesArr)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar las citas del fisioterapeuta')
  }
}

export const getUserBookings = async (userId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('bookings')
      .where('patient.id', '==', userId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar las citas del empleado')
  }
}

export const createBooking = async (bookingData: Record<string, any>): Promise<any> => {
  const createBookingCloud = firebase.functions.httpsCallable('saveBookingCloud')

  try {
    return await createBookingCloud(bookingData)
  } catch (error) {
    console.log(error)

    throw new Error('Hubo un error mientras se intentaba crear la cita')
  }
}

export const sendNewBookingNotification = async (bookingData: Record<string, any>): Promise<any> => {
  const createBookingNotification = firebase.functions.httpsCallable('sendNewBookingEmail')

  try {
    return await createBookingNotification(bookingData)
  } catch (error) {
    console.log(error)

    throw new Error('Hubo un error mientras se intentaba mandar el email de creación de la cita')
  }
}

export const sendEditBookingNotification = async (bookingData: Record<string, any>): Promise<any> => {
  const createEditBookingNotification = firebase.functions.httpsCallable('sendEditBookingEmail')

  try {
    return await createEditBookingNotification(bookingData)
  } catch (error) {
    console.log(error)

    throw new Error('Hubo un error mientras se intentaba mandar el email de modificación de la cita')
  }
}


export const deleteBooking = async (uid: string): Promise<any> => {
  try {
    return firebase.db.collection('bookings').doc(uid).delete()
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba eliminar la cita')
  }
}

export const updateBooking = async (bookingData: Record<string, any>): Promise<any> => {
  try {
    return firebase.db.collection('bookings').doc(bookingData.id).update(bookingData)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba actualizar los datos de la cita')
  }
}

// export const getStorePhysio = async (storeId: string): Promise<any> => {
//   try {
//     return await firebase.db
//       .collection('users')
//       .where('storeId', '==', storeId)
//       .where(`role.id`, '==', 'role2')
//       .get()
//       .then((snapshot: any) => {
//         if (snapshot.empty) {
//           return []
//         }
//         return snapshot.docs.map((doc: any) => {
//           return { id: doc.id, ...doc.data() }
//         })
//       })
//   } catch (error) {
//     throw new Error('Hubo un error mientras se intentaba recuperar el calendario del fisio')
//   }
// }

// export const updatePhysioCalendar = async (physioId: string, calendar: Record<string, any>): Promise<any> => {
//   try {
//     return await firebase.db.collection('users').doc(physioId).update({ calendar: calendar })
//   } catch (error) {
//     throw new Error('Hubo un error mientras se intentaba actualizar el calendario del fisioterapeuta')
//   }
// }

export const getAllIncidentsByCompany = async (companyId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('incidents')
      .where('companyId', '==', companyId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar los incidentes')
  }
}

export const getAllCampaignsByCompany = async (companyId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('campaigns')
      .where('companyId', '==', companyId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar las campañas')
  }
}
