import React, { SetStateAction, Dispatch, useState } from 'react'
import { Chip, Grid, Typography } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { MainBox, GridFlexBetween, ChipGrid, ChipElement, ChipAdd } from './form.styles'
import { SearchDialog } from '../Modals/SearchDialog'
import { LabelType, labels as labelsMain } from 'src/labels/main_labels'

interface ChipSelectorProps {
  setData: Dispatch<SetStateAction<any[] | never[]>>
  text: string
  data: any[]
  options: any[]
  labels?: LabelType
  required?: boolean
  fieldToSave?: string
  onlyOneSelected?: boolean
}

export default function ChipSelector({
  text,
  data,
  setData,
  options,
  labels,
  required,
  fieldToSave,
  onlyOneSelected
}: ChipSelectorProps): JSX.Element {
  const handleDelete = (idx: number) => {
    setData((prev) => prev.filter((elm: string, i: number) => idx !== i))
  }

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const addChip = (chipData: any) => {
    const dataToSave = fieldToSave ? chipData : chipData
   
    if (data.find(elm => elm.id === dataToSave.id)) {
      setData((prev) => prev.filter((elm) => elm.id !== dataToSave.id))
      return
    }
    setData((prev) => [...prev, dataToSave])
  }

  const getLabel = (elm: any) => {
    if (fieldToSave && options) {
      const label = options.find((opt) => opt[fieldToSave] === elm)
      if (label) {
        return label.name
      }
    }
    if (elm.name) {
      return elm.name
    }
    if (labels) {
      return labels.es[elm]
    }
  }
  return (
    <MainBox>
      <GridFlexBetween container spacing={1}>
        <Grid item xs={4}>
          <Typography variant="subtitle2" component="h6">
            {text} {required ? '*' : ''}
          </Typography>
        </Grid>
        <ChipGrid item xs={8}>
          {data &&
            data.map((elm: any, idx: number) => (
              <ChipElement key={idx}>
                <Chip size="small" label={elm?.name} variant="outlined" onDelete={() => handleDelete(idx)} />
              </ChipElement>
            ))}
          {((onlyOneSelected && data.length === 0) || !onlyOneSelected) && (
            <ChipAdd
              onClick={() => setOpen(true)}
              icon={<AddOutlinedIcon />}
              size="small"
              label={labelsMain.es.add}
              variant="filled"
              color="primary"
            />
          )}
        </ChipGrid>
      </GridFlexBetween>
      <SearchDialog
        single={onlyOneSelected}
        onClose={handleClose}
        open={open}
        optionsData={options}
        setter={addChip}
        selected={data}
        text={text}
        labels={labels}
      />
    </MainBox>
  )
}
