import React, { useState, useContext } from 'react'
import {
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  LinearProgress,
  Popover
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import TableSnackbar from './TableSnackbar'
import { StyledTableCell, CheckBoxCell, StyledTableRow } from './table.styles'
import DisplayFields from './DisplayFields'
import PopoverTableData from './PopoverTableData'

import { HeaderContext } from 'src/context/header/headerContext'

import { DynamicTableProps } from '../../types/pages.types'

import { DRAWER_WIDTH_CLOSE, DRAWER_WIDTH_OPEN, FOOTER_BAR_HEIGHT } from 'src/constants/const'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aNew = typeof a[orderBy] === 'object' ? (a[orderBy] as any).name : a[orderBy]
  const bNew = typeof b[orderBy] === 'object' ? (b[orderBy] as any).name : b[orderBy]
  if (bNew < aNew) {
    return -1
  }
  if (bNew > aNew) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof never>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
  headCells: any[]
  noActions?: boolean
  noCheckbox?: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead
      sx={{
        '& .Mui-active': { color: '#fff !important' },
        '& .MuiTableCell-head': { backgroundColor: '#196fc7 !important', color: '#fff !important' }
      }}
    >
      <TableRow>
        {!props.noCheckbox && (
          <CheckBoxCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts'
              }}
            />
          </CheckBoxCell>
        )}
        {headCells.map((headCell, idx) => (
          <StyledTableCell
            key={`${headCell.idField}-${idx}`}
            align={headCell.align}
            sx={{
              px: headCell.disablePadding ? 0 : '20px !important'
            }}
            sortDirection={orderBy === headCell.idField ? order : false}
          >
            {headCell.order ? (
              <TableSortLabel
                active={orderBy === headCell.idField}
                direction={orderBy === headCell.idField ? order : 'asc'}
                onClick={createSortHandler(headCell.idField)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </StyledTableCell>
        ))}
        {!props.noActions && <StyledTableCell align="right"></StyledTableCell>}
      </TableRow>
    </TableHead>
  )
}

export default function DynamicTable({
  snackBar,
  headCells,
  rows,
  onDelete,
  onEdit,
  onReSchedule,
  onDeactivate,
  onSign,
  onSendMail,
  onCloseIncident,
  filters,
  loading,
  onRowClick,
  noActions,
  noCheckbox,
  initialOrderBy,
  noPagination,
  selected,
  setSelected,
  initialOrder
}: DynamicTableProps): JSX.Element {
  const { openLayout } = useContext(HeaderContext)

  const [order, setOrder] = useState<Order>(initialOrder || 'asc')
  const [orderBy, setOrderBy] = useState<string>(initialOrderBy || 'name')
  // const [selected, setSelected] = useState<string[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (rows && event && event.target.checked) {
      const newSelecteds = rows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    if (rows && event && !event.target.checked && rows.length !== selected.length) {
      const newSelecteds = rows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handleRowClick = (row: any) => {
    if (onRowClick) onRowClick(row)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  const onDeleteSelected = () => {
    if (onDelete) onDelete(selected)
  }

  // POPOVER
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [rowPopover, setRowPopover] = useState<Record<string, unknown> | null>(null)
  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>, index: number, row: Record<string, any>) => {
    setRowPopover(row)
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopover = () => {
    setAnchorEl(null)
    setRowPopover(null)
  }
  const openPopover = Boolean(anchorEl)
  const idPop = openPopover ? 'simple-popover' : undefined

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ flex: 1 }}>
          {loading && <LinearProgress />}
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows ? rows.length : 0}
                headCells={headCells}
                noActions={noActions}
                noCheckbox={noCheckbox}
              />

              <TableBody>
                {/* if you need to support IE11, you can replace sort with:
                stableSort(rows, getComparator(order, orderBy)).slice() */}
                {rows &&
                  rows
                    .sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id)
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id || index}
                          selected={isItemSelected}
                        >
                          {!noCheckbox && (
                            <CheckBoxCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                onClick={(event) => handleClick(event, row.id)}
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId
                                }}
                              />
                            </CheckBoxCell>
                          )}
                          {headCells.map((col, colIdx) => (
                            <StyledTableCell key={`${col}-${colIdx}`} align={col.align} onClick={() => handleRowClick(row)}>
                              <DisplayFields col={col} row={row} />
                            </StyledTableCell>
                          ))}
                          {!noActions && (
                            <StyledTableCell align="right">
                              <Button
                                size="small"
                                type="submit"
                                // color="info"
                                variant="outlined"
                                aria-describedby={`${idPop}-${index}`}
                                onClick={(e) => handlePopover(e, index, row)}
                              >
                                <MoreHorizIcon />
                              </Button>
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      )
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {!noPagination && (
          <>
            <Box sx={{ height: { xs: filters ? '104px' : FOOTER_BAR_HEIGHT, md: FOOTER_BAR_HEIGHT } }} />
            <Box
              sx={{
                height: { xs: filters ? '104px' : FOOTER_BAR_HEIGHT, md: FOOTER_BAR_HEIGHT },
                position: 'fixed',
                bottom: 0,
                width: { xs: '100%', sm: `calc(100% - ${openLayout ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSE}px)` },
                overflowY: 'hidden',
                backgroundColor: '#ffffff',
                // zIndex: 999
                zIndex: 0
              }}
            >
              <Divider />
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: filters ? 'space-between' : 'flex-end',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                {filters && filters}
                <TablePagination
                  sx={{ '& .MuiToolbar-root': { pl: 0 } }}
                  rowsPerPageOptions={[]}
                  component="div"
                  count={rows ? rows.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Por página"
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from}–${to} de ${count !== -1 ? count : `más de ${to}`}`
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
      {snackBar && rows && (
        <TableSnackbar selected={selected} totalLength={rows.length} onSelectAllClick={handleSelectAllClick} onDelete={onDeleteSelected} />
      )}
      {rowPopover && (
        <Popover
          id={idPop}
          elevation={1}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <PopoverTableData
            onEdit={onEdit}
            onReSchedule={onReSchedule}
            onDeactivate={onDeactivate}
            onSign={onSign}
            onCloseIncident={onCloseIncident}
            onSendMail={onSendMail}
            onDelete={onDelete}
            rowPopover={rowPopover}
            handleClosePopover={handleClosePopover}
          />
        </Popover>
      )}
    </>
  )
}
