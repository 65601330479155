import React, { useContext, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext'
import { Grid, Container, TextareaAutosize } from '@mui/material'
import { ReactComponent as Faq } from 'src/assets/icons/ayuda/Faq.svg'
import { ReactComponent as ComoFunciona } from 'src/assets/icons/ayuda/ComoFunciona.svg'
// import { ReactComponent as Email } from 'src/assets/icons/ayuda/Email.svg'
import Email from '@mui/icons-material/Email';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CookieIcon from '@mui/icons-material/Cookie';
import SecurityIcon from '@mui/icons-material/Security';
import GavelIcon from '@mui/icons-material/Gavel';
import { ReactComponent as Phone } from 'src/assets/icons/ayuda/Phone.svg'
import { ReactComponent as ContactForm } from 'src/assets/icons/ayuda/ContactForm.svg'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'

import { ReactComponent as Check } from 'src/assets/icons/ayuda/Check.svg'
import { AlertContext } from 'src/context/alert/alertContext'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import CardAyuda from 'src/components/Cards/CardAyuda'
import { CardSummaryGrid } from 'src/components/Cards/cards.styles'
import { labels } from 'src/labels/main_labels'
import { privacyPolicy, termsAndConditionsUrl } from 'src/constants/const'
import TextInput from 'src/components/Form/TextInput'
import { sendInquiryMessage } from 'src/services/emails.services'

export default function Ayuda(): JSX.Element {
  const { showHeader } = useContext(HeaderContext)
  const { userData } = useContext(AuthContext)
  const [openModal, setOpenModal] = useState(false)
  const [question, setQuestion] = useState('')
  const [loading, setLoading] = useState(false)
  const { showSnackbar } = useContext(AlertContext)

  const Header = () => {
    return (
      <div className="flex-center ">
        <Typography variant="h3" component="h3">
          {labels.es.help}
        </Typography>
        <Typography variant="subtitle2" component="h3"></Typography>
      </div>
    )
  }

  useEffect(() => {
    showHeader(Header)
  }, [])

  const onSave = async () => {
    if (question !== '') {
      const data = {
        message: question,
        emailFrom: userData?.email,
        userFullName: userData.name + ' ' + userData.lastname + ' ' + userData.lastname2,
        store: userData.store?.name || '',
        position: userData?.role?.name || ''
      }
      try {
        await sendInquiryMessage(data)
        showSnackbar('success', labels.es.questionSended)
        setQuestion('')
        setOpenModal(false)
      } catch (error: any) {
        showSnackbar('error', error.message)
      }
    }
  }

  return (
    <Container sx={{ my: 3 }}>
      <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
        {labels.es.helpCenter}
      </Typography>
      <Grid container spacing={3}>
     
       
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={MenuBookIcon} manuals={true} title="Manuales" />
        </CardSummaryGrid>
      </Grid>
      <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
        {labels.es.contactMetaphysio}
      </Typography>

      <Grid container spacing={3}>
        <CardSummaryGrid item xs={12} md={4} onClick={() => window.open('mailto:soporte@metaphysio.app?subject="Tengo una consulta"')}>
          <CardAyuda Icon={Email} text={labels.es.helpEmail} title={labels.es.email} underlineText="soporte@metaphysio.app" />
        </CardSummaryGrid>
        {/* <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda
            Icon={Instagram}
            text={labels.es.helpInstagram}
            title={labels.es.instagram}
            externalLink="https://www.instagram.com/metaphysio/"
          />
        </CardSummaryGrid> */}
        {/* <CardSummaryGrid item xs={12} md={4} onClick={() => window.open('TEL:666666666')}>
          <CardAyuda Icon={Phone} text={labels.es.helpPhone} title={labels.es.phone} underlineText="666 666 666" />
        </CardSummaryGrid> */}
      </Grid>
      <Typography sx={{ mb: 1, mt: 4 }} variant="body1">
        {labels.es.metaphysioPolitic}
      </Typography>
      <Grid container spacing={3}>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={GavelIcon} title={labels.es.termsConditions} link={'/aviso-legal'} />
        </CardSummaryGrid>
        <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={SecurityIcon} title={labels.es.privacyPolicy} link={'/politica-de-privacidad'} />
        </CardSummaryGrid>
        {/* <CardSummaryGrid item xs={12} md={4}>
          <CardAyuda Icon={CookieIcon} title={labels.es.cookiesPolicy} />
        </CardSummaryGrid> */}
      </Grid>
    
    </Container>
  )
}
