import firebase from 'src/firebase/firebase'

export const recoverAccountPassword = async (email: string): Promise<any> => {
  try {
    return await firebase.auth.sendPasswordResetEmail(email)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba enviar correo de recuperación de contraseña')
  }
}

export const getUserData = async (id: string): Promise<any> => {
  return firebase.db
    .collection('users')
    .doc(id)
    .get()
    .then((response) => {
      const data = response.data()
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaban cargar los datos del usuario')
    })
}

export const createUser = async (data: Record<string, any>): Promise<any> => {
  const createUserCloud = firebase.functions.httpsCallable('createUser')

  try {
    return createUserCloud(data.credentials).then(async (newUserRef) => {
      await firebase.db
        .collection('users')
        .doc(newUserRef.data.uid)
        .set({
          id: newUserRef.data.uid,
          storeId: data.credentials.store || null,
          companyId: data.credentials.companyId || null,
          birthDate: data.credentials.birthDate || null,
          gender: data.credentials.gender || null,
          sector: data.credentials.sector || null,
          position: data.credentials.position || null,
          workingSince: data.credentials.workingSince || null,
          email: data.credentials.email,
          name: data.profileData.name,
          lastname: data.profileData.lastname,
          lastname2: data.profileData.lastname2,
          createdAt: new Date(),
          createdBy: data.profileData.creatorUid,
          role: data.profileData.role
        })
    })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba crear el usuario')
  }
}

export const updateUser = async (userData: Record<string, any>): Promise<any> => {
  try {
    return firebase.db.collection('users').doc(userData.uid).update(userData)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba actualizar los datos de usuario')
  }
}
