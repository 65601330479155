import React from 'react'
import { Button, Hidden } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

interface AddButtonProps {
  onClick: () => void
  text: string
}

export function AddButton({ onClick, text }: AddButtonProps) {
  return (
    <Button size="small" type="submit" color="primary" variant="contained" onClick={onClick}>
      <Hidden smDown>{text}</Hidden>
      <Hidden smUp>
        <AddIcon />
      </Hidden>
    </Button>
  )
}
