export const faq = {
  es: [
    {
      title: 'Alcalá de Guadaira',
      text: ['Martes y Viernes de 13.00 a 17.00']
    },
    {
      title: 'Alcobendas',
      text: ['Martes y Miércoles de 12.00 a 16.00']
    },
    {
      title: 'Alcorcón',
      text: ['Miércoles y Viernes de 11:30 a 15:30']
    },
    {
      title: 'Badalona',
      text: ['Jueves de 12:00 a 16:00']
    },
    {
      title: 'Bormujos',
      text: ['Martes de 8.00 a 12.00 y Jueves de 13.00 a 17.00']
    },
    {
      title: 'Burgos',
      text: ['Miércoles de 12.00 a 16.00']
    },
    {
      title: 'Castellón',
      text: ['Miércoles de 12.30 a 16.30']
    },
    {
      title: 'Finestrat',
      text: ['Miércoles de 12.00 a 16.00']
    },
    {
      title: 'Galdacano',
      text: ['Martes de 11.30 a 16.00']
    },
    {
      title: 'Jinámar',
      text: ['Martes de 12.00 a 16.00']
    },
    {
      title: 'Leganés',
      text: ['Miércoles de 11.30 a 17.30']
    },
    {
      title: 'Lleida',
      text: ['Jueves de 12.30 a 16.30']
    },
    {
      title: 'Lugo',
      text: ['Lunes de 12.00 a 16.00']
    },
    {
      title: 'Majadahonda',
      text: ['Martes y Jueves de 11.30 a 15.30']
    },
    {
      title: 'Malaga',
      text: ['Lunes, Miércoles y Viernes de 9.30 a 13.00']
    },
    {
      title: 'Massanassa',
      text: ['Miércoles de 12.30 a 16.30 y Viernes de 09.00 a 13.00']
    },
    {
      title: 'Murcia',
      text: ['Miércoles de 12.00 a 16.30']
    },
    {
      title: 'Palma',
      text: ['Miércoles de 12.00 a 16.00']
    },
    {
      title: 'Rivas',
      text: ['Viernes de 12.00 a 16.00']
    },
    {
      title: 'Salamanca',
      text: ['Miércoles de 12.30 a 16.30']
    },
    {
      title: 'Santander',
      text: ['Jueves de 12.30 a 16.30']
    },
    {
      title: 'Santiago',
      text: ['Martes de 11:30 a 15:30']
    },
    {
      title: 'Sant Quirze',
      text: ['Lunes y Martes de 12:00 a 16:30']
    },
    {
      title: 'Sestao',
      text: ['Miércoles de 11.30 a 16.00']
    },
    {
      title: 'Siero',
      text: ['Miércoles de 12.30 a 16.30']
    },
    {
      title: 'Terrasa',
      text: ['Miércoles de 12:00 a 16:30']
    },
    {
      title: 'Usera',
      text: ['Martes y Jueves de 12.30 a 16.30']
    },
    {
      title: 'Valladolid',
      text: ['Jueves de 12.00 a 16.00']
    },
    {
      title: 'Vícar',
      text: ['Viernes de 12.00 a 16.00']
    },
    {
      title: 'Zaragoza',
      text: ['Miércoles de 12:00 a 16:30']
    }
  ]
}
