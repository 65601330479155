import { Box, Button } from '@mui/material'
import React from 'react'
import { labels } from 'src/labels/main_labels'
import { ReactComponent as ExportData } from 'src/assets/icons/general/ExportData.svg'

interface FixedInputProps {
  onClick: () => void
  open: boolean
}

export default function ButtonExportData({ onClick, open }: FixedInputProps): JSX.Element {
  return (
    <Box sx={{ px: 2 }}>
      <Button
        sx={{ mb: 2, width: '100%', color: '#4B5563', backgroundColor: '#fff', borderRadius: '6px', '& .MuiButton-startIcon': { mr: 0 } }}
        startIcon={<ExportData />}
        variant="text"
        onClick={onClick}
      >
        {open && labels.es.exportData}
      </Button>
    </Box>
  )
}
