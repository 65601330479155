import { languages } from 'src/constants/const'

export const translateGenerator = (translationKeys: string[]): any[] => {
  let translation: any[] = []
  // && !translations.map((x) => x.language).includes(lang.value)
  const obj: Record<string, string> = {}
  for (const key of translationKeys) {
    obj[key] = ''
  }
  languages.forEach((lang) => {
    if (lang.value !== 'es') translation = [...translation, { language: lang.value, ...obj }]
  })
  return translation
}

export const stringAvatar = (name: string, surname?: string): Record<string, any> => {
  let text = ''
  if (!name && !surname) text = ''
  else if (surname) text = `${name.charAt(0) || ''}${surname.charAt(0) || ''}`
  else if (name.length <= 1) text = `${name.charAt(0) || ''}`
  else text = `${name.charAt(0)}${name.split(' ')[1] ? name.split(' ')[1][0] : ''}`

  if (text) text = text.toUpperCase()

  return {
    sx: { backgroundColor: '#6B7280', fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 400, fontSize: '16px', lineHeight: '16px' },
    children: text
  }
}
