import { styled, Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { DRAWER_WIDTH_OPEN, DRAWER_WIDTH_CLOSE, FOOTER_BAR_HEIGHT, HEADER_BAR_HEIGHT } from 'src/constants/const'
import { Chip, Typography, List, Box } from '@mui/material'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

export const Main = styled('main')(() => ({
  flexGrow: 1,
  width: '100%'
}))

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH_OPEN,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  [theme.breakpoints.down('sm')]: {
    position: 'fixed'
  }
})

const closedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  width: DRAWER_WIDTH_CLOSE,
  [theme.breakpoints.up('sm')]: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  [theme.breakpoints.down('sm')]: {
    // width: `calc(${theme.spacing(9)} + 1px)`,
    width: '0px'
  }
})

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  [theme.breakpoints.up('sm')]: {
    minHeight: HEADER_BAR_HEIGHT,
    width: '100%'
  },
  [theme.breakpoints.down('sm')]: {
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  }
}))

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.breakpoints.down('sm') ? 900 : theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  backgroundColor: '#ffffff',
  color: '#000000',
  width: `calc(100% - ${DRAWER_WIDTH_CLOSE}px)`,
  [theme.breakpoints.down('sm')]: {
    // width: `calc(${theme.spacing(9)} + 1px)`,
    width: '100%'
  },
  ...(open && {
    marginLeft: DRAWER_WIDTH_OPEN,
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  // backgroundColor: '#F8F8F8',
  width: DRAWER_WIDTH_OPEN,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  zIndex: 999,
  '& .MuiPaper-root': {
    // backgroundColor: '#F8F8F8'
  },
  '& .MuiListItemText-root': {
    margin: '0px'
  },

  '& .MuiTypography-root': { whiteSpace: 'pre-wrap', lineHeight: '14px' },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
    '& .MuiIcon-root': {
      marginLeft: '10px',
      marginRight: '15px'
    },
    '& .MuiListItem-root': {
      margin: '0px 10px',
      borderRadius: '6px',
      paddingTop: '7px',
      paddingBottom: '7px',
      width: 'inherit'
    },
    '& .MuiTypography-root': {
      textAlign: 'left'
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
    '& .MuiListItem-root': {
      margin: '0px 10px',
      borderRadius: '6px',
      flexDirection: 'column',
      width: 'inherit',
      padding: '10px 0px 5px 0px',
      marginTop: '7px',
      '& svg': {
        marginBottom: '2px'
      },
      '& .MuiBadge-badge': {
        right: '5px',
        top: '5px'
      }
    }
  })
}))

export const DrawerNav = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  flexDirection: 'column'
}))

export const ListCustom = styled(List)(() => ({
  '& .MuiIcon-root': {
    minWidth: 'inherit',
    color: '#9CA3AF'
  },
  '& svg': { marginLeft: '10px', marginRight: '10px' },
  '& .MuiListItemText-root': {
    textAlign: 'center'
  },
  '& .MuiTypography-root': {
    fontWeight: 500
  }
}))

export const ChipHorizontal = styled(Chip)(() => ({
  marginRight: '10px',
  paddingRight: '3px',
  paddingLeft: '3px',
  height: '20px'
}))
export const NavTitle = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: '#6B7280',
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(0),
  textTransform: 'uppercase'
}))

export const BoxNavBottom = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: FOOTER_BAR_HEIGHT,
  width: '100%'
}))
