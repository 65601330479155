import { useState, useEffect } from 'react'
import firebase from '../firebase/firebase'

interface Props {
  userDataPresent: boolean
  user: any | null
}

function useAuthentication(): Props {
  const [authenticateUser, setAuthenticateUser] = useState<Props>({
    userDataPresent: false,
    user: null
  })

  useEffect(() => {
    const unsuscribe = firebase.auth.onAuthStateChanged((user: any) => {
      if (user) {
        setAuthenticateUser({ userDataPresent: true, user: user })
      } else {
        setAuthenticateUser({ userDataPresent: true, user: null })
      }
    })
    return () => unsuscribe()
  }, [])

  return authenticateUser
}

export default useAuthentication
