import React from 'react'
import './styles/customStyles.css'
import './styles/swalStyles.css'
import AlertProvider from './context/alert/alertContext'
import AppRouter from './routes/AppRouter'
import AuthProvider from './context/auth/authContext'

import HeaderProvider from './context/header/headerContext'
import InfoProvider from './context/info/infoContext'
// import ErrorBoundary from './components/ErrorBoundary'






export default function App(): JSX.Element {
  return (
    // <ErrorBoundary>
    <AlertProvider>
      <AuthProvider>
        <HeaderProvider>
          <InfoProvider>
 
            <AppRouter />
          
          </InfoProvider>
        </HeaderProvider>
      </AuthProvider>
    </AlertProvider>
    // </ErrorBoundary>
  )
}
