import React, { FC } from 'react'
import { ListItem, ListItemText } from '@mui/material'

interface NavElementsProps {
  navLink: (link: string) => void
  open: boolean
  link: string
  Icon: any
  text: string
  badge?: boolean
}

export default function NavElements({ link, Icon, text, navLink }: NavElementsProps): JSX.Element {
  const path = location.pathname === '/' ? location.hash.split('/')[1] : location.pathname.split('/')[1]

  const active = path === link.split('/')[1]

  return (
    <ListItem
      sx={{ backgroundColor: active ? '#e5f2fa' : '', '& .MuiSvgIcon-root': { color: '#9aa3b0' } }}
      button
      disablePadding
      onClick={() => navLink(link as string)}
    >
      <Icon style={{ color: active ? '#244664' : '#1870c7' }} />
      <ListItemText sx={{ '& .MuiTypography-root': { color: active ? '#244664' : '#4B5563' } }} primary={text} />
    </ListItem>
  )
}
