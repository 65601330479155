export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR'

export const SHOW_HEADER = 'showHeader'
export const SET_OPEN = 'SET_OPEN'
export const FETCH_RESTAURANT = 'FETCH_RESTAURANT'
export const REMOVE_RESTAURANT = 'REMOVE_RESTAURANT'
export const FETCH_NEIGHBORHOOD = 'FETCH_NEIGHBORHOOD'

export const BOOKING_NUMBER = 'BOOKING_NUMBER'
