import React, { useContext, useEffect, useState } from 'react'
import { IconButton, LinearProgress, Stack, Typography, Button } from '@mui/material'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { labels } from 'src/labels/main_labels'
import { useParams, useHistory } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { grey } from '@mui/material/colors'
import { getIssueData } from 'src/services/issues.services'
import { ContainerFud } from 'src/styles/general-styles'
import TextInput from 'src/components/Form/TextInput'
import { dateTimeConvert } from 'src/utils/time-helpers'
import { saveAs } from 'file-saver'
import { Document, Page, Text, View, StyleSheet, pdf, Image } from '@react-pdf/renderer'

// rename helper for react18 overload
const MyDocument: any = Document
const MyPage: any = Page

// Create styles for PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  text: {
    fontSize: 14,
    fontFamily: 'Courier',
    fontWeight: 'normal'
  }
})

export default function IssuesDetail(): JSX.Element {
  const { idIncidencia } = useParams<{ idIncidencia: string }>()
  const history = useHistory()

  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const [data, setData] = useState<any | null>(null)
  // const [campaign, setCampaign] = useState<any | null>(null)
  // const [incident, setIncident] = useState<any | null>(null)
  const [loading, setLoading] = useState(false)

  const MyDocumentPdf = (data: any) => (
    <MyDocument>
      <MyPage size="A4" style={styles.page} orientation={'portrait'}>
        <View style={styles.section}>
          <Text style={styles.text}>Compañia: {data?.values.company.name}</Text>
          <Text style={styles.text}>
            {labels.es.store}: {data?.values.store.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.patient}: {data?.values?.patient.name}
          </Text>
          {data.values?.bookingReason && (
            <Text style={styles.text}>
              {labels.es.bookingReason}: {data?.values?.bookingReason?.name}
            </Text>
          )}
          <Text style={styles.text}>
            {labels.es.date}: {data.values.date ? dateTimeConvert(data.values.date, true) : ''}
          </Text>
          <Text style={styles.text}>
            {labels.es.timezone}: {data?.values?.hourFormatted}
          </Text>

          {data.values.campaign && !data.values.campaign.deleted && (
            <>
              <Text style={styles.text}>
                {labels.es.campaignName}: {data?.values?.campaign.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.userResponsable}: {data?.values?.campaign.all.userResponsable.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.description}: {data?.values?.campaign.all.description}
              </Text>
              <Text style={styles.text}>
                {labels.es.sector}: {data?.values?.campaign.all.sector.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.process}: {data?.values?.campaign.all.process.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.product}: {data?.values?.campaign.all.product}
              </Text>
              <Text style={styles.text}>
                {labels.es.machine}: {data?.values?.campaign.all.machine?.name}
              </Text>
            </>
          )}


          {data.values.affectedZone && (
            <Text style={styles.text}>
              {labels.es.affectedZone}: {data?.values?.affectedZone.name}
            </Text>
          )}
          <Text style={styles.text}>
            {labels.es.notes}: {data?.values?.notes}
          </Text>
          <Text style={styles.text}>
            Tipo: {data?.values?.issueType}
          </Text>
          {data?.values?.issueType === "Cancelación" && 
          (
            <>
          <Text style={styles.text}>
            Motivo: {data?.values?.cancelationReason}
          </Text>
           <Text style={styles.text}>
           Cancelado por: {data?.values?.cancelledBy + ' ' + data?.values?.cancelledByLastname}
         </Text>
            </>
          )
          }
          {data?.values?.issueType === "Modificación" && 
          (
<>
            <Text style={styles.text}>
            Motivo: {data?.values?.modifyReason}
          </Text>
          <Text style={styles.text}>
           Editado por: {data?.values?.editedBy + ' ' + data?.values?.editedByLastname}
         </Text>
           <Text style={styles.text}>
           Nueva fecha: {data?.values?.newDate}
         </Text>
           <Text style={styles.text}>
           Nueva hora: {data?.values?.newHour}
         </Text>
</>
            )
          }
          {/* <Text style={styles.text}>Firmado: {data.values.signImg ? 'Si' : 'No'}</Text> */}
        </View>
        <Image
          style={{ position: 'absolute', bottom: 20, right: 0, height: 200, width: 200 }}
          src={require('../../../assets/icons/general/MetaphysioLogo.png')}
          cache={false}
        />
      </MyPage>
    </MyDocument>
  )

  const Header = () => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <IconButton sx={{ ml: '-14px' }} onClick={() => history.goBack()}>
          <ChevronLeftIcon sx={{ color: grey[500] }} />
        </IconButton>

        <Typography variant="subtitle1" component="h1">
          Detalle Incidencia
        </Typography>
        <div />
      </Stack>
    )
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const resp = await getIssueData(idIncidencia)
     
      setData(resp)
      // if (resp.campaign && resp.campaign.id) {
      //   const respCampaign = await getCampaingData(resp.campaign.id)
      //   setCampaign(respCampaign)
      // }
      // if (resp.incident && resp.incident.id) {
      //   const respIncident = await getIncidentData(resp.incident.id)
      //   setIncident(respIncident)
      // }
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()

    showHeader(Header)
  }, [])

  return (
    <>
      {loading && <LinearProgress />}
      {data && (
        <ContainerFud>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              size="small"
              variant="outlined"
              // className={classes.newOrderButton}
              style={{ marginBottom: 10 }}
              onClick={async () => {
                const doc = <MyDocumentPdf values={data} />
                const asPdf = pdf() // {} is important, throws without an argument
                asPdf.updateContainer(doc)
                const blob = await asPdf.toBlob()
                saveAs(blob, `Cita#${data && data.bookingId}.pdf`)
              }}
            >
              Descargar PDF
            </Button>
          </div>
          {/* <TextInput noTopBorder disabled value={data.bookingId || ''} name="bookingId" text={labels.es.bookingId} placeholder={''} /> */}
          {data.company && <TextInput disabled value={data.company.name || ''} name="company" text={labels.es.company} placeholder={''} />}
          {data.store && <TextInput disabled value={data.store.name || ''} name="store" text={labels.es.store} placeholder={''} />}
          <TextInput disabled value={data.patient ? data.patient.name : ''} name="patient" text={labels.es.patient} placeholder={''} />
          {data.bookingReason && (
            <TextInput disabled value={data.bookingReason.name} name="patient" text={labels.es.bookingReason} placeholder={''} />
          )}
          {data.affectedZone && data.bookingReason.id === 'Patología Común' && (
            <TextInput disabled value={data.affectedZone.name} name="patient" text={labels.es.affectedZone} placeholder={''} />
          )}

          <TextInput
            disabled
            value={data.date ? dateTimeConvert(data.date, true) : ''}
            name="date"
            text={labels.es.date}
            placeholder={''}
          />
          <TextInput disabled value={data.hourFormatted || ''} name="hourFormatted" text={labels.es.timezone} placeholder={''} />
          <TextInput disabled value={data.issueType} name="issueType" text={'Tipo'} placeholder={''} />
          {data.issueType === "Cancelación" && (
            <>
        
         <TextInput disabled value={data.cancelationReason} name="cancelationReason" text={'Motivo'} placeholder={''} />

          <TextInput disabled value={data?.cancelledBy + ' ' + data?.cancelledByLastname} name="cancelationReason" text={'Cancelado por'} placeholder={''} />
            </>
          )}
          {data.issueType === "Modificación" && (
<>
                        <TextInput disabled value={data?.modifyReason || ''} name="motivo" text={'Motivo'} placeholder={''} />
                        <TextInput disabled value={data?.editedBy + ' ' + data?.editedByLastname} name="cancelationReason" text={'Editado por'} placeholder={''} />

                        <TextInput disabled value={data?.newDate || ''} name="nuevaFecha" text={'Nueva fecha:'} placeholder={''} />
                        <TextInput disabled value={data?.newHour || ''} name="nuevaHora" text={'Nueva hora'} placeholder={''} />
</>
)}



          
          <TextInput disabled value={data.notes || ''} name="notes" text={labels.es.notes} placeholder={''} />
          
        </ContainerFud>
      )}
    </>
  )
}
