import React from 'react'

import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Stack, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
interface SelectProps {
  handleChange: (ev: SelectChangeEvent<string>) => void
  data: string
  name: string
  text?: string
  options: Array<{ value: any; label: string }>
  minWidth?: number
}

export default function SimpleSelect({ data, name, text, handleChange, options, minWidth }: SelectProps): JSX.Element {
  return (
    <Stack sx={{ minWidth: minWidth || 70, alignItems: 'center' }} direction="row">
      <FormControl size="small" variant="outlined">
        <Select
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#cbe3fc'
            },
            '& .MuiSelect-select': { pl: '5px', pr: 0, color: '#111827' },
            minWidth: minWidth || 70,
            height: 40,
            borderRadius: '6px',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)'
          }}
          value={data}
          name={name}
          onChange={handleChange}
          displayEmpty
          IconComponent={KeyboardArrowDownIcon}
        >
          {options &&
            options.map((option, idx) => (
              <MenuItem key={idx} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        {text && <Typography sx={{ position: 'absolute', left: '30px', bottom: '9px' }}>{text}</Typography>}
      </FormControl>
    </Stack>
  )
}
