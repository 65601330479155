import React, { createContext, useEffect, useState, useContext } from 'react'

import { AlertContext } from '../alert/alertContext'
import { AuthContext } from '../auth/authContext'
import { getAllCompanies, getAllCompanyConfig } from 'src/services/companies.services'
import { getAllStores } from 'src/services/stores.services'
import { getAllPathologies } from 'src/services/pathologies.services'
export interface InfoContextProps {
  processes: Record<string, any>[]
  sectors: Record<string, any>[]
  areas: Record<string, any>[]
  machines: Record<string, any>[]
  pathologies: Record<string, any>[]
  roles: Record<string, any>[]
  allCompanies: Record<string, any>[]
  allStores: Record<string, any>[]
  loadingData: boolean
  loadingPathologies: boolean
  loadingCompanies: boolean
  loadingStores: boolean
  fetchCompanyConfigData: (companyId?: string) => void
  fetchPathologies: (companyId?: string) => void
  fetchCompanies: () => void
  fetchStores: () => void
}

export const InfoContext = createContext({} as InfoContextProps)

export const InfoProvider = ({ children }: any) => {
  const { showSnackbar } = useContext(AlertContext)
  const { userData } = useContext(AuthContext)

  const [processes, setProcesses] = useState([])
  const [sectors, setSectors] = useState([])
  const [areas, setAreas] = useState([])
  const [machines, setMachines] = useState([])
  const [pathologies, setPathologies] = useState([])
  const [roles, setRoles] = useState([])
  const [loadingData, setLoadingData] = useState(false)
  const [loadingCompanies, setLoadingCompanies] = useState(false)
  const [loadingPathologies, setLoadingPathologies] = useState(false)
  const [allCompanies, setAllCompanies] = useState([])
  const [allStores, setAllStores] = useState([])
  const [loadingStores, setLoadingStores] = useState(false)

  const fetchCompanyConfigData = async (companyId?: string) => {
    if (!userData.companyId && !companyId) return
    try {
      setLoadingData(true)
      const data = await getAllCompanyConfig(companyId || userData.companyId)
      setProcesses(data.filter((elm: { type: string; deleted: boolean }) => elm.type === 'processes' && !elm.deleted))
      setSectors(data.filter((elm: { type: string; deleted: boolean }) => elm.type === 'sectors' && !elm.deleted))
      setAreas(data.filter((elm: { type: string; deleted: boolean }) => elm.type === 'areas' && !elm.deleted))
      setMachines(data.filter((elm: { type: string; deleted: boolean }) => elm.type === 'machines' && !elm.deleted))
      setRoles(data.filter((elm: { type: string; deleted: boolean }) => elm.type === 'roles' && !elm.deleted))
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingData(false)
    }
  }

  const fetchCompanies = async () => {
    try {
      setLoadingCompanies(true)
      const companies = await getAllCompanies()
      if (companies && companies.length)
        setAllCompanies(companies.sort((a: any, b: any) => a.name.localeCompare(b.name)).filter((elm: any) => !elm.deleted))
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingCompanies(false)
    }
  }

  const fetchStores = async () => {
    try {
      setLoadingStores(true)
      const stores = await getAllStores()

      if (stores && stores.length)
        setAllStores(stores.sort((a: any, b: any) => a.storeName.localeCompare(b.storeName)).filter((elm: any) => !elm.deleted))
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingStores(false)
    }
  }

  const fetchPathologies = async (companyId?: string) => {
    try {
      if (companyId || userData.companyId) {
        setLoadingPathologies(true)
        const data = await getAllPathologies(companyId || userData.companyId)
        setPathologies(data)
      }
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingPathologies(false)
    }
  }

  useEffect(() => {
    if (userData?.companyId) fetchCompanyConfigData()
    if (userData?.companyId) fetchPathologies()

    fetchCompanies()
    fetchStores()
  }, [userData])

  return (
    <InfoContext.Provider
      value={{
        processes,
        sectors,
        areas,
        machines,
        pathologies,
        roles,
        allCompanies,
        allStores,
        loadingData,
        loadingCompanies,
        loadingStores,
        fetchCompanyConfigData,
        fetchPathologies,
        fetchCompanies,
        fetchStores,
        loadingPathologies
      }}
    >
      {children}
    </InfoContext.Provider>
  )
}

export default InfoProvider
