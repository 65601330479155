import React, { useContext, useEffect, useState } from 'react'
import firebase from 'src/firebase/firebase'
import { Box, IconButton, Stack, Typography } from '@mui/material'

import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext'
import { InfoContext } from 'src/context/info/infoContext'
import { AlertContext } from 'src/context/alert/alertContext'

import { labels } from 'src/labels/main_labels'
import CalendarTemplate from 'src/components/Calendar/ScheduleCalendar'
import SimpleSelect from 'src/components/Form/Select'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'



export default function Calendars(): JSX.Element {
  const { showSnackbar } = useContext(AlertContext)
  const { userData, setUserData } = useContext(AuthContext)
  const { allCompanies, allStores } = useContext(InfoContext)
  const [loading, setLoading] = useState(false)
  const { showHeader } = useContext(HeaderContext)
  const [availability, setAvailability] = useState<any[] | null>(null)
  const [saving, setSaving] = useState(false)

  // Filter for Admin
  const [filterCompany, setFilterCompany] = useState<string>('')
  const [filterStore, setFilterStore] = useState<any | never>(null)
  const [filterStoreOptions, setFilterStoreOptions] = useState<any[] | never>([])

  // const fetchPhysios = async (storeId: string) => {
  //   try {
  //     const resp = await getStorePhysio(storeId)
  //     if (resp.length) {
  //       const filterFisio = resp.filter((fisio: any) => !fisio.deleted)
  //       setPhysio(filterFisio[0])
  //       setAvailability(resp[0].calendar)
  //     } else {
  //       setFilterStore('')
  //       showSnackbar('error', 'No existe fisio para esta tienda')
  //     }
  //   } catch (error: any) {
  //     showSnackbar('error', error.message)
  //   }
  // }

  const fetchStoreCalendar = async (storeId: string) => {
 
    // if (allStores) {
    //   try {
    //     // setAvailability(null)
    //     setLoading(true)
    //     const storeSelected = allStores.find((store) => store.id === storeId)
    //     if (storeSelected && storeSelected.calendar.length >= 0) {
    //       // setAvailability(null)
    //       setAvailability(storeSelected.calendar)
    //       setLoading(false)
    //     } else if (storeSelected && !storeSelected.calendar) {
    //       setFilterStore('')
    //       showSnackbar('error', 'No existe calendario para esta tienda')
    //       setLoading(false)
    //     }
    //   } catch (error) {
    //     setLoading(false)

    //     showSnackbar('error', 'Error al cargar el calendario de la tienda')
    //   }
    // }
    try {
      await firebase.db.collection("stores").where("id", "==", storeId)
      .onSnapshot((querySnapshot:any) => {
      
          const store: any[] = [];
          querySnapshot.forEach((doc:any) => {
            if (doc.data().calendar.length >= 0) {
                    // setAvailability(null)
                    setAvailability(doc.data().calendar)
                    showSnackbar('success', 'Datos del calendario cargados')

                    setLoading(false)
                  } else if (!doc.data().storeSelected.calendar) {
                    setFilterStore('')
                    showSnackbar('error', 'No existe calendario para esta tienda')
                    setLoading(false)
                  }
          });
        })
      
    } catch (error) {
      setLoading(false)
      showSnackbar('error', 'Error al cargar el calendario de la tienda')
    }
      

  }

  // useEffect(() => {
  //   if (filterStore) {
  //     fetchStoreCalendar(filterStore)
  //   } else {
  //     setAvailability(null)
  //   }
  // }, [filterStore])

  useEffect(() => {
    if (userData.role?.id === 'role1') setAvailability(null)
    // else setAvailability([])
  }, [userData])

  const saveNewAvailability = async (newAvailability: any) => {
    setSaving(true)
    
    try {
      setUserData({ ...userData, calendar: newAvailability })
      await firebase.db.collection('stores').doc(filterStore).update({ calendar: newAvailability })
      showSnackbar('success', labels.es.availabilityUpdated)
    } catch (error: any) {
      showSnackbar('error', error.message)
    } finally {
      setSaving(false)
    }
  }

  const Header = () => {
    return (
      <div className="flex-center">
        <Typography variant="h3" component="h1">
          {labels.es.calendar}
        </Typography>
      </div>
    )
  }

  useEffect(() => {
    showHeader(Header)
  }, [])

  const filterCompanyOptions = [{ id: '', name: labels.es.filterByCompany }, ...allCompanies].map((elm) => ({
    value: elm.id,
    label: elm.name
  }))

  useEffect(() => {
    setFilterStore('')
    const loadfilterStoreOptions = allStores
      .filter((elm) => elm.companyId === filterCompany)
      .map((elm) => ({
        value: elm.id,
        label: elm.storeName
      }))

    setFilterStoreOptions([{ value: '', label: labels.es.filterByStore }, ...loadfilterStoreOptions])
  }, [filterCompany])

  const cleanFilters = () => {
    setFilterCompany('')
    setFilterStore('')
  }

  return (
    <>
      {userData.role?.id === 'role1' ? (
        <>
          <Stack sx={{ mt: 2 }} direction="row" justifyContent="center">
            <SimpleSelect
              minWidth={200}
              name="filterCompany"
              data={filterCompany}
              handleChange={(e) => setFilterCompany(e.target.value)}
              options={filterCompanyOptions}
            />
            {filterStoreOptions && filterCompany && (
              <Box sx={{ ml: 2 }}>
                <SimpleSelect
                  minWidth={200}
                  name="filterStore"
                  data={filterStore}
                  handleChange={(e) => {
                    
                    setFilterStore(e.target.value)
                    fetchStoreCalendar(e.target.value)
                  }}
                  options={filterStoreOptions}
                />
              </Box>
            )}
            <IconButton sx={{ mx: 2 }} color="primary" onClick={cleanFilters}>
              <FilterAltOffIcon />
            </IconButton>
          </Stack>
          {/* {filterStore && availability && (
            <Stack sx={{ mt: 1 }} direction="row" justifyContent="center" alignItems="center">
              <Typography variant="subtitle1" component="strong">
                Tienda seleccionado:
              </Typography>
              <Typography sx={{ ml: 1 }} variant="subtitle2" component="strong">
                {filterStore} {availability.length}
              </Typography>
            </Stack>
          )} */}
        </>
      ) : (
        userData.role.id === 'role2' && (
          <>
            <Stack sx={{ mt: 2 }} direction="row" justifyContent="center">
              {userData?.storesWorking && (
                <Box sx={{ ml: 2 }}>
                  <SimpleSelect
                    minWidth={200}
                    name="filterStore"
                    data={filterStore}
                    handleChange={(e) => {
                    
                      setFilterStore(e.target.value)
                      fetchStoreCalendar(e.target.value)
                    }}
                    options={[{ id: '', name: labels.es.filterByStore }, ...userData.storesWorking].map((elm: any) => ({
                      value: elm.id,
                      label: elm.name
                    }))}
                  />
                </Box>
              )}

              {/* <IconButton sx={{ mx: 2 }} color="primary" onClick={cleanFilters}>
                <FilterAltOffIcon />
              </IconButton> */}
            </Stack>
            {/* {filterStore && availability && (
              <Stack sx={{ mt: 1 }} direction="row" justifyContent="center" alignItems="center">
                <Typography variant="subtitle1" component="strong">
                  Tienda seleccionado:
                </Typography>
                <Typography sx={{ ml: 1 }} variant="subtitle2" component="strong">
                  {filterStore} {availability.length}
                </Typography>
              </Stack>
            )} */}
          </>
        )
      )}

      {availability && filterStore && !loading && (
        <CalendarTemplate
          availability={availability}
          saving={saving}
          setAvailability={(update: any) => {
            saveNewAvailability(update)
            setAvailability(update)
          }}
        />
      )}
    </>
  )
}
