import { styled } from '@mui/material/styles'
import { DialogTitle, Dialog } from '@mui/material'
import { grey } from '@mui/material/colors'

export const ModalTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 500,
  fontStyle: 'normal',
  lineHeight: '24px',
  letterSpacing: '0em',
  margin: theme.spacing(0),

  textAlign: 'left',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#111827',
  '& .MuiIconButton-root': {
    color: grey[500]
  }
}))

export const ModalDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2)
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiPaper-root': { margin: '10px', width: '100%' }
  }
}))
