import { EmailAuthProvider, getAuth, reauthenticateWithCredential } from 'firebase/auth'

export const reauthenticate = async (email: string, password: string): Promise<any> => {
  try {
    const auth = getAuth()
    if (auth && auth.currentUser) {
      const credential = EmailAuthProvider.credential(email, password)
      return await reauthenticateWithCredential(auth?.currentUser, credential)
    }
  } catch (error) {
    return error
  }
}
