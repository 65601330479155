import { styled, Box, Avatar } from '@mui/material'
import { CONTAINER_WIDTH_DEFAULT } from '../constants/const'

export const BoxGrey = styled(Box)(({ theme }) => ({
  backgroundColor: '#F3F4F6',
  width: '100%',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1)
}))

export const ContainerFud = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: CONTAINER_WIDTH_DEFAULT,
  margin: 'auto'
}))

export const AvatarResumen = styled(Avatar)(() => ({
  width: 64,
  height: 64,
  boxSizing: 'border-box',
  borderRadius: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover'
}))
