import React, { createContext, useEffect, useState, useContext } from 'react'
import firebase from 'src/firebase/firebase'
import authenticateUser from 'src/hooks/useAuthentication'
import { getUserData } from 'src/services/auth.services'
import { AlertContext } from '../alert/alertContext'
import { UserDataTypes } from '../../types/pages.types'

export interface AuthContextProps {
  user: Record<string, any>
  firebase: any
  userData: UserDataTypes
  setUserData: any
  initLoading: boolean
  logout: () => Promise<void>
}

export const AuthContext = createContext({} as AuthContextProps)

export const AuthProvider = ({ children }: any) => {
  const { showSnackbar } = useContext(AlertContext)

  const [userData, setUserData] = useState<any | null>(null)
  const user = authenticateUser()
  const [initLoading, setInitLoading] = useState(true)

  const logout = async (): Promise<void> => {
    setUserData(null)

    await firebase.logout()
  }

  useEffect(() => {
    if (user.user) {
      getUserData(user.user.uid)
        .then((result) => setUserData(result))
        .then(() => setInitLoading(false))
        .catch((error) => {
          if (error instanceof Error) showSnackbar('error', error.message)
          firebase.logout()
        })
    } else if (user && !user.user && user.userDataPresent) setInitLoading(false)
  }, [user])

  return <AuthContext.Provider value={{ user, userData, setUserData, firebase, initLoading, logout }}>{children}</AuthContext.Provider>
}

export default AuthProvider
