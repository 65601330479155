import React from 'react'
import { Button, Hidden } from '@mui/material'
import SendIcon from '@mui/icons-material/Send';


interface AddButtonProps {
  onClick: () => void
  text: string
}

export function SendButton({ onClick, text }: AddButtonProps) {
  return (
    <Button size="small" type="submit" color="primary" variant="contained" onClick={onClick} style={{backgroundColor:"white",padding:0}}>
      {/* <Hidden smDown>{text}</Hidden> */}
      <Hidden>
        <SendIcon style={{fill:'#1870c7'}} />
      </Hidden>
    </Button>
  )
}
