import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { ReactComponent as ChartHover } from 'src/assets/icons/general/ChartHover.svg'
import { Box, Stack, Typography } from '@mui/material'

import { CartesianGrid, Tooltip, BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts'

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
}

export interface ChartProps {
  title: string
  dataChart: any
}

export function Chart({ title, dataChart }: ChartProps): JSX.Element {
  const theme = useTheme()
  const [posData, setposData] = useState<Record<string, number>>({ x: 0, y: 0 })
  const [chartInfo, setChartInfo] = useState<any>(null)
  const [isToolTipActive, setIsToolTipActive] = useState(false)

  useEffect(() => {
    setChartInfo(dataChart)
  }, [dataChart])

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{ position: 'relative' }}>
          <ChartHover />
          <Typography
            sx={{ color: '#fff', position: 'absolute', top: '3px', left: '0px', width: '100%', textAlign: 'center' }}
            variant="subtitle2"
          >{`${payload[0].value}`}</Typography>
        </Box>
      )
    }
    return null
  }

  return (
    <Box sx={{ my: 2, border: '1px solid #E5F2FA', borderRadius: 2, boxSizing: 'border-box', height: '400px', py: 2 }}>
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 2, px: 4, height: '38px' }}>
        <Typography variant="subtitle1" sx={{ fontSize: '18px' }}>
          {title}
        </Typography>
      </Stack>
      <ResponsiveContainer height="90%">
        <BarChart
          width={40}
          height={40}
          margin={{
            left: 0,
            bottom: 20,
            top: 10,
            right: 40
          }}
          data={chartInfo}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Tooltip
            isAnimationActive={false}
            cursor={{ fill: 'transparent' }}
            position={{ x: posData.x + posData.width / 2 - 27, y: posData.y - 32 }}
            content={isToolTipActive ? <CustomTooltip /> : <></>}
          />
          <XAxis dataKey="month" stroke={theme.palette.text.secondary} />
          <YAxis dataKey="Sesiones" axisLine={false} stroke={theme.palette.text.secondary} />
          <Bar
            dataKey="Sesiones"
            fill="#1870c7"
            radius={[35, 35, 0, 0]}
            onMouseMove={(data: any) => {
              setposData(data)
            }}
            onMouseEnter={() => setIsToolTipActive(true)}
            onMouseOut={() => setIsToolTipActive(false)}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
