import React, { useEffect, useState } from 'react'
import { Button, Divider, IconButton, Stack, Typography, Box, Snackbar } from '@mui/material'

// ICONS
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as DeleteSnackbar } from 'src/assets/icons/general/DeleteSnackbar.svg'

import { labels } from 'src/labels/main_labels'

interface TableSnackbarProps {
  children?: React.ReactNode
  selected: string[]
  onSelectAllClick: () => void
  totalLength: number
  onDelete?: () => void
  onExport?: (selected: string[]) => void
  onDeactivateAll?: () => void
}

export default function TableSnackbar({
  selected,
  onSelectAllClick,
  totalLength,
  onDelete,
  onExport,
  onDeactivateAll
}: TableSnackbarProps): JSX.Element {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  useEffect(() => {
    if (selected.length > 0) handleClick()
    else setOpen(false)
  }, [selected])

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open}>
      <Box sx={{ width: 600, maxWidth: '100%', backgroundColor: '#374151', p: 2, color: '#ffffff' }}>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon sx={{ fontSize: '18px', color: '#fff' }} />
          </IconButton>
          <Typography variant="body2" sx={{ ml: 0.5, color: '#fff' }}>
            {selected.length} {selected.length > 1 ? labels.es.selecteds : labels.es.selected}
          </Typography>
        </Stack>
        <Divider sx={{ my: 1, borderColor: '#4B5563' }} />
        <div className="flex-between">
          <Stack direction="row" sx={{ flexWrap: 'wrap' }} spacing={{ xs: 0, md: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button onClick={onSelectAllClick}>
                <FileCopyOutlinedIcon sx={{ cursor: 'pointer', fontSize: 15, color: '#fff' }} />
                <Typography variant="body2" sx={{ ml: 0.5, color: '#fff' }}>
                  {totalLength === selected.length ? labels.es.deselectAll : labels.es.selectAll}
                </Typography>
              </Button>
            </Box>
            {onExport && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button onClick={() => onExport(selected)}>
                  <FileDownloadOutlinedIcon sx={{ cursor: 'pointer', fontSize: 15, color: '#fff' }} />
                  <Typography variant="body2" sx={{ ml: 0.5, color: '#fff' }}>
                    {labels.es.downloadqr}
                  </Typography>
                </Button>
              </Box>
            )}
            {onDeactivateAll && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button onClick={onDeactivateAll}>
                  <RemoveCircleOutlineIcon sx={{ cursor: 'pointer', fontSize: 15, color: '#fff' }} />
                  <Typography variant="body2" sx={{ ml: 0.5, color: '#fff' }}>
                    {labels.es.deactivateAll}
                  </Typography>
                </Button>
              </Box>
            )}
          </Stack>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={onDelete}>
              <DeleteSnackbar style={{ cursor: 'pointer' }} />
              <Typography variant="body2" sx={{ ml: 1, color: '#fff' }}>
                {labels.es.delete}
              </Typography>
            </Button>
          </Box>
        </div>
      </Box>
    </Snackbar>
  )
}
