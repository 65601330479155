import { HeaderState } from './headerContext'
import { SHOW_HEADER, SET_OPEN } from '../actions'
import { FC } from 'react'

type HeaderAction = { type: 'showHeader'; payload: FC } | { type: 'SET_OPEN'; payload: boolean }

export const headerReducer = (state: HeaderState, action: HeaderAction): HeaderState => {
  switch (action.type) {
    case SHOW_HEADER:
      return {
        ...state,
        header: action.payload
      }
    case SET_OPEN:
      return {
        ...state,
        open: action.payload
      }
    default:
      return state
  }
}
