import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Stack, useTheme, Box, Alert, IconButton } from '@mui/material'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import SearchInput from 'src/components/SearchInput'

import TablePag from 'src/components/Table/TablePag'

import deleteElements from 'src/services/deleteElements'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { labels } from 'src/labels/main_labels'
import { getAllCampaignsByCompany, deleteCampaing, getAllCampaignsByCompanyAndStore } from 'src/services/campaigns.services'
import { AddButton } from 'src/components/Buttons/AddButton'
import CampaignsAddEdit from './CampaignsAddEdit'
import SimpleSelect from 'src/components/Form/Select'
import { InfoContext } from 'src/context/info/infoContext'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import { useHistory } from 'react-router-dom'
import NoResultsLogo from '../../../components/NoResultsLogo/NoResultsLogo'
// import { seedDatabase } from '../../../scripts/seed'
import firebase from 'src/firebase/firebase'
// import users from '../../../scripts/localization.json'

const headCells: any[] = [
  {
    idField: 'campaignId',
    align: 'left',
    disablePadding: true,
    label: 'ID',
    // order: true,
    fieldtype: 'idCampaign'
  },
  {
    idField: 'sector',
    align: 'left',
    disablePadding: true,
    label: labels.es.sector,
    // order: true,
    fieldtype: 'string'
  },
  {
    idField: 'process',
    align: 'left',
    disablePadding: true,
    label: labels.es.process,
    // order: true,
    fieldtype: 'string'
  },
  {
    idField: 'product',
    align: 'left',
    disablePadding: true,
    label: labels.es.product,
    // order: true,
    fieldtype: 'string'
  },

  {
    idField: 'userResponsable',
    align: 'left',
    disablePadding: true,
    label: labels.es.userResponsable,
    // order: true,
    fieldtype: 'string'
  }
]
export default function Campaigns(): JSX.Element {
  const { userData } = useContext(AuthContext)
  const theme = useTheme()
  const { showHeader } = useContext(HeaderContext)
  const { allCompanies, allStores } = useContext(InfoContext)
  const { showSnackbar } = useContext(AlertContext)
  const [data, setData] = useState<any | null>([])
  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  // const [initHeader, setInitHeader] = useState(true)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [filterCompany, setFilterCompany] = useState<string>('')
  const [filterStore, setFilterStore] = useState<string>('')
  const [filterStoreOptions, setFilterStoreOptions] = useState<any[] | never>([])
  // const [searchString, setSearchString] = useState('')

  const history = useHistory()

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }

  // PAGINATION
  const [page, setPage] = useState(0)

  interface PageData {
    [key: number]: any[]
  }

  interface State {
    totalCount: number
    lastVisible: any
    pagesData: PageData
  }

  const [state, setState] = useState<State>({
    totalCount: 0,
    lastVisible: null,
    pagesData: {}
  })

  const fetchData = async (pag?: number) => {
    if (loadingTable) return

    const pagina = pag || page
    // if (!initHeader && !searchString) setInitHeader(true)
    setLoadingTable(true)
    try {
      if (!state.pagesData[pagina]) {
        let query = firebase.db.collection('campaigns').orderBy('campaignId', 'desc').limit(25)

        // if (searchString !== '') {
        //   const searchCoded = searchString.toUpperCase()
        //   query = query.where('campaignId', '>=', searchCoded)
        // }

        if (filterCompany) {
          query = query.where('companyId', '==', `${filterCompany}`)
        }
        if (filterStore) {
          query = query.where('storeId', '==', `${filterStore}`)
        }

        if (['role4', 'role5'].includes(userData.role.id)) {
          query = query.where('storeId', '==', `${userData?.storeId}`)
          query = query.where('companyId', '==', `${userData?.companyId}`)
        }

        if (['role3'].includes(userData.role.id)) {
          query = query.where('companyId', '==', `${userData?.companyId}`)
        }

        if (state.lastVisible) {
          query = query.startAfter(state.lastVisible)
        }

        const querySnapshot = await query.get()
        const newPagesData = { ...state.pagesData }

        newPagesData[pagina] = querySnapshot.docs.map((doc: any) => doc.data())

        setData(
          data.concat(
            querySnapshot.docs.map((doc: any) => {
              return { id: doc.id, ...doc.data() }
            })
          )
        )

        setState({
          ...state,
          lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
          pagesData: newPagesData,
          totalCount: querySnapshot.size
        })
      }
    } catch (error) {
      console.log(error)
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }

  const refreshSearch = async () => {
    if (filterCompany || filterStore) {
      setData([])
      setPage(0)
      setState({ totalCount: 0, lastVisible: null, pagesData: {} })
    }
  }

  useEffect(() => {
    refreshSearch()
  }, [filterCompany, filterStore])

  useEffect(() => {
    if (!Object.keys(state.pagesData).length) {
      fetchData(0)
    }
  }, [state.pagesData])

  useEffect(() => {
    if (userData && userData.role.id) fetchData(page)
    // if (userData.role.id === 'role3' && userData.companyId) setFilterCompany(userData.companyId)
  }, [page])

  // ANTIGUO SIN PAGINACION

  // const fetchCampaignsList = async (companyId?: string) => {
  //   const compId = userData.role.id === 'role1' ? companyId : userData.companyId

  //   if (!compId) return
  //   setLoadingTable(true)
  //   try {
  //     if (userData.role.id === 'role1' || userData.role.id === 'role3') {

  //       const resp = await getAllCampaignsByCompany(compId)

  //       const data = resp.filter((row: any) => row.deleted !== true)

  //       setData(data)
  //       setRowsData(data)
  //     } else {
  //       const resp = await getAllCampaignsByCompanyAndStore(userData.companyId, userData.storeId)
  //       const data = resp.filter((row: any) => row.deleted !== true)
  //       setData(data)
  //       setRowsData(data)
  //     }
  //   } catch (error) {
  //     if (error instanceof Error) showSnackbar('error', error.message)
  //   } finally {
  //     setLoadingTable(false)
  //   }
  // }

  // const handleSearch = async (word: string) => {
  //   if (!word) {
  //     if (data) setRowsData(data)
  //   } else {
  //     if (data) setRowsData(data.filter((campaign: any) => campaign.name.toLowerCase().includes(word.toLowerCase())))
  //   }
  // }

  const Header = () => {
    return (
      <Stack direction="row" justifyContent="flex-end">
        {/* <SearchInput placeholder={labels.es.searchCampaigns} name="search" handleSubmit={setSearchString} clean fullwidth /> */}
        <AddButton onClick={() => setOpenModal(true)} text={labels.es.addCampaigns} />
      </Stack>
    )
  }

  useEffect(() => {
    setFilterStore('')
    const loadfilterStoreOptions = allStores
      .filter((elm) => elm.companyId === filterCompany)
      .map((elm) => ({
        value: elm.id,
        label: elm.storeName
      }))

    setFilterStoreOptions([{ value: '', label: labels.es.filterByStore }, ...loadfilterStoreOptions])
  }, [filterCompany])

  useEffect(() => {
    // Cargamos filtros de tiendas para hr
    if (userData.role.id === 'role3') {
      const loadfilterStoreOptions = allStores
        .filter((elm) => elm.companyId === userData.companyId)
        .map((elm) => ({
          value: elm.id,
          label: elm.storeName
        }))

      setFilterStoreOptions([{ value: '', label: labels.es.filterByStore }, ...loadfilterStoreOptions])
    }
  }, [])

  // const fetchStoreCampaigns = async () => {
  //   setLoadingTable(true)
  //   try {
  //     const storeCampaigns = await getAllCampaignsByCompanyAndStore(filterCompany || userData.companyId, filterStore)

  //     if (storeCampaigns) setRowsData(storeCampaigns.filter((elm:any) => elm.deleted !== true))
  //     setLoadingTable(false)
  //   } catch (error) {
  //     setLoadingTable(false)
  //   }
  // }

  // useEffect(() => {
  //   if (filterStore) fetchStoreCampaigns()
  // }, [filterStore])

  // const updateLastname = async () => {
  //   try {
  //     for await (const [key, employee] of users.entries()) {
  //     const userRetreived = await firebase.db.collection('users').where('email','==',employee.email)
  //     .get().then((querySnapshot) => {
  //       querySnapshot.docs.map(async (doc) => {
  //         const allUserData = doc.data()
  //         // console.log('fullname:',allUserData?.name+" "+employee.apellido +' '+ allUserData?.lastname2)
  //         await firebase.db.collection('users').doc(doc.id).update({'fullName':allUserData?.name+' '+employee.apellido +' '+ allUserData?.lastname2}).then(()=> console.log("updated"))

  //       })

  //     })

  //   }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  //   const fixDates = async () => {
  //   try {
  //     for await (const [key, employee] of users.entries()) {
  //     const userRetreived = await firebase.db.collection('users').where('email','==',employee.email)
  //     .get().then((querySnapshot) => {
  //       querySnapshot.docs.map(async (doc) => {
  //         const allUserData = doc.data()
  //         const nacimiento= allUserData.workingSince
  //         const antiguedad = allUserData.birthday
  //         // console.log('fullname:',allUserData?.name+" "+employee.apellido +' '+ allUserData?.lastname2)
  //         await firebase.db.collection('users').doc(doc.id).update({'birthday': nacimiento, workingSince: antiguedad} ).then(()=> console.log("updated"))

  //       })

  //     })

  //   }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const createUser = firebase.functions.httpsCallable('createUserWithUid')

  // createUser().then(()=>console.log("ok")).catch(()=> console.log('error'));

  // const extractUsersByStatus = async () => {
  //   const usersToList = await firebase.db
  //   .collection('bookings')
  //   .where('storeId', '==', '3rCmusLZdNvlWjpuoMBn')
  //   .where('completed','!=',null)
  //   .get()
  //   .then((snapshot: any) => {
  //           if (snapshot.empty) {
  //             return []
  //           }
  //           return snapshot.docs.map((doc: any) => {
  //             return { id: doc.id, ...doc.data() }
  //           })
  //         })

  //     console.log(usersToList)
  // }

  // const makeFullName = async () => {
  //   const allUsers = await firebase.db
  //     .collection('users')
  //     .get()
  //     .then((snapshot: any) => {
  //       if (snapshot.empty) {
  //         return []
  //       }
  //       return snapshot.docs.map((doc: any) => {
  //         return { id: doc.id, ...doc.data() }
  //       })
  //     })

  //   for await (const user of allUsers) {
  //     if(!user.fullName){
  //       let fullName = user?.name
  //       if (user.lastname) fullName = fullName + ' ' + user?.lastname
  //       if (user.lastname2) fullName = fullName + ' ' + user?.lastname2
  //       console.log(fullName)
  //      await firebase.db.collection('users').doc(user.id).update({ fullName: fullName })
  //     }
  //   }
  // }

  //   const makeFieldsDeleted = async () => {
  //   const allUsers = await firebase.db
  //     .collection('users')
  //     .get()
  //     .then((snapshot: any) => {
  //       if (snapshot.empty) {
  //         return []
  //       }
  //       return snapshot.docs.map((doc: any) => {
  //         return { id: doc.id, ...doc.data() }
  //       })
  //     })

  //   for await (const user of allUsers) {
  //     if(!user.deleted){
  //      await firebase.db.collection('users').doc(user.id).update({ deleted: false })
  //      console.log("added")
  //     }
  //   }
  // }

  //  const changeStoreName = async () => {
  //   const allUsers = await firebase.db
  //     .collection('users')
  //     .get()
  //     .then((snapshot: any) => {
  //       if (snapshot.empty) {
  //         return []
  //       }
  //       return snapshot.docs.map((doc: any) => {
  //         return { id: doc.id, ...doc.data() }
  //       })
  //     })

  //   for await (const user of allUsers) {

  //     if (user?.store?.name === "Obramat Galdacano"){

  //       firebase.db.collection('users').doc(user.id).update({ store: {id: user.store.id, name : 'Obramat Galdakao'} })
  //       console.log("actualizado")
  //     }

  //   }
  // }

  //  const changeRoleName = async () => {
  // const allUsers = await firebase.db
  //   .collection('users')
  //   .get()
  //   .then((snapshot: any) => {
  //     if (snapshot.empty) {
  //       return []
  //     }
  //     return snapshot.docs.map((doc: any) => {
  //       return { id: doc.id, ...doc.data() }
  //     })
  //   })

  // for await (const user of allUsers) {

  //   if (user?.role?.name === "Jefe de Sector/a"){

  //     await firebase.db.collection('users').doc(user.id).update({ role: {id: user.role.id, name : 'Jefe/a de Sector'} })
  //     console.log("actualizado")
  //   }
  // }}

  // const changeSectorName = async () => {
  // const allUsers = await firebase.db
  //   .collection('users')
  //   .get()
  //   .then((snapshot: any) => {
  //     if (snapshot.empty) {
  //       return []
  //     }
  //     return snapshot.docs.map((doc: any) => {
  //       return { id: doc.id, ...doc.data() }
  //     })
  //   })

  // for await (const user of allUsers) {

  //   if (user?.role?.length > 0 && user?.role[0]?.name === "Director/a"){

  //    await firebase.db.collection('users').doc(user.id).update({ role: {id: 'role4', name : 'Director/a'}} )
  //     console.log("actualizado director")
  //   }

  //   if (user?.role?.length > 0 && user?.role[0]?.name === "Jefe/a de Sector"){

  //     await firebase.db.collection('users').doc(user.id).update({ role: {id: 'role5', name : 'Jefe de Sector'} })
  //     console.log("actualizado jefe")
  //    }

  //  if (user?.sector?.length > 0 && user?.sector[0]?.name === "Gran Obra"){

  //   await firebase.db.collection('users').doc(user.id).update({ sector: [{id: 'vHOMpNkQ6LkoiGkDlxpm', name : 'Materiales'},{id: 'FCkdSAENU5Q2i6A8tKNo', name : 'Madera'}] })
  //   console.log("actualizado Gran")
  //  }

  //  if (user?.sector?.length > 0 && user?.sector[0]?.name === "Equipamiento Técnico"){

  //   await firebase.db.collection('users').doc(user.id).update({ sector: [{id: 'MQeLFadhUVGlptCRfL9e', name : 'Electricidad'},{id: 'WG866trq2pXZqTt7uT6m', name : 'Herramientas'},{id: 'smA5rPnhv0csOsn7qwF2', name : 'Iluminación'},{id: 'H2Rqqr8k6mPFqUaQttiG', name : 'Ferreteria'}] })
  //   console.log("actualizado Técnico")
  //  }

  // }
  // }

  // CHECH SIZE

  //  firebase.db
  //   .collection('users')
  //   .where("store.name","==","Obramat Galdakao")
  //   .get()
  //   .then(function (querySnapshot) {
  //     console.log(querySnapshot.size)
  //   })

  // CHANGE EMPLOYEEID

  // const changeEmployeeIds = async () => {

  //   try {

  //     const allUsers = await firebase.db
  //     .collection('users')
  //     .get()
  //     .then((snapshot: any) => {
  //       if (snapshot.empty) {
  //         return []
  //       }
  //       return snapshot.docs.map((doc: any) => {
  //         return { id: doc.id, ...doc.data() }
  //       })
  //     })

  //     const noMatch: string[] = [];

  //     for await (const [key, employee] of users.entries()) {
  //       let founded = false

  //       allUsers.forEach(async (user: any)=> {
  //         if(user.email.toLowerCase() === employee?.EMAIL.toLowerCase()){
  //        founded = true
  //       // await firebase.db.collection('users').doc(user.id).update({ employeeId: employee.LDAP})
  //       // console.log("match")
  //         }
  //       })
  //       if(!founded) noMatch.push(employee.EMAIL)

  //     }
  //     console.log(noMatch)

  //   } catch (error) {
  //   console.log('error', error)
  // }

  // }

  // useEffect(() => {
  // changeRoleName()
  // changeSectorName()
  // changeStoreName()
  // seedDatabase(firebase)
  // makeFullName()
  // makeFieldsDeleted()
  // changeEmployeeIds()
  // extractUsersByStatus()
  // updateLastname()
  // fixDates()
  // if (userData) fetchData(0)
  // }, [])

  // useEffect(() => {
  //   showHeader(Header)
  // }, [data])

  // const getBookings = async () => {
  //   const dateTomorrow = new Date()
  //   const dayExtracted = dateTomorrow.getDate()
  //   const monthExtracted = dateTomorrow.getMonth()
  //   const yearExtracted = dateTomorrow.getFullYear()

  //   const bookings = await firebase.db.collection('bookings')
  //   return await bookings
  //     .where('year', '==', +yearExtracted)
  //     .where('month', '==', +monthExtracted)
  //     .where('day', '==', +dayExtracted)
  //     .get()
  //     .then((querySnapshot) => {
  //       const tempDoc = querySnapshot.docs.map((doc) => {
  //         return { id: doc.id, ...doc.data() }
  //       })

  //       return tempDoc
  //     })
  // }

  // const allBookings = getBookings()

  // console.log(allBookings)

  useEffect(() => {
    // if (!initHeader) return
    showHeader(Header)
  }, [])

  const onDelete = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels.es.campaigns, theme.palette.primary.main)).then((result) => {
      if (result.isConfirmed) {
        deleteElements(data, deleteCampaing)
          .then(() => {
            showSnackbar('success', labels.es.campaignsDeleted)
            closeModal()
            setSelected([])
            refreshSearch()
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const onEdit = async (data: Record<string, any>) => {
    // const resp = await getCompanyById(data.id)
    setEditData(data)
    setOpenModal(true)
  }

  const filterOptions = [{ id: '', name: labels.es.filterByCompany }, ...allCompanies].map((elm) => ({
    value: elm.id,
    label: elm.name
  }))

  const cleanFilters = () => {
    setFilterCompany('')
    setFilterStore('')
    refreshSearch()
  }

  const onRowClick = (row: Record<string, any>) => {
    history.push(`/campanas/${row.id}`)
  }

  return (
    <>
      <TablePag
        noCheckbox={userData.role.id !== ('role1' || 'role3')}
        headCells={headCells}
        rows={data}
        snackBar
        onDelete={onDelete}
        onEdit={onEdit}
        loading={loadingTable}
        selected={selected}
        setSelected={setSelected}
        initialOrderBy="campaignId"
        initialOrder="desc"
        onRowClick={onRowClick}
        page={page}
        setPage={setPage}
        filters={
          userData.role.id === 'role1' ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                '> div': { ml: 2, my: theme.breakpoints.down('sm') ? 0.5 : 0 },
                [theme.breakpoints.down('sm')]: {
                  overflowX: 'scroll'
                }
              }}
            >
              <SimpleSelect
                minWidth={200}
                name="filters"
                data={filterCompany}
                handleChange={(e) => setFilterCompany(e.target.value)}
                options={filterOptions}
              />
              {filterStoreOptions && filterCompany && (
                <SimpleSelect
                  minWidth={200}
                  name="filterStore"
                  data={filterStore}
                  handleChange={(e) => setFilterStore(e.target.value)}
                  options={filterStoreOptions}
                />
              )}
              <IconButton sx={{ mx: 2 }} color="primary" onClick={cleanFilters}>
                <FilterAltOffIcon />
              </IconButton>
            </Box>
          ) : userData.role.id === 'role3' ? (
            <>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  '> div': { ml: 2, my: theme.breakpoints.down('sm') ? 0.5 : 0 },
                  [theme.breakpoints.down('sm')]: {
                    overflowX: 'scroll'
                  }
                }}
              >
                {filterStoreOptions && (
                  <SimpleSelect
                    minWidth={200}
                    name="filterStore"
                    data={filterStore}
                    handleChange={(e) => setFilterStore(e.target.value)}
                    options={filterStoreOptions}
                  />
                )}
                <IconButton sx={{ mx: 2 }} color="primary" onClick={cleanFilters}>
                  <FilterAltOffIcon />
                </IconButton>
              </Box>
            </>
          ) : undefined
        }
      />
      {userData.role.id === 'role1' && !loadingTable && (!data || data.length === 0) && (
        <Alert sx={{ backgroundColor: 'transparent', justifyContent: 'center' }} severity="warning">
          {filterCompany ? 'No hay resultados disponibles' : 'Selecciona una compañia para ver resultados'}
        </Alert>
      )}
      {(!data || data.length === 0) && <NoResultsLogo />}
      <CampaignsAddEdit
        openModal={openModal}
        closeModal={closeModal}
        dataToEdit={editData}
        onDelete={onDelete}
        fetchData={() => refreshSearch()}
      />
    </>
  )
}
