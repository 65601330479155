import React, { useContext, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Accordion, AccordionSummary, AccordionDetails } from './ayuda.styles'
import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Email from '@mui/icons-material/Email'
import { grey } from '@mui/material/colors'
import { labels } from 'src/labels/main_labels'
import { faq } from 'src/labels/faq_labels'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import TextInput from 'src/components/Form/TextInput'
import { sendInquiryScheduleMessage } from 'src/services/emails.services'

export default function Ayuda(): JSX.Element {
  const { showHeader } = useContext(HeaderContext)
  const { userData } = useContext(AuthContext)
  const { showSnackbar, showAlert } = useContext(AlertContext)

  const [openModal, setOpenModal] = useState(false)
  const [question, setQuestion] = useState('')
  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [expanded, setExpanded] = React.useState<string | false>('panel1')
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const onSave = async () => {
    if (question !== '') {
      const data = {
        message: question,
        emailFrom: userData?.email,
        userFullName: userData.name + ' ' + userData.lastname + ' ' + userData.lastname2,
        store: userData.store?.name || '',
        position: userData?.role?.name || ''
      }
      try {
        await sendInquiryScheduleMessage(data)
        showSnackbar('success', labels.es.questionSended)
        setQuestion('')
        setOpenModal(false)
      } catch (error: any) {
        showSnackbar('error', error.message)
      }
    }
  }

  const Header = () => {
    return (
      // <div className="flex-between ">
        <div className="flex-center">
       
        <Typography variant="subtitle2" component="h4">
          Días de Servicio
        </Typography>
        </div>
        // <Typography variant="subtitle2" component="h4" onClick={() => setOpenModal(true)}>
         // Solicitud
          // <IconButton sx={{ ml: '0px', mb: 0.5 }}>
          //  <Email sx={{ color: '#1870c7' }} />
          // </IconButton>
        // </Typography> 
        // </div>
      
    )
  }

  useEffect(() => {
    showHeader(Header)
  }, [])

  return (
    <div>
      {faq.es.map((question: any) => (
        <Accordion key={question.title} onChange={handleChange(question.title)}>
          <AccordionSummary>
            <Typography variant="subtitle1">{question.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {question.text.map((txt: string) => (
              <Typography sx={{ mb: 1, color: '#6B7280', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }} key={txt}>
                {txt}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      <Modal
        title={'Solicitud Días de Servicio'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        footer={
          <FooterForm
            mainText
            text={labels.es.send}
            editing={false}
            closeModal={() => setOpenModal(false)}
            onSave={onSave}
            loading={loading}
            disabled={!question}
          />
        }
      >
        <TextInput
          noTopBorder
          value={question}
          handleChange={(e) => setQuestion(e.target.value)}
          name="name"
          text={labels.es.query}
          placeholder={'Escribe tu nombre y tienda junto a tu nueva solicitud de días para el servicio de fisioterapia'}
          multiline
          rows={3}
        />
      </Modal>
    </div>
  )
}
