import React from 'react'
import { Box } from '@mui/system'
import { CircularProgress } from '@mui/material'
import { ReactComponent as Logo } from 'src/assets/icons/general/metaText.svg'

export default function LoadingApp(): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw'
      }}
    >
      <Logo />
      <CircularProgress size={60} sx={{ mt: 3, color: '#1870c7' }} />
    </Box>
  )
}
