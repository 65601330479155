import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from '../../../types/pages.types'
import { useForm } from 'src/hooks/useForm'
import { AlertContext } from 'src/context/alert/alertContext'
import { labels } from 'src/labels/main_labels'
import { createStore, updateStore } from 'src/services/stores.services'

import { validateEmail } from 'src/utils/validateForm'
import SelectDialog from 'src/components/Form/SelectDialog'
import { InfoContext } from 'src/context/info/infoContext'

const INITIAL_VALUES = {
  storeName: '',
  address: '',
  email: '',
  phone: ''
}

export default function StoresAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData }: AddEditProps): JSX.Element {
  const editing = !!dataToEdit
  const { showSnackbar } = useContext(AlertContext)
  const { allCompanies, fetchStores } = useContext(InfoContext)

  const { handleChange, values, resetForm } = useForm(INITIAL_VALUES, dataToEdit)
  const [loading, setLoading] = useState(false)
  const [errorEmail, setErrorEmail] = useState<string | undefined>(undefined)
  const [company, setCompany] = useState<any | never>('')

  const resetComponent = () => {
    setLoading(false)
    resetForm()
    setErrorEmail(undefined)
    setCompany('')
  }

  useEffect(() => {
    if (dataToEdit) {
      if (dataToEdit.company) setCompany(dataToEdit.company)
    }
    return () => resetComponent()
  }, [dataToEdit])

  const onSave = async () => {
    setErrorEmail(undefined)
    if (!validateEmail(values.email)) {
      setErrorEmail(labels.es.errorEmailValidation)
    }
    setLoading(true)
    const newStore = JSON.parse(JSON.stringify(values))
    if (company) newStore.company = { id: company.id, name: company.name }
    if (company) newStore.companyId = company.id

    try {
      if (!dataToEdit) {
        newStore.createdAt = Date.now()
        newStore.updatedAt = Date.now()
        newStore.calendar = []

        await createStore(newStore)
        // if (idnewUser) history.push(`/restaurantes/${idnewUser}`)
        showSnackbar('success', labels.es.storeCreated)
      } else {
        newStore.updatedAt = Date.now()
        newStore.calendar = dataToEdit.calendar

        await updateStore(newStore, dataToEdit.id)
        showSnackbar('success', labels.es.storeUpdated)
      }
      resetComponent()
      if (fetchData) fetchData()
      fetchStores()
      closeModal()
    } catch (error: any) {
      showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit.id)
  }

  return (
    <Modal
      title={editing ? dataToEdit.storeName : labels.es.addStore}
      open={openModal}
      handleClose={closeModal}
      footer={
        <FooterForm
          text={labels.es.store}
          closeModal={closeModal}
          onSave={onSave}
          onDelete={onDelete ? handleDelete : undefined}
          editing={editing}
          loading={loading}
          disabled={!values.storeName || !values.email || !values.phone || !values.address}
        />
      }
    >
      <Box id="form-users">
        <SelectDialog text={labels.es.company} setData={setCompany} data={company} options={allCompanies} />

        <TextInput
          required
          value={values.storeName}
          handleChange={(e) => handleChange(e)}
          name="storeName"
          text={labels.es.storeName}
          placeholder={labels.es.storeName}
        />
        <TextInput
          required
          value={values.address}
          handleChange={(e) => handleChange(e)}
          name="address"
          text={labels.es.address}
          placeholder={labels.es.address}
        />
        <TextInput
          required
          value={values.email}
          handleChange={(e) => handleChange(e)}
          name="email"
          text={labels.es.email}
          placeholder={labels.es.email}
          errorBadge={errorEmail}
          type="email"
        />
        <TextInput
          required
          value={values.phone}
          handleChange={(e) => handleChange(e)}
          name="phone"
          text={labels.es.phone}
          placeholder={labels.es.phone}
          type="phone"
        />
      </Box>
    </Modal>
  )
}
