import React, { SetStateAction, Dispatch, useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { MainBox, GridFlexBetween } from './form.styles'
import { SearchDialog } from '../Modals/SearchDialog'
import { styled } from '@mui/material/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
interface SelectDialogProps {
  setData: Dispatch<SetStateAction<string | never>>
  text: string
  data: any
  options: string[] | any[]
  required?: boolean
  simple?: boolean
  roles?: boolean
  disabled?:boolean
  optionalField?:boolean
}
export const DialogBox = styled(Box)(({ theme }) => ({
  border: '1px solid #cbe3fc',
  width: '100%',
  height: 40,
  position: 'relative',
  alignItems: 'center',
  display: 'flex',
  pl: '13px',
  cursor: 'pointer',
  borderRadius: '6px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
  paddingLeft: '5px',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))
export default function SelectDialog({ text, data, setData, options, required, simple, roles,disabled,optionalField }: SelectDialogProps): JSX.Element {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const addChip = (chipData: any) => {
 
    if(optionalField && chipData?.id === data?.id) {
      setData('')
    }else 
    setData(chipData)
  }

  const getLabel = () => {
    if (data && data.name) return data.name
    else return data
  }

  return (
    <>
      {simple ? (
        <>
          <label style={{ fontSize: 11 }}>{text}</label>
          <DialogBox  onClick={() => setOpen(true)}>
            {getLabel()}
            <IconButton sx={{ position: 'absolute', right: 0, color: '#cbe3fc' }}>
              <KeyboardArrowDownIcon />
            </IconButton>
          </DialogBox>
        </>
      ) : (
        <MainBox>
          <GridFlexBetween container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle2" component="h6">
                {text} {required ? '*' : ''} {optionalField && <span style={{fontSize:10,color:'#a2a2a2'}}>(CAMPO OPCIONAL)</span>}
              </Typography>
             
            </Grid>
            <Grid item xs={8}>
              <DialogBox onClick={() => {!disabled && setOpen(true)}} style={{cursor: disabled ? 'not-allowed' : '', backgroundColor: disabled ? '#f1f5f8' : ''}}>
                {getLabel()}
                <IconButton sx={{ position: 'absolute', right: 0, color: '#cbe3fc' }}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              </DialogBox>
            </Grid>
          </GridFlexBetween>
        </MainBox>
      )}

      <SearchDialog
        single
        onClose={handleClose}
        selected={[data]}
        open={open}
        optionsData={options}
        setter={addChip}
        text={text.toLowerCase()}
        roles={roles}
      />
    </>
  )
}
