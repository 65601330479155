import firebase from 'src/firebase/firebase'

export const sendInquiryMessage = async (data: Record<string, any>): Promise<any> => {
  const sendInquiryMail = firebase.functions.httpsCallable('sendNewInquiry')

  try {
    return sendInquiryMail(data)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba enviar el formulario')
  }
}

export const sendInquiryScheduleMessage = async (data: Record<string, any>): Promise<any> => {
  const sendInquiryScheduleMail = firebase.functions.httpsCallable('sendNewScheduleInquiry')

  try {
    return sendInquiryScheduleMail(data)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba enviar la solicitud')
  }
}

export const sendNewBookingMessage = async (data: Record<string, any>): Promise<any> => {
  const sendNewBookingEmail = firebase.functions.httpsCallable('sendNewBookingEmail')

  try {
    return sendNewBookingEmail(data)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba enviar la solicitud')
  }
}
