import firebase from 'src/firebase/firebase'

export const getUserData = async (id: string): Promise<any> => {
  return firebase.db
    .collection('users')
    .doc(id)
    .get()
    .then((response) => {
      const data = response.data()
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba recuperar los datos del usuario')
    })
}

export const getAllUsers = async (): Promise<any> => {
  try {
    return await firebase.db
      .collection('users')
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba recuperar la lista de usuarios ')
  }
}

export const getAllUsersByCompany = async (companyId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('users')
      .where('companyId', '==', companyId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba recuperar la lista de usuarios de la compañia ')
  }
}

export const getAllUsersByCompanyAndStore = async (companyId: string, storeId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('users')
      .where('companyId', '==', companyId)
      .where('storeId', '==', storeId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar los usuarios')
  }
}

export const createUser = async (userData: Record<string, any>): Promise<any> => {
  const createUserCloud = firebase.functions.httpsCallable('createUser')
  try {
    return await createUserCloud({ email: userData.email, password: 'Obramat2.0' }).then(async (user) => {
      if (user.data.errorInfo && user.data.errorInfo.code === 'auth/email-already-exists') return { error: 'El email ya existe' }

      userData.id = user.data.uid

      await firebase.db.collection('users').doc(user.data.uid).set(userData)
      // await firebase.auth.sendPasswordResetEmail(userData.email)
    })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba crear el usuario')
  }
}

export const deleteUser = async (id: string): Promise<any> => {
  try {
    const deleteUserFromFirebase = firebase.functions.httpsCallable('removeUser')
    await deleteUserFromFirebase({ id })
    return firebase.db.collection('users').doc(id).update({ deleted: true, employeeId: 'deleted' })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba eliminar el usuario')
  }
}

export const updateUser = async (userData: Record<string, any>, id: string): Promise<any> => {
  delete userData.id
  delete userData.createdAt
  try {
    return firebase.db.collection('users').doc(id).update(userData)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba actualizar los datos del usuario')
  }
}
