import React, { useContext, useEffect, useState ,useRef} from 'react'
import "react-chat-elements/dist/main.css"
import ChatItem from './chatComponents/ChatItem/ChatItem';
import ChatList from './chatComponents/ChatList/ChatList';
import MessageBox from './chatComponents/MessageBox/MessageBox';
import Typography from '@mui/material/Typography'
import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext';
import SearchInput from 'src/components/SearchInput';
import GoBackIcon from '@mui/icons-material/ArrowBack'
import { MapsUgc } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SendButton } from 'src/components/Buttons/SendButton';
import InputBase from '@mui/material/InputBase'
import ChatBG from './chatBG.png'



export default function Chat() {
  const { showHeader } = useContext(HeaderContext)
  const { userData, firebase,user } = useContext(AuthContext)
  const [contacts, setContacts] = useState([])
  const [contactsFiltered, setContactsFiltered] = useState([])
  const [activeChat, setActiveChat] = useState('')
  const [activeView, setActiveView] = useState('conversations')
  const [inputMessage,setInputMessage] = useState('')
  const [messages,setMessages] = useState([])
  const [chats,setChats] = useState([]);
  const [arrayEmailsChats, setArrayEmailsChat] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef()
  
  const Header = () => {
    return (
      <>
      {activeView === 'chat' ? (
        
        <div className="flex-between">
        <IconButton sx={{ mx: 2 }} color="primary" onClick={()=> setActiveView('conversations')}>
        <GoBackIcon />
        </IconButton>
        <Typography variant="h3" component="h3">
        Chat
        </Typography>
        <Typography variant="subtitle2" component="h3"></Typography>
        </div>
        ) : activeView === 'conversations' ? (
          <div className="flex-between">
            
        <Typography variant="subtitle2" component="h3"></Typography>
        <Typography variant="h3" component="h3">
        Chat
        </Typography>
        <IconButton sx={{ mx: 2 }} color="primary" onClick={()=> setActiveView('contacts')}>
        <MapsUgc />
        </IconButton>
        </div>
        ) :(
          <div className="flex-between">
        <IconButton sx={{ mx: 2 }} color="primary" onClick={()=> setActiveView('conversations')}>
        <GoBackIcon />
        </IconButton>
        <Typography variant="h3" component="h3">
        Chat
        </Typography>
        <Typography variant="subtitle2" component="h3"></Typography>
        </div>
        )
      }
      
        </>
 ) }

 const notNull = (string) => {
  if (string === null || string === undefined) {
    return "";
  } else {
    return string;
  }
};




  const retrieveContactsForPhysio = async () => {
    
    const storesWorking = userData.storesWorking.map(store => store.id)
    firebase.db
      .collection("users")
      .where('role.id','==','role5')
      .where('store.id','in' ,storesWorking)
      .get()
      .then((res) => {
      const contacts = []
      res.forEach((doc) => {

       const newItem = doc.data();
       
          const contacto = {
  
            id:doc.id,
            name: newItem?.name,
            lastName: newItem?.lastname,
            fullName: newItem?.fullName,
            email: newItem?.email,
            role:  newItem?.role,
            store: newItem?.store,
            sector: newItem?.sector
            
          }
          contacts.push(contacto)
          // addContact(contacto);
        })
     
      setContactsFiltered(contacts)
      setContacts(contacts)

        // return contacts
        }
      )
      .catch((err) => console.error(err))
  
}

const retrieveContactsForWorker = async () => {

 
  firebase.db
    .collection("users")
    .where('role.id','==','role2')
    .where('storesWorking','array-contains',userData.store)
    .get()
    .then((res) => {

    const contacts= []
    
    res.forEach((doc) => {

     const newItem = doc.data();
      
        const contacto = {
          
          id: doc.id,
          name: newItem?.name,
          lastName: newItem?.lastname,
          fullName: newItem?.fullName,
          email: newItem?.email,
          role:  newItem?.role,
          sector: newItem?.sector
          
        }
        contacts.push(contacto)
      })
      setContacts(contacts)
      

      setContactsFiltered(contacts.filter(contact => !arrayEmailsChats.includes(contact.email)))
      }
    )
    .catch((err) => console.error(err))

}

const getChats = async () => {
  setArrayEmailsChat([])
  setChats([])
  const tempChats = []; 
  // const emailsChats = []

  // const chatsData = await firebase.db.collection("users").doc(user.user.uid).collection("chats").get();


  await firebase.db.collection("users").doc(user.user.uid).collection("chats").onSnapshot(async (querySnapshot) => {
   
  if (querySnapshot.docs.length > 0) {
    for await (const doc of querySnapshot.docs) {
    
      const newItem = doc.data();
      const id = newItem.id;
      const check = newItem.check;
      const readed = newItem.readed || false
      
      const latestMessage = newItem.latestMessage || { text: "" };

      // Get userRef ( The other user )

      const userRefData = await newItem.userRef.get();
      const userChatInfo = userRefData.data();
    

      const data = {
        image: userChatInfo.image || "",
        name: notNull(userChatInfo.fullName),
        role: userChatInfo.role,
        sector : userChatInfo.sector,
        fullName: userChatInfo.fullName,
        email: userChatInfo.email,
        deleted: userChatInfo.deleted || false,
        receiverId: userChatInfo.id,
        id: id,
        check,
        readed,
        latestMessage: latestMessage || { text: "" },
        lastestMessageDate: newItem?.latestMessage?.createdAt || new Date()
      };

      !userChatInfo.deleted && tempChats.push(data);
      setArrayEmailsChat([...arrayEmailsChats,userChatInfo.email])
      
      // emailsChats.push(userChatInfo.email)

    }
   
    setChats([]);
    setChats(tempChats.filter((v,i,a)=>a.findLastIndex(v2=>(v2.id===v.id))===i));
    // setArrayEmailsChat(emailsChats)
    setIsLoading(false);

   
  } else setIsLoading(false);
})

  userData.role.id === "role2" && await retrieveContactsForPhysio()
  userData.role.id === "role5" && await retrieveContactsForWorker()

};

async function handleSend() {



  firebase.db
    .collection("chats")
    .doc(activeChat.chatId)
    .collection("messages")
    .add({
      text:inputMessage,
      deleted: [],
      createdAt: new Date().getTime(),
      user: {
        _id: userData.email,
        email: userData.email
      
      }
    })
    .then(() => console.log("Mensaje grabado"))
    .catch((error) => console.error(error));

   firebase.db
    .collection("chats")
    .doc(activeChat.chatId)
    .set(
      {
        latestMessage: {
          text: inputMessage,
          createdAt: new Date().getTime()
        }
      },
      { merge: true }
    )
    .then(() => console.log("Actualizado"))
    .catch((error) => console.error(error));

 
      
  firebase.db
    .collection("users")
    .doc(activeChat.receiverId)
    .collection("chats")
    .doc(activeChat.chatId)
    .update({
      check: false,
      datecheck: Date.now(),
      latestMessage: {
        text:inputMessage,
        createdAt: new Date().getTime()
      },
      readed: false
    })
    // .then(() => console.log("okEi"))
    .catch((error) => console.log(error));


  firebase.db
    .collection("users")
    .doc(user.user.uid)
    .collection("chats")
    .doc(activeChat.chatId)
    .update({
      check: true,
      latestMessage: {
        text: inputMessage,
        createdAt: new Date().getTime()
      }
    })
    // .then(() => console.log("okiA"))
    .catch((error) => console.log(error));

  setInputMessage('')
  

}








useEffect(() => {
  showHeader(Header)

}, [activeView])

  useEffect(() => {

    getChats()
  }, [])

  const handleSearch = async (word) => {
   
    if (!word) {
      if (contacts) setContactsFiltered(contacts)
    } else {
      if (contacts) setContactsFiltered(contacts.filter((contact) => contact.fullName.toLowerCase().includes(word.toLowerCase())))
    }
  }

  const goToChat = (item) => {
   
    
    const array = [userData.email, item.email];
    const arraySorted = array.sort();
    const id = arraySorted[0] + arraySorted[1];
    setActiveView('chat')
    
    setActiveChat({...item,chatId: id})

  }


  const checkChatExists = async (item) => {

    const array = [userData.email, item.email];
    const arraySorted = array.sort();
    const id = arraySorted[0] + arraySorted[1];

   
    const data = {
      id: id,
      chatRef: firebase.db.doc("chats/" + id),
      userRef: firebase.db.doc("users/" + item.id)
    };

    const dataReceiver = {
      id: id,
      chatRef: firebase.db.doc("chats/" + id),
      userRef: firebase.db.doc("users/" + user.user.uid)
    };

    try {
      await firebase.db.collection("users")
        .doc(user.user.uid)
        .collection("chats")
        .doc(id)
        .set(data)


  
     await firebase.db
        .collection("users")
        .doc(item.id)
        .collection("chats")
        .doc(id)
        .set(dataReceiver)
 

      setActiveView('chat')
      setActiveChat({...item,chatId: id})

    } catch (error) {
      console.error(error)
    }

  }


  const messagesRef = useRef()

  const scrollToBottom = () => {
    messagesRef.current.scrollIntoView({ behavior: "auto" });
  }

  const handleChat = async  (item) => {
   await checkChatExists(item)
   }

   useEffect(() => {
    if(activeChat){
   const messagesListener = firebase.db
      .collection("chats")
      .doc(activeChat.chatId)
      .collection("messages")
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => {
          const firebaseData = doc.data();

          const data = {
            _id: doc.id,
            text: "",
            user: {
              _id: userData.email
            },
            deleted: firebaseData.deleted?.includes(userData.email),
            createdAt: new Date().getTime(),
            ...firebaseData
          };

          return data;
        });
       
        firebase.db
          .collection("users")
          .doc(user.user.uid)
          .collection("chats")
          .doc(activeChat.chatId)
          .update({ check: true, readed: true })
          // .then(() => console.log("oki"))

          .catch((error) => console.log(error));
        
        setMessages(messages);
        scrollToBottom()
      
      });


    return () => messagesListener();
    }
  }, [activeChat]);

  

return(
  <>

{activeView === 'contacts' &&  contacts.length > 0 && (
  <>
<div style={{margin:'10px 10px'}}>

<SearchInput placeholder={'Buscar contacto'} name="search" handleSubmit={handleSearch} clean fullwidth />
 </div>
 
  {contactsFiltered.map((contact,idx) => 
    <ChatItem
    key={idx}
    id={idx}
    avatar="https://w7.pngwing.com/pngs/205/731/png-transparent-default-avatar-thumbnail.png"
    alt={contact.fullName}
    title={`${contact.fullName} / ${contact.role.name} / ${contact?.sector[0]?.name ||''}`}
    date={null}
    // subtitle="Ok. See you !"
  
    unread={0}
    onClick={()=> handleChat(contact)}
/>
    )}
  </>
  )}

{activeView === 'contacts' && (!contacts || contacts.length === 0) &&  (
<>
<div style={{textAlign:"center", marginTop:'20px'}}>

<Typography variant="body1" sx={{ color: '#6B7280' }}>
          No hay ningún contacto disponible para chatear
        </Typography>
</div>
</>
)}

{activeView === 'chat' &&  activeChat && (
<>
 <div style={{overflow: 'scroll',width:'100%', height: '100%',minHeight:'75vh', display:'flex',flexDirection:'column',justifyContent:'start',backgroundImage: `url(${ChatBG})`}}>
     <ChatItem
     key={1}
     id={2}
    //  letterItem={{id:1,letter: activeChat?.name[0]}}
     avatar="https://w7.pngwing.com/pngs/205/731/png-transparent-default-avatar-thumbnail.png"
     alt={'test'}
     date={null}
     title={`${activeChat?.fullName} / ${activeChat?.role?.name} / ${activeChat?.sector?.map(sector => sector?.name)}`}
     
     
     />
  
{messages.sort((a, b) => a?.createdAt - b?.createdAt).map(message => (
    <MessageBox
    key={message.createdAt}
    retracted={false}
      status='read'
      removeButton={false}
      notch={true}
      notchStyle={{fill: message.user.email === userData.email ? "#ecf3fb": ""}}
      id={1}
      titleColor='black'
      onTitleClick={null}
      position={message.user.email === userData.email ? "right": "left"}
      // title={message.user.email === userData.email ? "Yo": activeChat.fullName}
      type="text"
      text={message.text}
      date={new Date(message.createdAt)}
      styles={{backgroundColor: message.user.email === userData.email ? "#ecf3fb": ""}}
      
    />
))}

<div style={{ float:"left", clear: "both" }}
             ref={messagesRef}>
        </div>
  </div>
    <div style={{position:"sticky",bottom:'20px',display:'flex',flexDirection:'row',width:'100%',justifyContent:'space-between',padding:'0px 25px'}}>

  

<InputBase
              sx={{ color: 'inherit', fontFamily: 'Inter', borderRadius:5,padding:'5px 15px',boxShadow:'6px 3px 15px -1px rgba(133,133,133,1)',backgroundColor:'white' }}
              value={inputMessage}
              onChange={e =>setInputMessage(e.target.value)}
              name={'inputMessageField'}
              placeholder={'Escribe algo aqui!'}
              fullWidth
              multiline={true}
              rows={2}
              type={'text'}
              autoComplete="off"
              endAdornment={
             
                  <SendButton onClick={() => inputMessage && handleSend()} text={'Enviar'} />
           
              
              }
            />
  

{/* <SendButton onClick={() => inputMessage && handleSend()} text={'Enviar'} /> */}

     </div>
     </>
)}

{/* {activeView === 'contacts' &&  contacts && contacts.length > 0 && (
 <>
 <div style={{display:'flex',flexDirection:'row'}}>

 <div style={{width:'100%',display:'flex',flexDirection:'column'}}>
<div style={{margin:'10px 10px', display:'flex',flexDirection:'row', justifyContent:"space-between",alignItems:'center'}}>
 <SearchInput placeholder={'Buscar contacto'} name="search" handleSubmit={handleSearch} clean fullwidth />
<ChatIcon />
</div>
   {contactsFiltered.map((contact,idx) => 
     <ChatItem
     key={idx}
     id={idx}
     avatar="https://w7.pngwing.com/pngs/205/731/png-transparent-default-avatar-thumbnail.png"
     alt={contact.fullName}
     title={`${contact.fullName} / ${contact.role.name} / ${contact?.sector[0]?.name}`}
     // subtitle="Ok. See you !"

     unread={0}
     onClick={()=> handleChat(contact)}
     />
     )}
     </div>
     </div>
   </>
   )} */}

{activeView === 'conversations' && chats && !chats.length && (
<>
<div style={{textAlign:"center", marginTop:'20px'}}>

<Typography variant="body1" sx={{ color: '#6B7280' }}>
          No tienes ningun chat aún, haz click en añadir para empezar a chatear
        </Typography>
</div>
</>
)}

{activeView === 'conversations' && chats && chats.length > 0 && (
 <>
 <div style={{display:'flex',flexDirection:'row'}}>

 <div style={{width:'100%',display:'flex',flexDirection:'column'}}>
{/* <div style={{margin:'10px 10px', display:'flex',flexDirection:'row', justifyContent:"space-between",alignItems:'center'}}>
 <SearchInput placeholder={'Buscar conversación'} name="search" handleSubmit={handleSearch} clean fullwidth />
</div> */}


    <ChatList
    onClick={(e) => goToChat(e)}
    dataSource={chats.sort((a, b) => (b.latestMessage?.createdAt != null ? b.latestMessage?.createdAt : 0) - (a.latestMessage?.createdAt != null ? a.latestMessage?.createdAt : 0)).map(chat => (
      {
        receiverId: chat.receiverId,
        email: chat.email,
        role: chat.role,
        sector: chat.sector,
        fullName: chat.fullName,
        avatar: 'https://w7.pngwing.com/pngs/205/731/png-transparent-default-avatar-thumbnail.png',
        title: `${chat.name} / ${chat.role.name}`,
        subtitle: chat.latestMessage.text,
        date: chat?.latestMessage?.createdAt ? new Date(chat?.latestMessage?.createdAt): null
        // unread: 3
      }))}
   />
     
     </div>
     </div>
   </>
   )}

   </>
)}

   

